import request from "@/utils/api_req.js";


/**
 * 增加地址簿地址
 */
export function addWalletAddress(data) {
	return request.post("wallet/add", data);
}

/**
 * 更新地址簿地址
 */
export function updateWalletAddress(data) {
	return request.post("wallet/update", data);
}

/**
 * 查询
 */
export function addressList() {
	return request.get("wallet/dressList");
}

/**
 *删除
 */
export function delWalletAddress(id) {
	return request.get("wallet/del/" + id);
}

/**
 *选择
 */
export function checkedWalletAddress(id) {
	return request.get("wallet/checked/" + id);
}

/**
 *选择取消
 */
export function cancelWalletAddress(id) {
	return request.get("wallet/cancel/" + id);
}

/**
 *单个查询
 */
export function walletAddressInfo(id) {
	return request.get("wallet/info/" + id);
}

/**
 *单个默认
 */
export function defaultWalletAddress() {
	return request.get("wallet/default");
}

<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 40px'}">
      <template #title>
        个人资料设置
      </template>
      <a-form>
        <a-form-item label="头像设置" :label-col="{ style: { top: '30px' } }">
          <div class="flex">
            <a-avatar :size="92" :src="avatar" size="small" @on-error="Avatar">
              <template #icon>
                <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
              </template>
            </a-avatar>
            <div class="margin-left flex align-start flex-direction justify-center">
              <div>
                <a-upload v-model:file-list="fileList"
                          :action="uploadUrl"
                          :maxCount="1"
                          accept="image/*"
                          :name="'multipart'"
                          @change="handleChange"
                          @beforeUpload="beforeUpload"
                          :showUploadList="false">
                  <div class="ant-upload-text">
                    <a-button size="small">更换头像</a-button>
                  </div>
                </a-upload>
              </div>
              <div class="mt-2 info">
                仅支持JPG、GIF、PNG、JPEG、BMP格式，文件小于4M
              </div>
            </div>
          </div>
        </a-form-item>

        <a-form-item label="昵称" :label-col="{ style: { width: '65px' } }">
          <a-input style="width: 325px;" v-model:value="nickname" @pressEnter="inputChange"/>
        </a-form-item>

        <a-form-item label="ID" :label-col="{ style: { width: '65px' } }">
          <a-input style="width: 325px;" disabled v-model:value="userInfo.invitationCode">
            <template #suffix>
              <Icon type="ios-lock"/>
            </template>
          </a-input>
        </a-form-item>

      </a-form>
    </a-card>
  </div>
</template>

<script>
import { BASE_URL } from '@/config/index.js'

import { userEdit } from '@/api/user.js'

export default {
  name: 'Profile',
  data() {
    return {
      userInfo: {},
      uploadUrl: '',
      avatar: '',
      nickname: '',
      fileList: [],
    }
  },
  mounted() {
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
      this.avatar = this.userInfo.avatar
      this.nickname = this.userInfo.nickname
    }
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
    this.getUserInfo()
  },
  methods: {
    inputChange(e) {
      this.nickname = e.target._value
      this.formSubmit()
    },
    handleChange(info) {
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        this.avatar = response.data.url
        this.formSubmit()
      }
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    Avatar() {
      this.avatar = require('@/assets/images/default.png')
    },
    getUserInfo() {
      this.$store.dispatch('USERINFO')
          .then(res => {
            this.userInfo = res
            this.avatar = this.userInfo.avatar
            this.nickname = this.userInfo.nickname
          })
    },
    /**
     * 提交修改
     */
    formSubmit() {
      let that = this
      userEdit({
        avatar: that.avatar,
        nickname: that.nickname,
        birthday: that.getDate('start'),
        city: '1',
        province: '1',
        sex: 0
      })
          .then(res => {
            this.getUserInfo()
          })
    },
    getDate(type) {
      const date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()

      if (type === 'start') {
        year = year - 60
      } else if (type === 'end') {
        year = year + 2
      }
      month = month > 9 ? month : '0' + month
      day = day > 9 ? day : '0' + day
      return `${year}-${month}-${day}`
    },
  }
}
</script>

<style scoped lang="scss">
.info {
  font-size: 13px;
  font-weight: 350;
  line-height: 25px;
  letter-spacing: 0px;
  color: #999999;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }

  .list-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>

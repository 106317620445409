<template>
  <div id="list">
    <arco-scrollbar
        style="height:630px;overflow-y: auto;overflow-x:hidden !important;background-color: #FFFFFF;padding: 10px 0;">
      <a-menu class="side-menu"
              mode="inline"
              v-model:selectedKeys="selectedKeys"
              @click="changeCurrentSession">
        <a-menu-item v-for="(item,index) in messages" :key="index"
                     class="mt-3 mb-3">
          <a-badge :dot="item.mesCount">
            <div class="d-flex">
              <div class="me-auto">
                <a-avatar :src="item.headImg" :size="42"/>
              </div>
              <div class="ml-2 mt-1" style="display: flex;justify-content: space-between;">
                <div class="me-auto" style="width: 80px;">
                  <div class=" d-flex flex-column justify-between" style="height: 42px;">
                    <div class="text-cut" style="width: 60px;">{{ item.merName }}</div>
                    <div class="text-cut text-sm text-gray">{{ item.topTitle ? item.topTitle : '' }}</div>
                  </div>
                </div>
                <div class="text-gray text-sm">
                  {{ item.updateTime }}
                </div>
              </div>
            </div>
          </a-badge>
        </a-menu-item>
      </a-menu>
    </arco-scrollbar>
  </div>
</template>

<script>
import { msgCusList, msgCusTempSave } from '@/api/message'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'list',
  props: {
    keyword: {
      type: String,
      default: ''
    },
    dbMerId: {
      type: Number,
      default: ''
    },
    // 刷新INDEX
    refreshList: {
      type: Number,
      default: -1
    },
  },
  watch: {
    keyword: {
      handler(val) {
        this.queryMsgCusList()
      }
    },
    refreshList: {
      handler(val) {
        this.queryMsgCusList()
      }
    }
  },
  data() {
    return {
      messages: [],
      openKeys: [],
      selectedKeys: [],
      index: 0,
    }
  },
  created() {
    // this.queryMsgCusList()
  },
  methods: {
    queryMsgCusList() {
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    headImg: visitorId
                  }
                  msgCusList(data)
                      .then(res => {
                        let arrass = res.data
                        this.messages = []
                        if (arrass && arrass.length > 0) {
                          arrass.forEach(item => {
                            if (item.merName.indexOf(this.keyword) > -1) {
                              this.messages.push(item)
                            }
                          })
                          this.selectedKeys = []
                          if (!this.keyword) {
                            this.messages.forEach((item, index) => {
                              if (Number.parseInt(this.dbMerId) === Number.parseInt(item.merId)) {
                                this.selectedKeys.push(index)
                                this.$emit('change', item)
                              }
                            })
                          } else {
                            this.selectedKeys.push(0)
                            this.$emit('change', this.messages[0])
                          }
                        }
                      })
                })
          })
    },
    changeCurrentSession(item) {
      this.selectedKeys = []
      this.selectedKeys.push(item.key)
      let mesItem = this.messages[item.key]
      mesItem.index = item.key + 1
      this.$emit('change', mesItem)
      setTimeout(() => {

        FingerprintJS.load()
            .then(fp => {
              fp.get()
                  .then(result => {
                    let visitorId = result.visitorId
                    this.queryMsgCusBackList(visitorId)
                  })
            })
      }, 400)
    },
    queryMsgCusBackList(visitorId) {
      let data = {
        headImg: visitorId,
      }
      msgCusList(data)
          .then(res => {
            let arrass = res.data
            this.messages = []
            if (arrass && arrass.length > 0) {
              arrass.forEach(item => {
                if (item.merName.indexOf(this.keyword) > -1) {
                  this.messages.push(item)
                }
              })
            }
          })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(ul) {
  margin-left: 0 !important;
}

:deep(.ant-menu-item) {
  padding-left: 0 !important;
  height: 72px;
}

:deep(.ant-menu-title-content) {
  padding: 10px !important;
}

#list {
  ul {
    padding-left: 0px;
    margin-left: 2px;
  }

  li {
    padding-top: 14px;
    padding-bottom: 14px;
    //padding-right: 40px;
    //border-bottom: 1px solid #E5E5E5;
    list-style: none;
    cursor: pointer;
    background-color: #F6F7F6;

    &:hover {
      background-color: #D8D6D6;
    }
  }

  li.active { /*注意这个是.不是冒号:*/
    background-color: #C8C6C6;
  }

  .avatar {
    width: 45px;
    height: 45px;
    vertical-align: middle;
  }

  .name {
    display: inline-block;
    margin-left: 6px;
    font-size: 13px;
  }

  .nameId {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    margin-top: 5px;
    color: #666666;
  }

  .userList {
    max-height: 600px;
    background-color: #F6F7F6 !important;
  }

  .el-scrollbar__wrap.default-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>

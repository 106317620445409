<template>
  <div>
    <div class="wrapper" :style="{right:handleDrawer ? '300px' : '0px'}">

      <div class="barItem" @mouseenter="showCartNum(item)" @click="clickBar(item)" v-for="(item,index) in resetConfig.menuList" :key="index">
        <Tooltip placement="left" :content="item.title">
          <Icon size="20" :type="item.icon"/>
          <p class="barTitle" v-if="item.titleShow"> {{item.title}}</p>
          <div class="circle" v-if="item.title === '购物车'">
            {{cartNum < 100 ? cartNum : 99}}
          </div>
        </Tooltip>
      </div>

    </div>
    <Drawer width="300" class="popup" :title="drawerData.title" :mask="resetConfig.mask" :closable="resetConfig.closable"
      v-model="handleDrawer">
      <drawerPage :title="drawerData.title" />
    </Drawer>
  </div>
</template>

<script>
import Storage from '@/plugins/storage.js';
import Configuration from './config';
import drawerPage from './Drawer'
export default {
  name: 'Main',
  data () {
    return {
      resetConfig: Configuration, // 菜单项
      handleDrawer: false, // 是否可展开
      drawerData: '' // 菜单基础数据
    }
  },
  components: {drawerPage},
  computed: {
    userInfo () {
      return {};
    },
    cartNum () {
      return 1
    }
  },
  methods: {
    showCartNum (item) { // 获取购物车数量
      if (this.userInfo && item.title === '购物车') {
      }
    },
    clickBar (val) { // tabbar点击操作
      if (!this.userInfo) {
      } else {
      }
    },
    openBlank (path) { // 新页面打开地址
    },
    getCartList () { // 获取购物车列表
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    background-color: #000!important;
  }
  .barItem {
    text-align: center;
    padding: 13px 0;

    cursor: pointer;
    color: #fff;
    &:hover{
      background-color: #F31947;;
      .circle{
        color: #F31947;;
        background-color: #fff;
      }
    }
  }

  .barTitle {
    writing-mode: vertical-lr;
    letter-spacing: 2px;
    padding: 4px 0;

  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    background: #F31947;;
  }

  .wrapper {
    width: 40px;
    position: fixed;
    transition: .35s;
    height: 100%;
    z-index: 9999;
    background: #141414;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  :deep(.popup .ivu-drawer-body){
    padding: 0!important;
    background-color: #eee;
  }
  :deep(.popup .ivu-drawer-wrap){
    z-index: 3001;
  }
</style>

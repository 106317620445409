<template>
  <div>
    <div class="container width_1200_auto page-container-all">
      <a-image :preview="false" class="all-page-container" :src="require('@/assets/index/coupback.png')"></a-image>
      <div class="page-join-content">
        <div class="font-top1">
          尊贵新人
        </div>
        <div class="font-top2">
          注册即享豪华福利！
        </div>
        <div class="page-container">
          <a-card :bordered="false" :body-style="{padding: '25px', width:'1200px'}" :loading="loading">
            <div class="d-flex">
              <div class="ml-2 text-bold text-lg me-auto">限时抢券</div>
              <div class="extra cursor-pointer"
                   @click="()=>{$router.push({path:'/MyCoupon'})}">
                我的优惠券
              </div>
            </div>
            <div class="font-info">
              <a-row :gutter="[16,24]">
                <a-col :span="8" v-for="(item,index) in list" :key="index">
                  <div class="bg-img-cus">
                    <div class="money-hour" v-if="item.showFlag === 1">
                      {{ item.month }}
                      <text class="money-dw">
                        {{
                          item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : ''
                        }}
                      </text>
                    </div>
                    <div class="money" v-if="item.showFlag === 0">
                      {{ item.money }}
                      <text class="money-dw">USDT</text>
                    </div>
                    <div class="item">
                      <div class="content cursor-pointer" @click="showInfo(item)">
                        <div class="text-grey text-font-s">{{ item.name }}</div>
                        <div class="line2 text-font-xs">{{ item.content }}
                          <div class="text-gray text-sm flex">
                            <div class="text-cut text-font-s-1">
                              {{ item.receiveEndTime }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="action cursor-pointer">
                        <div class="action" v-if="item.lastTotal > 0 && !item.isUserReceive">
                          <div class="text-cus-ss" @click="onlLqCon(item)">
                            <!-- 领取 -->
                            领取
                          </div>
                        </div>
                        <div class="action-gray" v-if="item.lastTotal > 0 && item.isUserReceive">
                          <div class="text-cus-ss-1 text-gray" v-if="item.isUserReceive">
                            <!-- 已领取 -->
                            已领取
                          </div>
                        </div>
                        <div class="action-gray" v-if="item.lastTotal <= 0">
                          <div class="text-cus-ss-1  text-gray">
                            <!-- 已领完 -->
                            已领完
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </div>
      </div>
    </div>

    <a-modal :open="show" :title="tagInfo.name" :footer="null" @cancel="show=false">
      <div>
        <div class="mb-3 d-flex">
          <div class="text-gray">面值:</div>
          <div class="ml-3">{{ tagInfo.money }} USDT</div>
        </div>
        <div class="mb-3 d-flex">
          <div class="text-gray">发放期:</div>
          <div class="ml-3">
            {{ tagInfo.receiveStartTime + 'ー' + tagInfo.receiveEndTime }}
          </div>
        </div>
        <div class="mb-3 d-flex">
          <div class="text-gray">使用时长:</div>
          <div class="ml-3">
            {{ tagInfo.month }}
            <text class="money-dw">
              {{
                tagInfo.trialFlag === 0 ? '小时' : tagInfo.trialFlag === 1 ? '天' : tagInfo.trialFlag === 2 ? '月' : ''
              }}
            </text>
          </div>
        </div>
        <div style="font-size: 13px;" class="text-gray">
          {{ tagInfo.content }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { createCoupon, getCouponProList, } from '@/api/order.js'

export default {
  data() {
    return {
      type: '',
      list: [],
      loading: false,
      page: 1,
      limit: 100,
      tagInfo: '',
      show: false,
    }
  },
  created() {
    this.statusClick(7)
  },
  methods: {
    onlLqCon(conp) {
      let that_ = this
      createCoupon(conp.id)
          .then((res) => {
            that_.list = []
            that_.getList()
          })
    },
    showInfo(item) {
      this.tagInfo = item
      this.show = true
      console.log(item)
    },
    getList() {
      let that_ = this
      that_.loading = true
      getCouponProList({
        category: 7,
        page: that_.page,
        limit: that_.limit
      })
          .then(res => {
            that_.list = res.data.list
            that_.loading = false
          })
    },
    //切换类型
    statusClick: function (status) {
      this.loading = false
      this.page = 1
      this.limit = 15
      this.list = []
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;

  .mer-top-card {
    width: 100%;
  }
}

.extra {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-align: center;
}

.page-join-content {
  position: absolute;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;

  .font-top1 {
    font-size: 56px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-shadow: 0px 4px 2px rgba(229, 73, 0, 0.3);
  }

  .font-top2 {
    font-size: 46px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    color: #FBFF00;
  }

  .font-top3 {
    margin-top: 40px;
    font-size: 36px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
    /* 高额佣金 */
    color: #FFC12E;
  }
}

.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;
}

.flex-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.page-container {
  width: 1200px;
  margin-top: 90px;
}


.page-container-all {
  position: relative;
  width: 1200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 1200px;
  background-color: #FFFFFF;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 1200px;
  }
}

.bg-img-cus {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 100px;
  background-image: url('@/assets/coupon/list-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .item {
    position: relative;
    display: flex;
    margin-top: 20px;
    padding-right: 10px;
    height: 95px;
    justify-content: flex-end;

    align-items: center;

    .content {
      position: absolute;
      top: 10px;
      left: 125px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-around;

      color: #000;
      width: calc(100% - 96px - 120px);

      .text-font-s {
        font-size: 16px;
        color: #3D3D3D;
      }

      .text-font-xs {
        font-size: 10px;
        color: #999999;
        margin-top: 5px;
      }

      .text-font-xs-time {
        font-size: 11px;
        color: #FF8529;
        margin-top: 3px;
      }

      .text-font-s-1 {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
      }
    }

    .action {
      width: 100px;
      text-align: center;
      width: 72px;
      height: 28px;
      border-radius: 50px;
      background: #FF984A;
      box-shadow: 0px 3px 0px 0px rgba(255, 123, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .text-cus-ss {
        font-size: 14px;
        line-height: normal;
        color: #FFFFFF;
      }

      .text-cus-ss-1 {
        font-size: 14px;
        line-height: normal;
        color: gray;
      }
    }
  }

  .money-hour {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 95px;

    font-size: 43px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .money {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 95px;

    font-size: 28px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }
}
</style>

<template>
  <div class="page-container-all width_1200_auto">
    <a-image v-if="selecedIndex === '0' || selecedIndex === '1'" :preview="false" class="all-page-container"
             :src="require('@/assets/footer/user_1.jpg')"></a-image>
    <a-image v-if="selecedIndex === '2'" :preview="false" class="all-page-container"
             :src="require('@/assets/footer/user_2.jpg')"></a-image>
    <a-image v-if="selecedIndex === '3'" :preview="false" class="all-page-container"
             :src="require('@/assets/footer/user_3.jpg')"></a-image>
    <a-image v-if="selecedIndex === '4'" :preview="false" class="all-page-container"
             :src="require('@/assets/footer/user_4.jpg')"></a-image>

    <a-card :body-style="{padding: '10px'}" class="tabs flex justify-between align-center">
      <div class="tabs ml-3">
        <div :class="selecedIndex === '0' || selecedIndex === '1' ? 'tbs-item-selected':'' " class="tbs-item mr-4 cursor-pointer"
             @click="seletcedTab('0')">
          用户协议
        </div>
        <div :class="selecedIndex === '2' ? 'tbs-item-selected':'' " class="tbs-item mr-4 cursor-pointer" @click="seletcedTab('2')">
          隐私政策
        </div>
        <div :class="selecedIndex === '3' ? 'tbs-item-selected':'' " class="tbs-item mr-4 cursor-pointer" @click="seletcedTab('3')">
          商家入驻协议
        </div>
        <div :class="selecedIndex === '4' ? 'tbs-item-selected':'' " class="tbs-item mr-4 cursor-pointer" @click="seletcedTab('4')">
          免责申明
        </div>
      </div>
    </a-card>
    <a-card style="width: 100%" class="mt-2">
      <div v-html="article.content"></div>
    </a-card>
  </div>
</template>
<script>
import { getArticleDetails } from '@/api/api.js'

export default {
  components: {},
  data() {
    return {
      selecedIndex: '0',
      article: {}
    }
  },
  watch: {
    '$route.query.id': {
      handler(val) {
        this.selecedIndex = this.$route.query.id
        this.articleDetails()
      }
    }
  },
  created() {
    this.selecedIndex = this.$route.query.id
    this.articleDetails()
  },
  methods: {
    seletcedTab(index) {
      this.selecedIndex = index
      this.articleDetails()
    },
    articleDetails() {
      let infoId
      if (this.selecedIndex === '0' || this.selecedIndex === '1') {
        infoId = 19
      }
      if (this.selecedIndex === '2') {
        infoId = 20
      }
      if (this.selecedIndex === '3') {
        infoId = 21
      }
      if (this.selecedIndex === '4') {
        infoId = 22
      }
      getArticleDetails(infoId)
          .then(res => {
            this.article = res.data
          })
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;

  .tbs-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 102px;
    height: 24px;
    color: #999999;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20rpx;
  }

  .tbs-item-selected {
    border-radius: 50px;
    background: #269FFA;
    color: #FFFFFF;
  }
}

.page-container-all {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 3px;
  min-height: 1200px;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 1200px;
  }
}

.page-container {
  width: 1200px;
  margin-top: 300px;

  .font-info {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    color: #7B7B7B;
  }
}
</style>

<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}" :loading="loading">
      <div class="flex justify-space-between" style="padding: 0 2px;">
        <div class="me-auto text-bold">
          我的供需
        </div>
        <div class="cu-center">
          <a-button class="item-item-btns cu-center" type="link" @click="toAdd">
            新增
          </a-button>
        </div>
      </div>
      <empty v-if="noteRecommendList.length === 0"/>

      <div class="mt-4" v-else>
        <a-row :gutter="[16,24]">
          <a-col :span="8" v-for="(item, index) in noteRecommendList" :key="index">
            <a-card :bodyStyle="{padding: '10px',height: '180px'}" @click="viewCard(item)"
                    class="cursor-pointer position-relative">
              <div class="list-title line2">
                <div class="d-flex">
                  <div class="title-tg" :class="item.categoryId === 2 ? 'bg-blue' :''">
                    {{ item.categoryId === 1 ? $t('i_015') : $t('i_016') }}
                  </div>
                  <div class="text-cut">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div class="list-content line2">{{ item.content }}</div>
              <div class="list-time">发布时间:{{ item.createTime }}</div>
              <!-- 审核状态:0-待审核，1-审核通过，2-审核失败，3-平台关闭 -->
              <div class="text-sm text-gray margin-top-xs">
                <div v-if="item.auditStatus === 0 || item.auditStatus === 2 || item.auditStatus === 3"
                     class="approval_status">
                  <div class="status_count" :class="item.auditStatus === 0 ? 'status1' : 'status0'">
                    <div class="status_title">
                      <span class="iconfont"
                            :class="(item.auditStatus === 2 || item.auditStatus === 3) ? 'icon-tishi' : 'icon-shijian'"></span>
                      <span v-if="item.auditStatus === 2" class="title"
                            style="font-size: 13px;">审核未通过，内容仅自己可见</span>
                      <span v-if="item.auditStatus === 3" class="title"
                            style="font-size: 13px;">平台关闭，内容仅自己可见</span>
                      <span v-if="item.auditStatus === 0" class="title"
                            style="font-size: 13px;">正在审核，内容仅自己可见</span>
                    </div>
                    <div v-if="item.auditStatus === 2 || item.auditStatus === 3" class="status_info"
                         style="font-size: 13px;">
                      {{ item.refusal }}
                    </div>
                  </div>
                </div>

                <div v-if="item.auditStatus === 1" class="approval_status">
                  <div class="status_count" :class="item.auditStatus === 0 ? 'status1' : 'status0'">
                    <div style="color: green;font-size: 13px;">
                      <span class="iconfont icon-xuanzhong11"></span>
                      <span v-if="item.auditStatus === 1" class="title"
                            style="color: green;font-size: 26rpx;">审核通过</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!-- 分页 -->
      <div v-if="noteRecommendList && noteRecommendList.length > 0" class="page-size mt-4">
        <a-pagination :page-size="limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  myNoteApi,
} from '@/api/discover.js'

export default {
  data() {
    return {
      loading: false,
      current: ['1'],
      activeGxKey: 1,
      page: 1,
      limit: 12,
      total: null,
      noteRecommendList: [],
      where: {
        page: 1,
        limit: 12
      },
    }
  },
  mounted() {
    if (this.$store.getters.uid) {
      this.myNoteList()
    } else {
      this.$router.push({
        path: '/login',
        query: {}
      })
    }
  },
  methods: {
    // 获取我的的作品
    myNoteList: function () {
      this.loading = true
      myNoteApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
    toAdd() {
      if (this.$store.getters.uid) {
        this.$router.push({
          path: '/AddSupply',
          query: {}
        })
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    viewCard(item) {
      this.$router.push({
        path: '/MyAdd',
        query: {
          noteId: item.id
        }
      })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.myNoteList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.myNoteList()
    },
  }
}
</script>

<style scoped lang="scss">
.title-tg {
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  font-size: 14px;
  border-radius: 100px;
  background: #FF6600;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}

.approval_status {
  .status_count {
    padding: 13px 15px 10px;
    border-radius: 5px;

    .title {
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }

    &.status0 {
      background: #FDF3F2;

      .title {
        color: #E93323;
      }
    }

    &.status1 {
      background: #FFF4EB;

      .title {
        color: #FC8327;
      }
    }

    .iconfont {
      font-size: 15px;
    }

    .icon-shijian {
      color: #FC8327;
    }

    .icon-tishi {
      color: #E93323;
    }

    .status_info {
      font-size: 11px;
      color: #666666;
      margin: 5px 0 0 18px;
    }
  }
}

.item-item-btns {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: #2b85e4;

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.item-item-btn {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.list-title {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.list-time {
  font-size: 12px;
  font-weight: 350;
  color: #A7A7A7;
}

.list-content {
  margin-top: 6px;
  font-size: 13px;
  color: #999999;
  height: 45px;
}

.page-size {
  text-align: right;
}

.list-g-x {
  padding: 20px 0 20px 10px;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}

.ant-menu-light {
  background: inherit !important;
}

</style>

<template>
  <div class="container width_1200_auto">
    <div class="mer-top-card margin-top-xs">
      <!--  步骤条 -->
      <OrderSteps :current="showIndex"/>

      <a-card :body-style="{padding: '20px 30px'}" :bordered="false" :loading="loading">
        <div class="d-flex mb-6">
          <div class="me-auto">确认收货地址</div>
          <div class="text-blue cursor-pointer" @click="() =>{ $router.push({path: '/MyAddress'})}">管理收货地址</div>
        </div>
        <arco-scrollbar style="height:150px;overflow: auto;">
          <div v-if="addressList && addressList.length>0">
            <a-radio-group v-model:value="addressId" @change="addressChange">
              <div :class="addressId === item.id ? 'radio-back' : 'radio-back-v'" v-for="(item, index) in addressList"
                   :key="index">
                <a-radio :style="radioStyle" :value="item.id">
                  <div class="d-flex" :class="addressId === item.id ? 'text-black' : ''">
                    <a-space>
                      <div>{{ item.province }}</div>
                      <div>{{ item.city }}</div>
                      <div>{{ item.detail }}</div>
                      <div>+{{ item.countryCode }} {{ item.phone }}</div>
                    </a-space>
                  </div>
                </a-radio>
              </div>
            </a-radio-group>
          </div>
          <div v-else>
            <empty :_-title="'暂无地址'"/>
          </div>
        </arco-scrollbar>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs">
      <a-card :body-style="{padding: '20px 30px'}" :bordered="false" :loading="loading">
        <a-row class="col-gray" :gutter="[16,24]">
          <a-col :span="8">商品</a-col>
          <a-col class="cu-center" :span="2">属性</a-col>
          <a-col class="cu-center" :span="5">单价</a-col>
          <a-col class="cu-center" :span="5">数量</a-col>
          <a-col class="text-right" :span="4">小计</a-col>
        </a-row>
        <div v-for="(item, index) in merchantOrderVoList" :key="index">
          <div v-for="(items, indexs) in item.orderInfoList" :key="indexs">
            <a-row :gutter="[16,24]">
              <a-col :span="8">
                <div class="d-flex align-content-start flex-wrap mt-2 cursor-pointer"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                  <div>
                    <Icon class="mr-1 text-gray" :size="16" custom="iconfont icon-shangdian"/>
                  </div>
                  <div class="mr-1">{{ item.merName }}</div>
                  <div class="mr-1 text-gray">
                    <Icon :size="16" type="ios-arrow-forward"/>
                  </div>
                </div>
                <div class="mt-2 ml-1 d-flex justify-start">
                  <div class="rounded">
                    <a-image :src="items.image" :width="80" :height="80"/>
                  </div>
                  <div class="ml-2 pro-name line3">{{ items.productName }}</div>
                </div>
              </a-col>
              <a-col :span="2" class="cu-center">
                <div class="text-grey" v-if="items.sku">{{ items.sku }}</div>
              </a-col>
              <a-col :span="5" class="cu-center">
                <div class="text-red">{{ items.price || items.productPrice }} USDT</div>
              </a-col>
              <a-col :span="5" class="cu-center">
                <div>x {{ items.payNum }}</div>
              </a-col>
              <a-col class="text-right d-flex align-center justify-end" :span="4">
                <div>{{ items.price || items.productPrice }} USDT</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs" v-for="(item, index) in merchantOrderVoList" :key="index">
      <a-card :body-style="{padding: '20px 30px'}" :bordered="false" :loading="loading">
        <div class="mb-2">
          <a-row :gutter="24" style="width: 100%">
            <a-col class="text-gray" :span="2">配送方式：</a-col>
            <a-col :span="22">{{ item.shippingType === 1 ? '快递配送' : '到店自提' }}</a-col>
          </a-row>
        </div>
<!--        <div class="mb-2">-->
<!--          <a-row :gutter="[16,24]">-->
<!--            <a-col class="text-gray" :span="2">快递费用:</a-col>-->
<!--            <a-col :span="22">-->
<!--              <div v-if='!item.freightFee || item.freightFee !== 0' class="noCoupon"> 免运费</div>-->
<!--              <div v-else class='money'>{{ item.freightFee }} USDT</div>-->
<!--            </a-col>-->
<!--          </a-row>-->
<!--        </div>-->
        <div class="mb-2">
          <a-row :gutter="[16,24]">
            <a-col class="text-gray" :span="2">订单备注:</a-col>
            <a-col :span="22">
              <a-textarea :rows="4" v-model:value="item.remark" placeholder='请输入订单备注'
                          @input='bindHideKeyboard(item.remark,index)'></a-textarea>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs">
      <a-card :body-style="{padding: '20px 30px'}" :bordered="false" :loading="loading">
        <div class="card-title">合计</div>
        <div class="text-red heji-money text-right">
          {{ orderInfoVo.payFee || '0.00' }} USDT
        </div>
      </a-card>
    </div>

    <div class="text-right mt-3 d-flex justify-end">
      <div class="cursor-pointer now-btn cu-center" @click="SubOrder">
        <a-spin size="mini" :spinning="loading">
          立即购买
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { Debounce } from '@/utils/validate'
import { getAddressList } from '@/api/user'
import { loadPreOrderApi, orderCreate, postOrderComputed, orderPay } from '@/api/order'

import OrderSteps from '@/components/orderSteps/index.vue'

export default {
  name: 'Confirm',
  components: {
    OrderSteps
  },
  props: {},
  data() {
    return {
      showIndex: 0,
      orderNo: this.$route.query.orderNo,
      platCouponFee: '',
      platUserCouponId: '',
      merCouponFee: '',
      orderMerchantRequestList: [],
      type: '',
      orderProNum: '',
      addressChangeId: '',
      merchantOrderVoList: [],
      orderInfoVo: {},
      radioStyle: {
        display: 'flex',
        height: '30px',
        lineHeight: '30px'
      },
      addressId: 0,
      addressList: [],
      loading: false,
      activeIndex: '',
      visibleView: false,
      balance: 0,
      cartArr: [],
    }
  },
  created() {
    this.getAddressList()
    this.getLoadPreOrder()
  },
  methods: {
    //立即下单
    SubOrder: Debounce(function (e) {
      let that = this,
          data = {}
      let flag = false
      that.orderMerchantRequestList.map(function (v) {
        if (v.shippingType === 1 && !that.addressId && that.addressId <= 0) {
          flag = true
        }
      })
      if (flag) {
        this.$Message.error('请选择收货地址')
        return
      }
      data = {
        addressId: that.addressId,
        orderMerchantRequestList: that.orderMerchantRequestList,
        isUseIntegral: false,
        preOrderNo: that.orderNo,
        platUserCouponId: 0
      }
      this.loading = true
      that.payment(data)
    }),
    payment(data) {
      this.onCreate(data)
    },
    onCreate(data) {
      orderCreate(data)
          .then(res => {
            if (res.code === 200) {
              this.loading = false
              this.$router.replace({
                path: '/Payment',
                query: {
                  orderNo: res.data.orderNo,
                  payPrice: res.data.payPrice
                }
              })
            } else {
              this.$Message.error(res.message)
              this.loading = false
            }
          })
    },
    addressChange(e) {
      this.addressId = e.target.value
      this.computedPrice()
    },
    getLoadPreOrder() {
      this.loading = true
      loadPreOrderApi(this.orderNo)
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              let orderInfoVo = res.data
              this.orderInfoVo = orderInfoVo
              this.merchantOrderVoList = orderInfoVo.merchantInfoList //商户端数据
              this.platCouponFee = orderInfoVo.platCouponFee //平台优惠券总金额
              this.platUserCouponId = orderInfoVo.platUserCouponId
              this.merCouponFee = orderInfoVo.merCouponFee //店铺优惠券总金额
              orderInfoVo.merchantInfoList.map(item => {
                this.orderMerchantRequestList.push({
                  shippingType: item.shippingType,
                  merId: item.merId,
                  remark: '',
                  userCouponId: item.userCouponId
                })
              })
              this.type = orderInfoVo.type //订单类型
              this.orderProNum = orderInfoVo.orderProNum
              if (orderInfoVo.addressId && orderInfoVo.addressId > 0) {
                this.addressId = orderInfoVo.addressId
              }
            }
            this.loading = false
          })
    },
    bindHideKeyboard: function (e, i) {
      this.orderMerchantRequestList[i].remark = e
    },
    // 计算订单价格
    computedPrice() {
      this.loading = true
      postOrderComputed({
        addressId: this.addressId,
        isUseIntegral: false,
        orderMerchantRequestList: this.orderMerchantRequestList,
        preOrderNo: this.orderNo,
        platUserCouponId: 0
      })
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              let data = res.data
              data.merOrderResponseList.map((item, i) => {
                this.merchantOrderVoList[i].freightFee = item.freightFee
                this.merchantOrderVoList[i].couponFee = item.couponFee
              })
              this.orderInfoVo.platCouponUserList = data.platCouponUserList //平台优惠券数据
              this.merCouponFee = data.merCouponFee //店铺优惠券总金额
              this.orderInfoVo.couponFee = data.couponFee //优惠券优惠金额
              this.orderInfoVo.userIntegral = data.surplusIntegral //使用的积分
              this.orderInfoVo.deductionPrice = data.deductionPrice
              this.orderInfoVo.freightFee = data.freightFee
              this.orderInfoVo.payFee = data.payFee
              this.orderInfoVo.proTotalFee = data.proTotalFee
              this.orderInfoVo.surplusIntegral = data.usedIntegral //剩余积分
              this.platCouponFee = data.platCouponFee //平台优惠金额
              this.orderInfoVo.integralDeductionSwitch = data.integralDeductionSwitch //积分抵扣开关
            }
            this.loading = false
          })
    },
    getAddressList() {
      let that = this
      that.loading = true
      getAddressList()
          .then(res => {
            that.addressList = res.data
            if (that.addressList && that.addressList.length > 0) {
              that.addressId = that.addressList[0].id
            }
            that.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.now-btn {
  width: 346px;
  height: 50px;
  border-radius: 8px;
  opacity: 1;
  background: #269FFA;
  position: absolute;

  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.heji-money {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  font-weight: 500;
  font-size: 18px;
  color: #FF3F3F;
}

.card-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: 0em;
  color: #5D5D5D;
}

.pro-name {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #3D3D3D;
}

.col-gray {
  height: 30px;
  border-radius: 12px 12px 0px 0px;
  box-sizing: border-box;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #EBEBEB;
}

.radio-back {
  width: 1120px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background: #D6EEFF;
  box-sizing: border-box;
  border: 1px solid #9FD7FF;
}

.radio-back-v {
  width: 1120px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  box-sizing: border-box;
}


.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;

  .mer-top-card {
    width: 100%;
  }
}
</style>

<template>
  <!-- 新人礼弹窗 -->
  <div>
    <!--    <a-modal :open="" :title="null" :footer="null" @cancel="handleCancel">-->
    <div class="coupon_modal" @touchmove.stop.prevent="moveStop" v-if="couponModal">
      <div class="mask"></div>
      <div class="_container"
           :style="{'background-image': `url(${urlDomain}crmebimage/presets/coupon_modal_bg.png)`}">
        <div class="_tit">新人尊享</div>
        <div class="_look">优惠券将发放至个人账户，可在“我的优惠券”查看</div>
        <scroll-view :scroll-top="0" scroll-y="true" class="_box">
          <div class="_item acea-row row-middle" v-for="item in couponList" :key="item.id"
               :style="{'background-image': `url(${urlDomain}crmebimage/presets/coupon_item_bg.png)`}">
            <div class="_price_box">
              <div class="money-hour" v-if="item.showFlag === 1">
                {{ item.month }}
                <text class="money-dw">
                  {{
                    item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : ''
                  }}
                </text>
              </div>
              <div class="money" v-if="item.showFlag === 0">
                {{ item.money }}
                <text class="money-dw">USDT</text>
              </div>

              <div class="_desc">
                <div class="line1 text-xl-h1 text-bold" style="font-size: 30px;">{{ item.name }}</div>
                <div class="_end line1 mt-3">
                  {{ '领取后' + !item.isFixedTime ? item.hour + '小时内可用' : item.day + '天内可用' + '' }}
                </div>
                <div class="_end line1">
                  {{ getTime(item.receiveStartTime) + ' ~ ' + getTime(item.receiveEndTime) + '可领取' }}
                </div>
              </div>

            </div>
          </div>
        </scroll-view>
        <div class="_btn" @click="pullDown"
             :style="{'background-image': `url(${urlDomain}crmebimage/presets/coupon_modal_btn.png)`}">
        </div>
        <div class="guanbi">
          <Icon color="#FFFFFF" type="ios-close-circle-outline" @click="close()"/>
        </div>
      </div>
    </div>
    <!--    </a-modal>-->
  </div>
</template>
<script>
import { createCoupon, } from '@/api/order.js'

export default {
  name: 'couponDialog',
  props: {
    couponList: {
      type: Array,
      default: []
    },
    //图片域名地址
    urlDomain: {
      type: String,
      default: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/'
    },
    //弹窗是否弹起
    couponModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * 时间截取成字符串 23-06-26
     * @param {Object} time 2023-06-26 15:13:00
     */
    getTime(time) {
      let reg = new RegExp('-', 'g'); //g代表全部
      return time.split(' ')[0].replace(reg, '.').substring(2, 10);
    },
    moreCoup() {
      this.$router.push({ path: '/Coupon' })
      this.close()
    },
    pullDown() {
      let that_ = this
      if (that_.couponList && that_.couponList.length > 0) {
        that_.couponList.forEach((item) => {
          createCoupon(item.id)
              .then((res) => {
                if (res.code !== 200){
                  that_.$Message.error(res.message)
                } else {
                  localStorage.removeItem('newGift')
                  that_.$emit('close')
                }
              })
        })
      }
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>
<style lang="scss" scoped>
.mask {
  opacity: .5;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 300ms ease 0ms, -webkit-transform 300ms ease 0ms, transform 300ms ease 0ms;
  transform-origin: 50% 50%;
  z-index: 8831;
}

.coupon_modal {
  ._container {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 610px;
    height: 630px;
    background-size: cover;
    z-index: 8833;

    ._tit {
      text-align: center;
      height: 42px;
      font-size: 42px;
      font-weight: 600;
      color: #FFE9BE;
      line-height: 42px;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      margin-top: 226px;
    }

    ._look {
      text-align: center;
      font-size: 22px;
      color: #fff;
      margin: 20px auto 28px;
    }

    ._box {
      width: 540px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }

    ._item-cus {
      width: 80%;
      height: 165px;
      border-radius: 16px;
      background-size: cover;
      margin-top: 30px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;

      ._desc {
        width: 280px;
        font-size: 34px;
        color: #FEBC2D;
        font-weight: 500;
      }
    }

    ._item {
      width: 100%;
      height: 175px;
      border-radius: 14px;
      background-size: cover;
      padding: 10px 0;

      ._price_box {
        width: 200px;
        padding: 0 20px;
        text-align: center;
        border-right: 1px dashed #E6E6E6;
        position: relative;

        ._price {
          font-size: 34px;
          color: #E93323;
          font-weight: 600;
        }

        ._man {
          font-size: 22px;
          color: #666;
          margin-top: 10px;
        }

        .money {
          position: absolute;
          left: 0px;
          top: 0;
          width: 200px;
          height: 145px;

          font-size: 53px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;
          color: #FEBC2D;

          display: flex;
          justify-content: center;
          align-items: center;

          .money-dw {
            margin-left: 5px;
            margin-top: 35px;
            font-size: 12px;
            color: #FEBC2D;
          }
        }

        .money-hour {
          position: absolute;
          left: 0px;
          top: 0;
          width: 200px;
          height: 145px;

          font-size: 53px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;
          color: #FEBC2D;

          display: flex;
          justify-content: center;
          align-items: center;

          .money-dw {
            margin-left: 5px;
            margin-top: 35px;
            font-size: 12px;
            color: #FEBC2D;
          }
        }

        ._desc {
          width: 380px;
          position: absolute;
          padding: 0 30px;
          left: 180px;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 10px;

          ._text {
            width: 280px;
            font-size: 32px;
            color: #282828;
            font-weight: 600;
          }

          ._end {
            margin-top: 15px;
            font-size: 22px;
            color: #999;
          }
        }
      }

      ~ ._item {
        margin-top: 20px;
      }
    }

    ._btn {
      width: 320px;
      height: 76px;
      background-size: cover;
      margin: 10px auto 0;
    }

    .guanbi {
      font-size: 36px;
      color: #fff;
      font-weight: bold;
      position: absolute;
      top: -10px;
      right: -20px;
      transform: translateX(-50%);
    }
  }
}
</style>

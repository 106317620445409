<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Share">
          我的推广
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        邀请详情
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="margin-top-sm">
      <div>
        <a-row :gutter="[16, 24]">
          <a-col :span="12">
            <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
              <div class="card-top">
                <div class="item-1">
                  <a-image :src="require('@/assets/share/inOne.png')" :width="100" :height="100"
                           :preview="false"></a-image>
                </div>
                <div class="item-2">
                  <div class="item-title">邀请人数</div>
                  <div class="item-number">{{ peopleData.count ? peopleData.count : '0' }}</div>
                  <div class="item-unit">(人)</div>
                </div>
              </div>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
              <div class="card-top">
                <div class="item-1">
                  <a-image :src="require('@/assets/share/inTwo.png')" :width="100" :height="100"
                           :preview="false"></a-image>
                </div>
                <div class="item-2">
                  <div class="item-title">我的佣金</div>
                  <div class="item-number">
                    {{
                      peopleData.brokerage ? parseFloat(peopleData.brokerage)
                          .toFixed(2) : '0.00'
                    }}
                  </div>
                  <div class="item-unit">(USDT)</div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <a-card :bordered="false" :body-style="{width: '100%'}">
      </a-card>
    </div>

    <div class="margin-top-sm">
      <a-card :bordered="false" :body-style="{width:'1200px', padding: '0'}">
        <template #title>
          <div class="flex-title">
            <div class="line-shu"></div>
            <div class="ml-2">邀请详情</div>
          </div>
        </template>
        <div>
          <div class="table-top">
            <div class="menu-group">
              <div class="menu" :class="grade === 0 ? 'back-select' :''" @click="setType(0)">一级(<span
                  class="pepole-count">{{ peopleData.firstSpreadNum ? peopleData.firstSpreadNum : '0' }}</span>)
              </div>
              <div class="menu" :class="grade === 1 ? 'back-select' :''" @click="setType(1)">二级(<span
                  class="pepole-count">{{ peopleData.secondSpreadNum ? peopleData.secondSpreadNum : '0' }}</span>)
              </div>
              <div class="menu" :class="grade === 2 ? 'back-select' :''" @click="setType(2)">三级(<span
                  class="pepole-count">{{ peopleData.thirdSpreadNum ? peopleData.thirdSpreadNum : '0' }}</span>)
              </div>
            </div>
            <div class="search">
              <a-input :placeholder="'关键词'" v-model:value="keywords">
                <template #prefix>
                  <Icon type="md-search" color="#BFBFBF"/>
                </template>
                <template #suffix>
                  <a-button :size="'small'" type="link" @click="submitForm">搜索</a-button>
                </template>
              </a-input>
            </div>
          </div>
          <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="listData">
            <template #headerCell="{ column }">
              <template v-if="column.key === 'time'">
                <div class="text-left">{{ column.title }}</div>
              </template>
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'nickname'">
                <div class="text-bold">{{ record.nickname }}</div>
              </template>
              <template v-if="column.key === 'uid'">
                <div class="text-gray">{{ record.uid }}</div>
              </template>
              <template v-if="column.key === 'type'">
                <div class="text-bold">{{ record.type }}</div>
              </template>
              <template v-if="column.key === 'joinTime'">
                <div class="text-gray">{{ record.joinTime }}</div>
              </template>
              <template v-if="column.key === 'joinBrokerageSum'">
                <div class="text-red text-bold">{{ record.joinBrokerageSum ? record.joinBrokerageSum : '0.00' }} USDT
                </div>
              </template>
              <template v-if="column.key === 'orderBrokerageSum'">
                <div class="text-red text-bold">{{ record.orderBrokerageSum ? record.orderBrokerageSum : '0.00' }}
                  USDT
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </a-card>
      <!-- 分页 -->
      <div class="page-size mt-3" v-if="listData && listData.length > 0">
        <a-pagination :page-size="pageData.limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>
<script>
import { inviteData, inviteDataList, } from '@/api/tg.js'

export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '用户',
          dataIndex: 'nickname',
          key: 'nickname'
        }, {
          title: 'ID',
          dataIndex: 'inviteCode',
          key: 'inviteCode'
        }, {
          title: '加入时间',
          dataIndex: 'joinTime',
          key: 'joinTime'
        }, {
          title: '入驻费返佣',
          dataIndex: 'joinBrokerageSum',
          key: 'joinBrokerageSum'
        }, {
          title: '返佣金额',
          dataIndex: 'orderBrokerageSum',
          key: 'orderBrokerageSum'
        },
      ],
      listData: [],
      pageData: {
        page: 1,
        limit: 10,
      },
      total: 0,
      pList: [],
      pInfo: {},
      grade: 0,
      peopleData: {},
      keywords: '',
    }
  },
  created() {
    this.upCallback()
    this.getInviteData()
  },
  methods: {
    submitForm: function () {
      this.listData = []
      this.pageData = {
        page: 1,
        limit: 10,
      }
      this.upCallback()
    },
    setType: function (grade) {
      if (this.grade !== grade) {
        this.listData = []
        this.grade = grade
        this.pageData = {
          page: 1,
          limit: 10,
        }
        this.upCallback()
      }
    },
    getInviteData() {
      inviteData()
          .then(res => {
            this.peopleData = res.data
          })
    },
    upCallback() {
      let data = {
        keyword: this.keywords,
        grade: this.grade,
        page: this.pageData.page,
        limit: this.pageData.limit,
      }
      inviteDataList(data)
          .then((res) => {
            let curPageData = res.data
            if (curPageData && curPageData.length >= 0) {
              this.listData = res.data
              if (this.listData && curPageData.length >= 1) {
                this.total = this.listData[0].total
              }
            }
          })
    },
    changePageNum(val) { // 修改页码
      this.pageData.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.pageData.page = 1
      this.pageData.limit = val
      this.upCallback()
    },
  }
}
</script>

<style scoped lang="scss">
.back-select {
  border: 1px solid #2b85e4 !important;
}

.table-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .search {
    width: 384px;
    height: 37px;
    border-radius: 50px;
    margin-right: 20px;
  }

  .menu-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 20px;

    .menu {
      cursor: pointer;
      width: 98px;
      height: 30px;
      border-radius: 50px;
      box-sizing: border-box;
      border: 1px solid #E8E8E8;
      font-size: 14px;
      font-weight: normal;
      line-height: 26px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 20px;

      .pepole-count {
        color: #F6BC33;
      }
    }
  }
}

.card-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-1 {
    width: 207px;
    height: 144px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F9F8;
  }

  .item-2 {
    width: 100%;
    height: 144px;
    padding: 30px;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-title {
      font-size: 16px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .item-number {
      margin-top: 13px;
      font-size: 38px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-unit {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;
}
</style>

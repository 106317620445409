<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="460"
             centered
             @cancel="cancel">
      <template #title>
        <div class="cu-center">确认支付</div>
      </template>
      <div class="padding-top-xs">
        <div class="d-flex">
          <div class="title-info text-bold me-auto">{{ title }}</div>
          <div class="title-info text-bold text-red">支付金: {{ splus }} USDT</div>
        </div>
        <div class="padding-top-xs">
          <code-input
              @change="completedInput"
              :fields="6"
              :fieldWidth="46"
              :fieldHeight="46"
              :required="true"
          />
        </div>
        <div class="forget-link cursor-pointer text-gray mt-2 me-auto"
             @click="()=>{$router.push({path:'/AccountSafe'})}">
          {{ $t('pwd.pwd_001') }}?
        </div>
        <div class="forget-link cursor-pointer text-green mt-2"
             @click="()=>{$router.push({path:'/MyWallet'})}">
          余额: {{ balance }} USDT
        </div>
      </div>
      <template #footer>
        <div class="cu-center">
          <div class="pay-btn cursor-pointer cu-center" @click="onFinish">{{ btnTitle }}</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '请输入6位支付密码'
    },
    balance: {
      type: Number,
      default: ''
    },
    splus: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    },
    btnTitle: {
      type: String,
      default: '确认支付'
    },
  },
  data() {
    return {
      captcha: '',
    }
  },
  methods: {
    completedInput(e) {
      this.captcha = e
    },
    cancel() {
      this.captcha = ''
      this.$emit('complete', '')
    },
    onFinish(e) {
      if (!this.captcha || this.captcha.length < 6) {
        return this.$Message.error('支付密码不正确')
      }
      this.$emit('complete', this.captcha)
      this.captcha = ''
    },
  }
}
</script>

<style scoped lang="scss">
.forget-link {
  display: flex;
  justify-content: flex-end;
}

.title-info {
  font-size: 15px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: -0.17px;
  color: #3D3D3D;
  padding-right: 10px;
}

.pay-btn {
  width: 410px;
  height: 50px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #FFFFFF;
}
</style>

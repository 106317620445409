<template>
  <arco-scrollbar type="track"
                  style="padding: 15px;height: 420px;overflow-y: scroll;overflow-x:hidden !important;background-color: #F3F3F3;"
                  @scroll="scrollUpTop"
                  ref="scroll" :scrollTop="scrollTop">
    <div v-if="loading" class="cu-center" style="margin-top: 30px">
      <a-spin :spinning="loading"/>
    </div>
    <div class="cu-chat">
      <div v-if="!messages || messages.length == 0" class="cu-info round">
        有问题~找客服~51担保竭诚为您服务
      </div>
      <div v-for="(item, index) in messages">
        <div class="cu-info round cu-center">{{ time(item.msgTime) }}</div>
        <div class="cu-item" :class="item.type === 2 ? '' : 'self'">
          <a-avatar class="cu-avatar" :src="merchantInfo.avatar" v-if="item.type === 2"/>

          <div class="main">
            <div v-if="!item.linkId">
              <div v-if="item.msgType === 0" class="content text-black"
                   :class="item.type === 2 ? '' : 'bg-green'">
                <div>{{ item.title }}</div>
              </div>
              <a-image width="220px" height="120px" v-if="item.msgType === 1" :src="item.icon" mode="aspectFill">
              </a-image>
              <div v-if="item.msgType === 2">
                <vue3-video-play v-bind="playerOptions" :src="item.icon"/>
              </div>
              <div v-if="item.msgType === 3">
                <div class="chat-voice-right content bg-green flex-rows justify-end"
                     :style="{width:handleVoiceWidth(item.title)}"
                     @click="handleVoicePlay(item, index)">
                  <div class="chat-voice-right-inner flex justify-end align-center">
                    <!-- 时长 -->
                    <span decode='true' class="text-black chat-voice-length-right text-sm">
                      {{ item.title }}
                    </span>
                    <!-- 语音条主体 -->
                    <span class="chat-voice-second-right text-black margin-right-xs">"</span>
                    <!-- 语音图标 -->
                    <span class="voice-img"
                          :class="[{ voice_icon_right: true },{ voice_icon_right_an: isPlay && voiceIndex === index }]">
                    </span>
                  </div>
                  <!-- 向右三角形 -->
                  <div class=" trangle trangle-right">
                  </div>
                </div>
              </div>
            </div>
            <div class="content bg-white cursor-pointer" v-if="item.linkId"
                 @click="item.orderNo ? toOrder(item.linkId) : toProduct(item)">
              <div class="cu-card article">
                <div class="cu-item-cus">
                  <div class="title">
                    <div v-if="item.orderNo" class="text-bold text-sm text-cut text-df">
                      订单号: {{ item.orderNo }}
                    </div>
                    <div v-else class="text-bold text-sm text-cut text-df">
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="flex margin-top-xs">
                    <a-image style="border-radius: 10rpx;" :showLoading="true" :src="item.icon"
                             width="80px" height="80px"></a-image>
                    <div class="desc flex-1 flex flex-col justify-between">
                      <div class="text-red margin-left-xs text-df">
                        {{ item.price }}
                        <span class="text-sm margin-left-xs">USDT</span>
                        <div v-if="item.type === 2 && !item.orderNo"
                             class="text-sm text-gray margin-left-xs">
                          x {{ item.payNum }}
                        </div>
                      </div>
                      <div v-if="item.type === 2"
                           @click="toProduct(item)"
                           class="text-blue margin-left-xs text-sm flex justify-between align-center  cursor-pointer">
                        <span v-if="!item.downOrder">{{ item.orderNo ? '立即付款' : '立即下单' }}</span>
                        <span v-else class="text-gray">{{ '已支付' }}</span>
                        <span v-if="!item.downOrder" class="text-xs text-gray margin-left-xs">
														{{
                            item.merIsDel ? '服务结束' : comparedateEnable(item.msgTime) ? '已过期' : '1小时后过期'
                          }}
												</span>
                      </div>
                      <div class="text-gray margin-left-xs text-sm">
                        {{ item.orderTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a-avatar class="cu-avatar" :src="userInfo.avatar" v-if="item.type === 1 && userInfo.avatar">
            <template #icon>
              <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
            </template>
          </a-avatar>
          <a-avatar class="cu-avatar" v-if="item.type === 1 && !userInfo.avatar">我</a-avatar>
          <div class="date" v-if="item.type === 1">{{ item.isRead ? '已读' : '未读' }}</div>
        </div>
      </div>

      <div v-if="tempMessages !== null" class="cu-item self margin-top">
        <div class="main">
          <div class="content bg-green shadow" v-if="!tempMessages.price && tempMessages.msgType === 0">
            <span>{{ tempMessages.title }}</span>
          </div>
          <a-image :src="tempMessages.icon" mode="aspectFit" height="20px"
                   v-if="!tempMessages.price && tempMessages.msgType === 1">
          </a-image>
          <div class="content bg-white shadow" v-if="tempMessages.price">
            <div class="cu-card article">
              <div class="cu-item-cus">
                <div class="title">
                  <div v-if="tempMessages.orderNo" class="text-bold text-sm text-cut text-df">
                    订单号: {{ tempMessages.orderNo }}
                  </div>
                  <div v-else class="text-bold text-sm text-cut text-df">
                    {{ tempMessages.title }}
                  </div>
                </div>
                <div class="flex margin-top-xs">
                  <a-image :showLoading="true" :src="tempMessages.icon" width="80px" height="80px"></a-image>
                  <div class="desc flex-1 flex flex-col justify-between">
                    <div class="text-red margin-left-xs text-lg">
                      {{ tempMessages.price }}
                      <span class="text-sm margin-left-xs">USDT</span>
                    </div>
                    <div class="text-gray margin-left-xs text-sm">
                      <div class="cu-tag margin-bottom-xs text-blue round text-sm" @click="sendOrderMsg(0)">
                        {{ tempMessages.orderTime ? '发送订单' : '发送产品' }}
                      </div>
                      <div>{{ tempMessages.orderTime }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cu-avatar round" :style="`background-image:url(${userInfo.avatar})`">
        </div>
      </div>
    </div>
  </arco-scrollbar>
</template>

<script>
import { cusInfoSave, msgCusInfoList } from '@/api/message'
import { formatChatListTime } from '@/utils/chat'
import { getProductDetail, } from '@/api/product.js'

import { getMerIndexInfoApi, } from '@/api/merchant.js'
import { preOrderApi } from '@/api/order'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  name: 'message',
  props: {
    // 数据
    selectItem: {
      type: Object,
      default: {}
    },
    // 刷新INDEX
    refreshIndex: {
      type: Number,
      default: -1
    },
    // 商品信息
    productInfo: {
      type: Object,
      default: {}
    },
    // 预发布信息
    tempMessages: {
      type: Object,
      default: null
    },
  },
  watch: {
    refreshIndex: {
      handler(val) {
        this.queryMsgCusList()
      }
    },
    tempMessages: {
      handler(val) {
        this.handleScroll()
      }
    },
  },
  data() {
    return {
      messages: [],
      page: 1,
      limit: 10,
      merInfo: {},
      isPlay: false,
      voiceIndex: -1,
      audio: null,
      scrollTopInt: null,
      loading: false,
      merchantInfo: {},
      scrollTop: 0,
      playerOptions: {
        width: '544px', //播放器高度
        height: '592px', //播放器高度
        color: '#409eff', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: '', //视频源
        poster: '', //封面
      },
      top1msg: '是否有优惠?',
      top3msg: '购买后有什么服务?',
      top2msg: '其他问题~',
      dbOrderNo: '',
      skuArr: [],
      skuArrId: 0,
      inviteUserId: '',
    }
  },
  created() {
    this.userInfo = this.$store.getters.userInfo
    FingerprintJS.load()
        .then(fp => {
          fp.get()
              .then(result => {
                let visitorId = result.visitorId
                this.inviteUserId = visitorId
              })
        })
  },
  methods: {
    toOrder(orderNo) {
      this.$router.push({
        path: '/OrderDetails',
        query: {
          orderNo: orderNo,
        }
      })
    },
    toProduct(item) {
      if (item.type === 1 && item.downOrder) {
        this.$router.push({
          path: '/Product',
          query: {
            id: item.linkId,
          }
        })
      }
      if (item.type === 2 && !item.downOrder) {
        this.getPreOrderCreate(item)
      }
    },
    /**
     * 预下单
     */
    getPreOrderCreate(item) {
      getProductDetail(item.linkId, 'normal')
          .then(res => {
            let data = res.data
            for (let key in data.productValue) {
              let obj = data.productValue[key]
              this.skuArr.push(obj)
            }
            this.getPreOrderLink('buyNow',
                [{
                  'attrValueId': parseFloat(this.skuArr[0].id),
                  'productId': parseFloat(item.linkId),
                  'productNum': parseFloat(item.payNum)
                }], item.chatToken)
          })
    },
    getPreOrderLink(preOrderType, orderDetails, chatToken) {
      return new Promise((resolve, reject) => {
        preOrderApi({
          'preOrderType': preOrderType,
          'orderDetails': orderDetails,
          'chatOrderId': chatToken
        })
            .then(res => {
              if (res.code === 200) {
                this.$router.push({
                  path: '/OrderConfirm',
                  query: {
                    orderNo: res.data.orderNo,
                  }
                })
              } else {
                this.$Message.error(res.message)
              }
            })
      })
    },
    comparedateEnable(date1) {
      let oDate1 = new Date(date1)
      let oDate2 = new Date()
      if (oDate2.getTime() - oDate1.getTime() >= 3600000) {
        return true
      }
      return false
    },
    sendOrderMsg(index) {
      let data = {}
      if (this.tempMessages.orderNo) {
        data = {
          type: 1,
          merId: this.selectItem.merId,
          linkId: this.tempMessages.orderNo,
          msgType: 0,
          orderNo: this.tempMessages.orderNo,
          title: this.tempMessages.orderNo,
          icon: this.tempMessages.icon,
          price: this.tempMessages.price,
          isSend: true,
          orderTime: this.tempMessages.orderTime,
          inviteUserId: this.inviteUserId,
        }
      } else {
        data = {
          type: 1,
          merId: this.selectItem.merId,
          linkId: this.productInfo.id,
          msgType: 0,
          orderNo: '',
          title: this.productInfo.name,
          icon: this.productInfo.image,
          price: this.productInfo.price,
          isSend: false,
          inviteUserId: this.inviteUserId,
        }
      }
      cusInfoSave(data)
          .then(res => {
            this.$emit('refresh', 'clear')
          })
    },
    sendMsg(index) {
      let data = {
        type: 1,
        merId: this.selectItem.merId,
        linkId: '',
        msgType: 0,
        orderNo: '',
        title: index === 1 ? this.top1msg : index === 2 ? this.top2msg : index === 3 ? this.top3msg : '',
        icon: '',
        price: '',
        isSend: false,
        msgTime: '',
        inviteUserId: this.inviteUserId,
      }
      cusInfoSave(data)
          .then(res => {
            this.$emit('refresh', 'clear')
          })
    },
    handleScroll() {
      setTimeout(() => {
        this.scrollTop += 999999
        this.$refs.scroll.scrollTop(999999)
        this.page = 1
      }, 500)
    },
    getMerIndexInfo() {
      if (!this.selectItem.merId || this.selectItem.merId <= 0) {
        this.merchantInfo.avatar = 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/public/maintain/2024/08/26/414edd11b8844acc9ad9831efe1ea9f9Y1k0qYe95p.jpg'
        return
      }
      getMerIndexInfoApi(this.selectItem.merId)
          .then(res => {
            this.merchantInfo = res.data
          })
    },
    scrollUpTop(e) {
      if (e.target.scrollTop === 0) {
        this.page = this.page + 1
        this.queryMsgCusList()
        e.target.scrollTop = 30
      }
    },
    handleVoiceWidth(lenght) {
      lenght = lenght - 1
      let Lmin = 69
      let Lmax = 183
      let barCanChangeLen = Lmax - Lmin
      // 11秒以内的语音
      if (lenght < 11) {
        // VoicePlayTimes 为10秒时，正好为可变长度的一半
        return (Lmin + lenght * 0.05 * barCanChangeLen) + 'px'
      } else {
        // 12-60秒的语音
        return (Lmin + 0.5 * barCanChangeLen + (lenght - 10) * 0.01 * barCanChangeLen) + 'px'
      }
    }, // 播放语音
    handleVoicePlay(item, index) {
      this.voiceIndex = index
      this.isPlay = !this.isPlay
      if (!this.isPlay) {
        this.voiceIndex = -1
      }
      if (this.audio) {
        this.audio.pause()
        this.audio = null
      }
      this.audio = new Audio(item.icon)
      this.isPlay ? this.audio.play() : this.audio.pause()
      this.audio.onended = () => {
        this.isPlay = false
        this.voiceIndex = -1
      }
    },
    queryMsgCusList() {
      let that = this
      that.loading = true
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  // 商户信息
                  that.getMerIndexInfo()
                  let dataParam = {
                    page: that.page,
                    limit: that.limit,
                    merId: that.selectItem.merId,
                    headImg: visitorId
                  }
                  msgCusInfoList(dataParam)
                      .then(res => {
                        if (that.page > 1) {
                          const curPageData = res.data.list
                          if (curPageData && curPageData.length > 0) {
                            that.messages = curPageData.concat(that.messages)
                          }
                        } else {
                          that.messages = res.data.list
                        }
                        that.loading = false
                        that.handleScroll()
                      })
                })
          })
    },
    time(dateStr) {
      return formatChatListTime(dateStr)
    }
  }
}
</script>

<style lang="scss" scoped>
.border-cus {
  border: 1px solid #E8E8E8;
}

.voice_icon_right {
  background-image: url('../../assets/chat/voice-left-3.png');
  margin-left: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.voice_icon_left {
  background-image: url('../../assets/chat/voice-right-3.png');
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.voice_icon_right_an {
  animation: voiceAn_right 1s linear alternate infinite;
}

.voice_icon_left_an {
  animation: voiceAn_left 1s linear alternate infinite;
}

@keyframes voiceAn_right {
  0% {
    background-image: url('../../assets/chat/voice-left-1.png');
  }

  50% {
    background-image: url('../../assets/chat/voice-left-2.png');
  }

  100% {
    background-image: url('../../assets/chat/voice-left-3.png');
  }
}

@keyframes voiceAn_left {
  0% {
    background-image: url('../../assets/chat/voice-right-1.png');
  }

  50% {
    background-image: url('../../assets/chat/voice-right-2.png');
  }

  100% {
    background-image: url('../../assets/chat/voice-right-3.png');
  }
}

.chat-voice-right {
  height: 35px;
  padding: 0 0 0 -5px !important;

  .chat-voice-right-inner {
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center;

    .voice-img {
      margin-right: 2px;
      height: 15px;
      width: 15px;
    }
  }
}


.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg-green {
  background-color: #39b54a;
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
  color: #666666;
}

.el-image {
  width: 260px;
  height: 160px;
}

.cu-chat {
  display: flex;
  flex-direction: column;
}

.cu-chat .cu-item {
  display: flex;
  position: relative;
}

.cu-chat .cu-item > .cu-avatar {
  width: 40px;
  height: 40px;
}

.cu-chat .cu-item > .main {
  max-width: calc(100% - 80px);
  margin: 0 20px;
  display: flex;
  align-items: center;
  word-break: break-all;
  word-wrap: break-word;
}

.cu-chat .cu-item > image {
  height: 160px;
}

.cu-chat .cu-item > .main .content {
  padding: 10px;
  border-radius: 3px;
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  font-size: 15px;
  position: relative;
  min-height: 40px;
  line-height: 20px;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}

.cu-chat .cu-item > .main .content:not([class*="bg-"]) {
  background-color: #ffffff;
  color: #333333;
}

.cu-chat .cu-item .date {
  position: absolute;
  font-size: 12px;
  color: #8799a3;
  width: calc(100% - 160px);
  bottom: -17px;
  left: 100px;
}

.cu-chat .cu-item .action {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.cu-chat .cu-item > .main .content::after {
  content: "";
  top: 13.5px;
  transform: rotate(45deg);
  position: absolute;
  z-index: 100;
  display: inline-block;
  overflow: hidden;
  width: 12px;
  height: 12px;
  left: -6px;
  right: initial;
  background-color: inherit;
}

.cu-chat .cu-item.self > .main .content::after {
  left: auto;
  right: -6px;
}

.cu-chat .cu-item > .main .content::before {
  content: "";
  top: 15px;
  transform: rotate(45deg);
  position: absolute;
  z-index: -1;
  display: inline-block;
  overflow: hidden;
  width: 12px;
  height: 12px;
  left: -12px;
  right: initial;
  background-color: inherit;
  filter: blur(2.5px);
  opacity: 0.3;
}

.cu-chat .cu-item > .main .content:not([class*="bg-"])::before {
  background-color: #333333;
  opacity: 0.1;
}

.cu-chat .cu-item.self > .main .content::before {
  left: auto;
  right: -6px;
}

.cu-chat .cu-item.self {
  justify-content: flex-end;
  text-align: right;
}

.cu-chat .cu-info {
  margin: 10px auto;
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 3px;
  line-height: 1.4;
}

#message {
}


.text-xs {
  font-size: 10px;
}

.text-sm {
  font-size: 12px;
}

.text-df {
  font-size: 14px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-Abc {
  text-transform: Capitalize;
}

.text-ABC {
  text-transform: Uppercase;
}

.text-abc {
  text-transform: Lowercase;
}

.text-price::before {
  content: "¥";
  font-size: 80%;
  margin-right: 4upx;
}

.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-content {
  line-height: 1.6;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red,
.line-red,
.lines-red {
  color: #e54d42;
}

.text-orange,
.line-orange,
.lines-orange {
  color: #f37b1d;
}

.text-yellow,
.line-yellow,
.lines-yellow {
  color: #fbbd08;
}

.text-olive,
.line-olive,
.lines-olive {
  color: #8dc63f;
}

.text-green,
.line-green,
.lines-green {
  color: #39b54a;
}

.text-cyan,
.line-cyan,
.lines-cyan {
  color: #1cbbb4;
}

.text-blue,
.line-blue,
.lines-blue {
  color: #0081ff;
}

.text-purple,
.line-purple,
.lines-purple {
  color: #6739b6;
}

.text-mauve,
.line-mauve,
.lines-mauve {
  color: #9c26b0;
}

.text-pink,
.line-pink,
.lines-pink {
  color: #e03997;
}

.text-brown,
.line-brown,
.lines-brown {
  color: #a5673f;
}

.text-grey,
.line-grey,
.lines-grey {
  color: #8799a3;
}

.text-gray,
.line-gray,
.lines-gray {
  color: #aaaaaa;
}

.text-black,
.line-black,
.lines-black {
  color: #333333;
}

.text-white,
.line-white,
.lines-white {
  color: #ffffff;
}
</style>

<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="820"
             @close="cancel"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">发布曝光、资讯</div>
      </template>
      <a-form
          :model="formData"
          name="basic"
          autocomplete="off"
          @finish="onFinish">
        <a-form-item name="content" :rules="[{ required: true, message: '请输入您要发布的资讯信息' }]">
          <div class="bg-gray rounded">
            <a-textarea v-model:value="formData.content"
                        placeholder="请输入您要发布的资讯信息"
                        :rows="15"
                        :maxlength="2000"
                        :showCount="true"
                        :bordered="false"
            ></a-textarea>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="ml-2">
            <a-upload :action="uploadUrl"
                      list-type="picture-card"
                      :accept="acceptStr"
                      :name="'multipart'"
                      :maxCount="6"
                      v-model:file-list="fileList"
                      multiple
                      @remove="removes"
                      @change="handleChange"
                      @beforeUpload="beforeUpload"
                      @preview="handlePreview">
              <div>
                <icon-image/>
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
              <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage"/>
              </a-modal>
            </a-upload>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="text-right">
            <a-button type="primary" html-type="submit">发布</a-button>
          </div>
        </a-form-item>
      </a-form>
      <template #footer/>
    </a-modal>
  </div>
</template>
<script>
import { BASE_URL } from '@/config/index.js'

export default {
  name: 'Evaluate',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    formDataPar: {
      type: Object,
      default: null,
    }
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.fileList = []
          if (this.formDataPar) {
            this.formData = {
              content: this.formDataPar.content,
              images: this.formDataPar.images
            }
            if (this.formDataPar.images && this.formDataPar.images.length > 0) {
              this.formDataPar.images.forEach(item => {
                this.fileList.push({ url: item })
              })
            }
          }
        }
      }
    }
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewTitle: '',
      uploadUrl: '',
      previewImage: '',
      acceptStr: 'image/*',
      formData: {
        content: '',
        images: []
      }
    }
  },
  created() {
    this.formData = {
      content: '',
      images: []
    }
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
  },
  methods: {
    onFinish() {
      this.$emit('change', this.formData)
      this.formData = {
        content: '',
        images: []
      }
    },
    cancel() {
      this.$emit('change')
      this.formData = {
        content: '',
        images: []
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
    },
    removes(e) {
      console.log(e)
      return new Promise((resolve, reject) => {
        let uploadsFile = []
        this.formData.images.forEach(item => {
          if (e.response && item !== e.response.data.url) {
            uploadsFile.push(item)
          }
          if (e.url && item !== e.url) {
            uploadsFile.push(item)
          }
        })
        this.formData.images = uploadsFile
        resolve(true)
      })
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    handleChange(info) {
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        this.formData.images.push(response.data.url)
      }
    }
  },
}
</script>

<style scoped lang="scss">
textarea {
  resize: none
}
</style>

<template>
  <div class="mb-4">
    <a-card :bordered="false">
      <a-steps :current="current" :items="items" label-placement="vertical" size="small"></a-steps>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'orderSteps',
  props: {
    current: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      items: [
        {
          title: '确认订单信息',
        },
        {
          title: '支付',
        },
        {
          title: '支付结果',
        },
      ]
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">

</style>
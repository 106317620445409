<template>
  <div class="width_1200_auto">
    <div class="notic-bar">
      <Icon custom="iconfont icon-xitonggonggao" class="notice-img" style="font-size: 20px;"/>
      <div class="notice-bar-container">
        <div class="notice-bar__wrap">
          <vue3ScrollSeamless class="scroll-wrap" :dataList="list">
            <ul class="ui-wrap">
              <li class="li-item" v-for="(item,i) of list" :key="i">
                <div class="line1">{{ item }}</div>
              </li>
            </ul>
          </vue3ScrollSeamless>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vue3ScrollSeamless } from 'vue3-scroll-seamless'

export default {
  name: 'NoticeTop',
  components: {
    vue3ScrollSeamless
  },
  props: {
    list: {
      type: Object,
      default: []
    }
  },
  data() {
  },
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.scroll-wrap {
  min-width: 1360px !important;
  margin: 0 auto;
  z-index: 9999;
  overflow: hidden;
}

.notic-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: white;
  border-radius: 5px;
  padding: 2px 5px;
}

.notice-bar-container {
  display: flex;
  height: 20px;
  overflow: hidden;
  margin-left: 5px;
  width: 100% !important;
}

.notice-img {
  width: 24px;
  height: 24px;
  color: red;
}

.notice-bar__wrap {
  margin-left: 10px;
  display: flex;
  animation: move 50s linear infinite;
  line-height: 20px;
  color: red;

  .notice-bar__wrap_text {
    width: max-content;
    min-width: 100px;
  }
}

@keyframes move {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>

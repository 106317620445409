<template>
  <div class="container">
    <!-- 固定头部 -->
    <couponModal :coupon-modal="couponModal" :couponList="couponList" @close="couponModal = false"/>
    <!--  <hover-search class="hover-search" :class="{show: topSearchShow}"></hover-search>-->
    <!-- 头部 包括登录等 -->
    <BaseHeader></BaseHeader>
    <!-- 搜索框、logo -->
    <Search @onSearch="search"></Search>
    <!-- 商品分类 -->
    <cateNav v-show="showCate" :showAlways="true" :large="false" :opacity="false"></cateNav>
    <!-- 下滑线条 -->
    <div class="line-border"></div>
    <!-- 路由 -->
    <router-view/>
    <!-- 底部栏 -->
    <BaseFooter></BaseFooter>
    <!-- 侧边栏 -->
    <fixedBar v-if="topImgShow" class="fixed-bar" :showTop="topShow"></fixedBar>
  </div>
</template>

<script>
import BaseHeader from '@/components/header/Header.vue'
import BaseFooter from '@/components/footer/Footer.vue'
import fixedBar from '@/components/fixed/index.vue'
import couponModal from '@/components/couponModal/index.vue'

export default {
  name: 'Index',
  components: {
    BaseHeader,
    BaseFooter,
    fixedBar,
    couponModal,
  },
  data() {
    return {
      autoCoupList: [],
      showCpmodel: false,
      // 顶部广告
      showNav: true, // 是否展示分类栏
      topSearchShow: true, // 滚动后顶部搜索栏展示
      carouselLarge: false, // 不同轮播分类尺寸
      carouselOpacity: false, // 不同轮播分类样式.
      topShow: false,
      showCate: true,
      switchArr: ['MerHome', 'MerInfo'],
      screenWidth: document.body.clientWidth,
      topImgShow: true,
      couponList: [],
      couponModal: false
    }
  },
  props: {
    pageData: {
      type: null,
      default: ''
    }
  },
  watch: {
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          let obj = this.switchArr.indexOf(newVal)
          if (obj > -1) {
            this.showCate = false
          } else {
            this.showCate = true
          }
        })
      }
    },
  },
  created() {
    let newVal = this.$route.name
    this.$nextTick(() => {
      let obj = this.switchArr.indexOf(newVal)
      if (obj > -1) {
        this.showCate = false
      } else {
        this.showCate = true
      }
    })

    this.getCouponList()
  },
  mounted() {
    let that = this
    window.onscroll = function () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      if (top > 300) {
        that.topShow = true
      } else {
        that.topShow = false
      }
    }
    that.restWindows()
    if (that.$store.getters.token) {
      that.getUserInfo()
    }
  },
  methods: {
    //优惠券列表
    getCouponList() {
      this.couponList = JSON.parse(localStorage.getItem('newGift')) ? JSON.parse(localStorage.getItem('newGift')) : []
      if (this.couponList && this.couponList.length > 0) {
        this.couponModal = true
      }
    },
    getUserInfo() {
      this.$store.dispatch('USERINFO')
          .then(res => {
            this.userInfo = res
            this.avatar = this.userInfo.avatar
            this.nickname = this.userInfo.nickname
          })
    },
    restWindows() {
      const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          that.screenWidth = window.screenWidth
          if (that.screenWidth < 1210) {
            that.topImgShow = false
          } else {
            that.topImgShow = true
          }
        })()
      }
    },
    search(key) {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: key.keyword,
          selectFlag: key.selectFlag,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.msg-cus {
  width: 120px;
  height: 120px;
}

.line-border {
  border-bottom: 2px solid #269FFA;
}

.hover-search {
  width: 100%;
  height: 60px;
  transform: translateY(-200px);
  position: fixed;
  top: 0;
  z-index: 9999;
  //box-shadow: 0 0 10px 2px rgb(90 90 90 / 60%);
  transition: 0.35s;
}

.show {
  transform: translateY(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  top: 0;
}

.fixed-bar {
  //-webkit-transform: translateY(50%)  !important;
  //transform: translateY(50%)  !important;
  //transition: .35s  !important;
  //overflow: hidden  !important;
  //position: fixed  !important;
  //right: 0  !important;
  //top: 50%  !important;
  position: fixed;
  right: 0;
  bottom: 50% !important;
  -webkit-transform: translateY(50%) !important;
  -moz-transform: translateY(50%) !important;
  -ms-transform: translateY(50%) !important;
  -o-transform: translateY(50%) !important;
  transform: translateY(50%) !important;
  z-index: 200 !important;
  -webkit-box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18) !important;
  -moz-box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18) !important;
  box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18) !important;
}

.fixed-bar-msg {
  opacity: 1 !important;
  transform: translateY(50%);
  transition: .35s;
  z-index: 210;
  overflow: hidden;

  position: fixed;
  right: 130px;
  top: 20%;

  :deep(.ant-badge .ant-badge-count) {
    top: 10px !important;
    right: 15px !important;
  }

}

.logo-img {
  max-width: 150px;
  height: 43px !important;
  cursor: pointer;
}

.overflow-disable {
  width: 1200px;
  overflow: auto !important;
}

</style>

<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Enter">
          商家入驻
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        审核不通过
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="margin-top-sm">
      <a-card :bordered="false" :body-style="{width: '100%'}">
        <div class="content margin-top-xl">
          <a-image :src="require('@/assets/join/join-failed.png')" :preview="false" :width="192" :height="142"/>
          <div class="result-info">
            抱歉您的商户入驻申请未通过！
          </div>
          <div class="result-info-content">
            {{ $route.query.denialReason }}
          </div>
          <div class="margin-top margin-bottom-lg cursor-pointer">
            <div class="btn-ftom" @click="goDetal">
              重新申请
            </div>
          </div>
          <div class="form-info-warning">温馨提示:请修改错误内容重新提交申请即可！</div>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goDetal() {
      this.$router.push({
            path: '/Enter',
            query: {
              key: 1,
              orderNo: this.$route.query.orderNo,
            }
          },
      )
    }
  }
}
</script>

<style scoped lang="scss">
.btn-ftom {
  width: 206px;
  height: 44px;
  border-radius: 50px;
  background: #269FFA;

  font-size: 16px;
  font-weight: 350;
  line-height: 25px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form-info-warning {
  width: 810px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0px;
  color: #7E7E7E;

  display: flex;
  justify-content: center;
  align-items: center;
}

.copy {
  width: 85px;
  height: 30px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #C3C3C3;

  font-size: 13px;
  font-weight: normal;
  line-height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content-info {
    padding: 20px;
    margin-top: 62px;
    width: 810px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;

    .form-info {
      font-size: 14px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}

.result-info {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #ED4F4F;
  margin-top: 28px;
}

.result-info-content {
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  color: #999999;
  margin-top: 28px;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;

  .card-cu {
    height: 475px;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 25px;
  }

  .content-font-1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
  }

  .content-font-2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #999999;

    .conten-customer {
      color: #269FFA;
    }
  }

  .content-btn {
    width: 115px;
    height: 38px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #FFFFFF;
  }
}
</style>

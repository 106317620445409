<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Enter">
          商家入驻
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        申请历史
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="margin-top-sm">
      <a-card :bordered="false" title="申请记录">
        <empty v-if="listData.length === 0 && !loading"/>
        <a-table v-else :pagination="false" :loading="loading" :columns="columns" :data-source="listData">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <div class="text-blue">{{ record.name }}</div>
            </template>
            <template v-if="column.key === 'createTime'">
              {{ record.createTime }}
            </template>
            <template v-if="column.key === 'handlingFee'">
              <div class="text-red text-bold" v-if="!record.isPay">{{ record.handlingFee }} USDT</div>
              <div class="text-red text-bold" v-if="record.isPay">{{ record.factPrice }} USDT</div>
            </template>
            <template v-else-if="column.key === 'auditStatus'">
              <div class="flex align-center">
                <a-image :width="24" :height="24" :preview="false" class="status-icon" v-if="record.auditStatus === 1"
                         :src="require('@/assets/images/pending.png')" mode=""></a-image>
                <a-image :width="24" :height="24" :preview="false" class="status-icon"
                         v-else-if="record.auditStatus === 2" :src="require('@/assets/images/passed.png')"
                         mode=""></a-image>
                <a-image :width="24" :height="24" :preview="false" class="status-icon"
                         v-else-if="record.auditStatus === 3 || record.auditStatus === 4"
                         :src="require('@/assets/images/not-pass.png')" mode=""></a-image>
                <text v-if="!record.isPay && record.auditStatus === 1 " class="text-gray margin-left-xs">待支付</text>
                <text v-else-if="record.auditStatus === 2" class="text-green text-bold margin-left-xs">
                  {{ statusText(record.auditStatus) }}
                </text>
                <text v-else-if="record.auditStatus === 3 || record.auditStatus === 4"
                      class="status-text margin-left-xs">{{ statusText(record.auditStatus) }}
                </text>
                <text v-else class="status-text margin-left-xs">{{ statusText(record.auditStatus) }}</text>
              </div>
            </template>

            <template v-else-if="column.key === 'action'">
              <div>
                <!-- 支付 -->
                <text @click="joinVip(record)" v-if="!record.isPay&&record.auditStatus === 1"
                      class="btn margin-right-xs shadow cursor-pointer">
                  支付
                </text>
                <!-- 编辑 -->
                <text @click="goDetal(record)" v-if="!record.isPay&&record.auditStatus === 1"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  编辑
                </text>
                <!-- 取消后或者审核不通过编辑 -->
                <text @click="goDetal(record)" v-if="!record.isPay&&(record.auditStatus === 4)"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  编辑
                </text>
                <!-- 审核不通过编辑 -->
                <text @click="goDetalInfo(record)" v-if="record.auditStatus === 3"
                      class="btn1-cus margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 查看 -->
                <text @click="goDetal(record)" v-if="record.isPay &&record.auditStatus === 1"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 审核成功 -->
                <text @click="shareView(record)" v-if="record.auditStatus === 2"
                      class="btn2 margin-right-xs shadow cursor-pointer">
                  商户信息
                </text>
                <!-- 审核失败 -->
                <text @click="goDetal(record)" v-if="record.auditStatus === 4"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 取消 -->
                <text @click="goCanceled(record)" v-if="(record.auditStatus === 1 && record.auditStatus !== 4)"
                      class="btn3 margin-right-xs shadow cursor-pointer">
                  取消
                </text>
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
      <!-- 分页 -->
      <div class="page-size margin-top-xs" v-if="listData && listData.length > 0">
        <a-pagination :page-size="pageData.limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>
<script>
import { getMerSettledRecordApi, setApplyCancel } from '@/api/merchant'

export default {
  data() {
    return {
      columns: [
        {
          title: '店铺名称',
          key: 'name'
        },
        {
          title: '提交时间',
          key: 'createTime'
        },
        {
          title: '状态',
          key: 'auditStatus'
        },
        {
          title: '金额',
          key: 'handlingFee'
        },
        {
          title: '',
          key: 'action'
        }
      ],
      loading: false,
      listData: [],
      pageData: {
        page: 1,
        limit: 15,
      },
      total: 0
    }
  },
  created() {
    this.getListData()
  },
  methods: {
    shareView(item) {
      this.$router.push({
        path: '/AuditSuc',
        query: {
          account: item.realName,
        }
      })
    },
    goDetalInfo(item) {
      this.$router.push({
        path: '/AuditErr',
        query: {
          key: '1',
          orderNo: item.id,
          denialReason: item.denialReason,
        }
      })
    },
    goDetal(item) {
      this.$router.push({
        path: '/Enter',
        query: {
          key: '1',
          orderNo: item.id,
        }
      })
    },
    goCanceled(item) {
      // 取消
      setApplyCancel(item)
          .then(res => {
            if (res.code === 200) {
              this.listData = []
              this.pageData = {
                page: 1,
                limit: 10,
              }
              this.getListData()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    joinVip(item) {
      this.$router.push({
        path: '/JoinPay',
        query: {
          id: item.id,
        }
      })
    },
    getListData() {
      this.loading = true
      getMerSettledRecordApi(this.pageData)
          .then(res => {
            this.total = res.data.total
            this.listData = res.data.list
            this.loading = false
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.pageData.page = val
      this.getListData()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.pageData.page = 1
      this.pageData.limit = val
      this.getListData()
    },
    statusText(number) {
      return number === 1 ? '待审核' : number === 2 ? '审核通过' :
          number === 3 ? '审核未通过' :
              number === 4 ? '已取消' : ''
    },
    statusBtn(number) {
      return number === 0 ? '编辑' : number === 1 ? '查看' :
          number === 2 ? '重新提交' : ''
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;

  .card-cu {
    height: 475px;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 25px;
  }

  .content-font-1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
  }

  .content-font-2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #999999;

    .conten-customer {
      color: #269FFA;
    }
  }

  .content-btn {
    width: 115px;
    height: 38px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #FFFFFF;
  }
}

.btn {
  font-size: 14px;
  color: #fff;
  padding: 7px 16px;
  border-radius: 30px;
  background-color: #FEB902;
}

.btn1 {
  font-size: 14px;
  color: #269FFA;
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #269FFA;
}

.btn1-cus {
  font-size: 14px;
  color: #ED4F4F;
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #ED4F4F;
}

.btn2 {
  font-size: 14px;
  color: #fff;
  padding: 7px 16px;
  border-radius: 30px;
  background-color: #FEB902;
}

.btn3 {
  font-size: 14px;
  color: #999999;
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #999999;
}
</style>

<template>
  <div class="h5_push" v-if="show" @tap="handleClick">
    <a-card :bordered="false" :bodyStyle="{padding: '0'}">
      <div class="push-title">
        <div class="push-type">
          <a-image class="image" :height="24" :width="24" :preview="false"
                   :src="require('@/assets/logo.png')"></a-image>
          <span class="ml-2">{{ messageType }}</span>
        </div>
        <span class="ml-2 text-df cursor-pointer" @click="closeMsg"><Icon type="ios-close" :size="24"/> </span>
      </div>
      <div class="push-body mb-4">
        <div class="push-content">
          <div class="push-content-title">{{ messageTitle }}</div>
          <div class="push-content-text">{{ messageContent }}</div>
        </div>
        <a-image :preview="false" class="push-img" :height="60" :width="60" :src="messageImage"
                 mode="aspectFill"></a-image>
      </div>
    </a-card>
  </div>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: ''
    },
    messageTitle: {
      type: String,
      default: ''
    },
    messageTime: {
      type: String,
      default: '现在'
    },
    messageContent: {
      type: String,
      default: ''
    },
    messageImage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // 关闭时间
      closeTime: 5000,
      // app内横幅提醒
      inApp: false,
      // 声音提醒
      voice: true,
      // 振动提醒
      vibration: false,
      top: 60,
      left: 60,
      ctx: null,
      source: null,
      loop: false, //是否循环
      hasPlay: false, //是否播放 助变量 可忽略辅
      isPause: false, //是否暂停 助变量 可忽略辅
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.playAudio()
        setTimeout(() => {
          this.stopAudio()
          this.$emit('click')
        }, this.closeTime)
      }
    }
  },
  methods: {
    closeMsg() {
      this.stopAudio()
      this.$emit('click')
    },
    // 播放
    async playAudio() {
      this.ctx = new (window.AudioContext || window.webkitAudioContext())()
      this.source = this.ctx.createBufferSource() // 创建音频源头姐点
      const audioBuffer = await this.loadAudio()
      this.playSound(audioBuffer)
    },
    async loadAudio() {
      const audioUrl = require('@/assets/mp3/notice.mp3')
      const res = await fetch(audioUrl)
      const arrayBuffer = await res.arrayBuffer() // byte array字节数组
      const audioBuffer = await this.ctx.decodeAudioData(
          arrayBuffer,
          function (decodeData) {
            return decodeData
          }
      )
      return audioBuffer
    },
    async playSound(audioBuffer) {
      this.source.buffer = audioBuffer // 设置数据
      this.source.loop = false //设置，循环播放
      this.source.connect(this.ctx.destination) // 头尾相连
      // 可以对音频做任何控制
      this.source.start(0) //立即播放
      this.hasPlay = true
    },
    // 暂停
    async resumeAudio() {
      if (this.ctx.state === 'running') {
        this.ctx.suspend()
        this.isPause = true
      } else if (this.ctx.state === 'suspended') {
        this.ctx.resume()
        this.isPause = false
      }
    },
    // 结束
    async stopAudio() {
      this.source.stop()
      this.hasPlay = false
      this.isPause = false
    },
    handleClick() {
      this.jump()
      this.$emit('click')
    },
    jump() {
      this.$router.push({
        path: '/Message',
        query: {
          title: this.messageType,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h5_push {
  width: 510px;
  box-shadow: 0 3px 18px 0 rgba(54, 58, 68, 0.08);
  border-radius: 20px;
  position: fixed;
  right: 10px;
  z-index: 9999999;

  .push-title {
    padding: 15px 15px 7.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;

    .push-type {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      .image {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  .push-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 15px;

    .push-content {
      width: calc(100% - 150px);

      .push-content-title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .push-content-text {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .push-img {
      width: 100px;
      height: 100px;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      margin-left: 50px;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>

<template>
  <div class="container width_1200_auto">
    <!--  步骤条 -->
    <OrderSteps :current="2"/>

    <a-spin size="large" :spinning="loading">
      <div class="mer-top-card margin-top-xs" v-if="order_pay_info.paid">
        <a-card :bordered="false" :loading="loading">
          <a-result
              status="success"
              title="支付成功">
            <template #subTitle>
              <div style="width: 262px;margin: 0 auto;">
                <div class="d-flex">
                  <div class="mb-3 me-auto">订单号:</div>
                  <div class="mb-3">{{ order_pay_info.orderNo }}</div>
                </div>
                <div class="d-flex">
                  <div class="mb-3 me-auto">下单时间:</div>
                  <div class="mb-3">{{ order_pay_info.createTime }}</div>
                </div>
                <div class="d-flex">
                  <div class="mb-3 me-auto">支付金额:</div>
                  <div class="mb-3 text-red">{{ order_pay_info.payPrice }} USDT</div>
                </div>
              </div>
            </template>
            <template #extra>
              <a-button key="console" type="primary" @click="()=>{$router.replace({path :'/MyOrder'})}">查看订单
              </a-button>
              <a-button key="buy" @click="()=>{$router.replace({path :'/'})}">去首页</a-button>
            </template>
          </a-result>
        </a-card>
      </div>

      <div class="mer-top-card margin-top-xs" v-else>
        <a-card :bordered="false" :loading="loading">
          <a-result
              status="error"
              title="支付失败">
            <template #subTitle>
              <div style="width: 262px;margin: 0 auto;">
                <div class="d-flex">
                  <div class="mb-3 me-auto">订单号:</div>
                  <div class="mb-3">{{ order_pay_info.orderNo }}</div>
                </div>
                <div class="d-flex">
                  <div class="mb-3 me-auto">下单时间:</div>
                  <div class="mb-3">{{ order_pay_info.createTime }}</div>
                </div>
                <div class="d-flex">
                  <div class="mb-3 me-auto">支付金额:</div>
                  <div class="mb-3 text-red">{{ order_pay_info.payPrice }} USDT</div>
                </div>
              </div>
            </template>
            <template #extra>
              <a-button key="console" type="primary" @click="()=>{$router.replace({path :'/MyOrder'})}">查看订单
              </a-button>
              <a-button key="buy" @click="()=>{$router.replace({path :'/'})}">去首页</a-button>
            </template>
          </a-result>
        </a-card>
      </div>
    </a-spin>
  </div>
</template>
<script>
import {
  getOrderDetail,
} from '@/api/order.js'
import OrderSteps from '@/components/orderSteps/index.vue'

export default {
  name: 'OrderResult',
  components: { OrderSteps },
  data() {
    return {
      loading: false,
      orderNo: '',
      order_pay_info: {
        paid: '',
        orderNo: '',
        createTime: '',
        payPrice: '',
      }
    }
  },
  created() {
    this.orderNo = this.$route.query.orderNo
    this.getOrderPayInfo()
  },
  methods: {
    getOrderPayInfo: function () {
      let that = this
      this.loading = true
      getOrderDetail(that.orderNo)
          .then(res => {
            that.order_pay_info = res.data
            if (res.data.paid) {
              this.loading = false
            } else {
              this.loading = false
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 12px;
  min-height: 1200px;

  .mer-top-card {
    width: 100%;
  }
}
</style>

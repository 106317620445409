<template>
  <div class="shadow-box">
    <!-- 消息中心 -->
    <div class="shadow-item cursor-pointer" @click="() => {$router.push({path: '/Message'})}">
      <a-badge :count="msgCount">
        <div class="msg-cus">
          <Vue3Lottie :animationData="defaultOptions" :width="'100%'" @animCreated="handleAnimation"/>
        </div>
      </a-badge>
    </div>
    <div class="shadow-item flex flex-a-c flex-j-c golbal-btn" v-for="(item,index) in iconList"
         @click="handleClickIcon(item)" :key="index">
      <Icon :custom="'iconfont ' + item.icon" :size="23"/>
      <div class="shadow-label mt-2">{{ item.label }}</div>
      <div class="line" v-if="iconList.length-1 !=index"></div>
    </div>

    <div class="shadow-item flex flex-a-c flex-j-c" v-if="showTop">
      <div class="font-info-center cu-center" @click="scrollToTop">
        <Icon type="ios-arrow-up" class="mb-1 cu-center" :size="23"/>
      </div>
    </div>
  </div>
</template>

<script>

import { getReadCount } from '@/api/message'
import * as animationData from '@/utils/message.json'

export default {
  name: 'fixed-index',
  props: {
    showTop: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      iconList: [
        {
          icon: 'icon-qianbao',
          label: '我的钱包',
          path: '/MyWallet'
        },
        {
          icon: 'icon-geren',
          label: '个人信息',
          path: '/UserInfo'
        },
        {
          icon: 'icon-dingdan',
          label: '我的订单',
          path: '/MyOrder'
        },
        {
          icon: 'icon-shoucang',
          label: '我的收藏',
          path: '/MyCollect'
        },
        {
          icon: 'icon-dianpu',
          label: '关注店铺',
          path: '/Attention'
        },
        {
          icon: 'icon-zuji',
          label: '足迹',
          path: '/History'
        },
        {
          icon: 'icon-youhuiquan1',
          label: '优惠券',
          path: '/Coupon'
        },
        {
          icon: 'icon-kefu',
          label: '官方客服',
          path: 'kefu'
        },
      ],
      isScrolling: false,
      timeoutId: '',
      defaultOptions: animationData,
      msgCountInterval: '',
      msgCount: 0,
      anim: '',
    }
  },
  mounted() {
    const vm = this
    // 添加滚轮事件监听器
    window.addEventListener('wheel', function () {
      if (vm.isScrolling) {
        // 如果页面正在滚动，则取消定时器并设置 isScrolling 为 false
        window.cancelAnimationFrame(vm.timeoutId)
        vm.isScrolling = false
      }
    })
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
        }
      }
    },
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.IntervalMsgCount()
          }
        })
      }
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
    IntervalMsgCount() {
      this.msgCountInterval = setInterval(() => {
        if (!this.$store.getters.token) {
          if (this.msgCountInterval) {
            clearInterval(this.msgCountInterval)
            this.msgCountInterval = null
          }
        } else {
          this.getAllReadCount()
        }
      }, 5000)
    },
    getAllReadCount() {
      getReadCount()
          .then(res => {
            this.msgCount = res.data
          })
    },
    scrollToTop() {
      const c = document.documentElement.scrollTop || document.body.scrollTop
      if (c > 0) {
        this.timeoutId = window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, c - c / 4)
        this.isScrolling = true
      } else {
        this.isScrolling = false
      }
    },
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    handleClickIcon(val) {
      if (val.path === 'kefu') {
        this.showCusService()
      } else {
        this.$router.push(val.path)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.shadow-box {
  padding: 1px;
  width: 56px;
  border-radius: 10px;
  opacity: 1;
  box-shadow: 0px 1px 10px 0px rgba(154, 154, 154, 0.5);
}

.shadow-label {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0px;
}

.icon {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.shadow-item {
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 5px;

  :deep(.ant-badge .ant-badge-count) {
    top: 5px !important;
    right: 10px !important;
  }

  > .line {
    width: 34px;
    height: 1px;
    opacity: 1;
    margin-top: 5px;
    border: 0.7px solid #EAEAEA;
  }
}
</style>

<template>
  <div>
    <div>
      <a-upload :action="uploadUrl"
                list-type="picture-card"
                :accept="acceptStr"
                :name="'multipart'"
                :maxCount="limit"
                v-model:file-list="fileImages"
                multiple
                @remove="removes"
                @change="handleChange"
                @beforeUpload="beforeUpload"
                @preview="handlePreview">
        <div v-if="type === 1">
          <icon-image/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
        <div v-if="type === 2">
          <icon-video-camera/>
          <div class="ant-upload-text">
            上传视频
          </div>
        </div>
        <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel" v-if="type === 1">
          <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
      </a-upload>
    </div>
    <div>
      <div v-if="type === 2 && uploads.length > 0">
        <a-button type="link" @click="viewVideo">查看视频</a-button>
        <a-modal :open="previewVideoVisible" :title="previewVideoTitle" :footer="null" @cancel="handleVideoCancel"
                 :width="'700px'"
                 :bodyStyle="{height:'540px'}">
          <vue3-video-play ref="videoPlayer" v-bind="playerOptions" :poster="uploads[0]"/>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/config/index.js'

export default {
  data() {
    return {
      fileImages:[],
      uploadUrl: '',
      acceptStr: 'image/*',
      uploads: [],
      limit: 1,
      previewVisible: false,
      previewTitle: '',
      previewVideoVisible: false,
      previewVideoTitle: '',
      previewImage: '',
      playerOptions: {
        width: '654px', //播放器高度
        height: '502px', //播放器高度
        color: '#409eff', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: '', //视频源
        poster: '', //封面
      }
    }
  },
  watch: {
    previewVideoVisible(val) {
      if (!val) {
      }
    }
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    fileList:{
      type: Array,
      default: []
    },
  },
  created() {
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
    if (this.type === 1) {
      this.acceptStr = 'image/*'
      this.limit = 8
    } else {
      this.acceptStr = 'video/*'
      this.limit = 1
    }
    this.fileImages = this.fileList
    this.fileList.forEach(item => {
      this.uploads.push(item.url)
    })
  },
  methods: {
    viewVideo() {
      this.playerOptions.src = this.uploads[0]
      this.previewVideoVisible = true
      this.previewVideoTitle = '视频查看'
    },
    handleVideoCancel() {
      this.previewVideoVisible = false
      this.previewVideoTitle = ''
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    removes(e) {
      return new Promise((resolve, reject) => {
        let fileType = 2
        if (this.acceptStr.indexOf('image') > -1) {
          fileType = 1
        }
        let uploadsFile = []
        this.uploads.forEach(item => {
          if(e.response && e.response.data && item !== e.response.data.url){
            uploadsFile.push(item)
          }
          if (e.url && item !== e.url) {
            uploadsFile.push(item)
          }
        })
        this.uploads = uploadsFile
        if (this.uploads.length === 0) {
          this.$emit('updateLoadSuc', {
            type: 0,
            uploadImgs: uploadsFile
          })
        } else {
          this.$emit('updateLoadSuc', {
            type: fileType,
            uploadImgs: uploadsFile
          })
        }
        resolve(true)
      })
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    handleChange(info) {
      let fileType = 2
      if (this.acceptStr.indexOf('image') > -1) {
        fileType = 1
      }
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        if (fileType === 2) {
          this.uploads = []
        }
        this.uploads.push(response.data.url)
        this.$emit('updateLoadSuc', {
          type: fileType,
          uploadImgs: this.uploads
        })
      }

    }
  },
}
</script>

<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="620"
             @close="cancel"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">申请售后</div>
      </template>
      <div class="d-flex justify-between mt-10 mb-2">
        <div style="width: 48%" class="mb-5">
          <a-card :class="selected ===1 ? 'selected-card' :''" class="cursor-pointer" style="height: 107px;"
                  @click="selectFlag(1)">
            <span class="text-bold">仅退款</span>
            <span class="text-gray text-sm">（无需退货）</span>
            <div class="text-gray text-sm">
              未收到货，或与卖家协商同意不退货只退款
            </div>
          </a-card>
        </div>
        <div style="width: 48%;">
          <a-card :class="selected ===2 ? 'selected-card' :''" class="cursor-pointer" style="height: 107px;"
                  @click="selectFlag(2)">
            <span class="text-bold">退货退款</span>
            <span class="text-gray text-sm">（已收到货）</span>
            <div class="text-gray text-sm">
              已收到货，需要退还收到的货物
            </div>
          </a-card>
        </div>
      </div>
      <a-form
          :model="formData"
          name="basic"
          layout="vertical"
          autocomplete="off"
          @finish="onFinish">

        <a-form-item label="退货原因" name="index" :rules="[{ required: true, message: '请选择退货原因' }]">
          <a-select ref="select" placeholder="请选择退货原因" v-model:value="formData.index"
                    @change="selectHandleChange">
            <a-select-option :value="item" :label="index" v-for="(item,index) in RefundArray" :key="index"/>
          </a-select>
        </a-form-item>

        <a-form-item name="evaluate">
          <div class="bg-gray rounded">
            <a-textarea v-model:value="formData.evaluate"
                        placeholder="（说明100字以内，凭证最多4张）"
                        :rows="8"
                        :max-length="100"
                        :bordered="false"
            ></a-textarea>

            <div class="ml-2">
              <a-upload :action="uploadUrl"
                        list-type="picture-card"
                        :accept="acceptStr"
                        :name="'multipart'"
                        :maxCount="4"
                        multiple
                        @remove="removes"
                        @change="handleChange"
                        @beforeUpload="beforeUpload"
                        @preview="handlePreview">
                <div>
                  <icon-image/>
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
                <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage"/>
                </a-modal>
              </a-upload>
            </div>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="text-right">
            <a-button type="primary" html-type="submit">{{ $t('pwd.pwd_010') }}</a-button>
          </div>
        </a-form-item>
      </a-form>
      <template #footer/>
    </a-modal>
  </div>
</template>
<script>
import { ordeRefundReason } from '@/api/order'
import { BASE_URL } from '@/config/index.js'

export default {
  name: 'afterSales',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selected: 1,
      previewVisible: false,
      previewTitle: '',
      uploadUrl: '',
      previewImage: '',
      acceptStr: 'image/*',
      formData: {
        index: '',
        evaluate: '',
        pics: [],
        afterSalesType: 1,
      },
      RefundArray: []
    }
  },
  created() {
    let text =this.RefundArray && this.RefundArray.length> 0 ? this.RefundArray[0] : ''
    this.formData = {
      index: text,
      evaluate: '',
      pics: [],
      afterSalesType: 1,
    }
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
    this.getRefundReason()
  },
  methods: {
    onFinish() {
      this.$emit('change', this.formData)
      this.selected = 1,
      this.formData = {
        index: '',
        evaluate: '',
        pics: [],
        afterSalesType: 1,
      }
      this.formData.index =this.RefundArray && this.RefundArray.length> 0 ? this.RefundArray[0] : ''
    },
    cancel() {
      this.$emit('change')
      this.selected = 1,
      this.formData = {
        index: '',
        evaluate: '',
        pics: [],
        afterSalesType: 1,
      }
      this.formData.index =this.RefundArray && this.RefundArray.length> 0 ? this.RefundArray[0] : ''
    },
    /**
     * 获取退款理由
     */
    getRefundReason: function () {
      ordeRefundReason()
          .then(res => {
            this.RefundArray = res.data
            this.formData.index = this.RefundArray[0]
          })
    },
    selectHandleChange(e) {
      this.formData.index = e
    },
    selectFlag(e) {
      this.selected = e
      this.formData.afterSalesType = e
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
    },
    removes(e) {
      return new Promise((resolve, reject) => {
        let uploadsFile = []
        this.formData.pics.forEach(item => {
          if (item !== e.response.data.url) {
            uploadsFile.push(item)
          }
        })
        this.formData.pics = uploadsFile
        resolve(true)
      })
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    handleChange(info) {
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        this.formData.pics.push(response.data.url)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.selected-card {
  border: 1px solid #2b85e4;
}
</style>

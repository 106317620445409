<template>
  <div>
    <div class="container width_1200">
      <a-card :body-style="{padding: '20px'}">
        <div class='text-xl text-bold'>{{articleInfo.title}}</div>
        <div class='d-flex mt-4 text-gray cu-center justify-start'>
          <div class='mr-3'>{{articleInfo.author}}</div>
          <div class='mr-2'>{{articleInfo.createTime}}</div>
          <div><Icon type="ios-eye-outline" :size="24"/>{{articleInfo.visit}}</div>
        </div>
        <div class="mt-5">
          <div v-html="content"></div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import {
  getArticleDetails
} from '@/api/api.js';
import {
  getProductDetail
} from '@/api/product.js';

export default {
  data() {
    return {
      id: 0,
      articleInfo: [],
      store_info: {},
      content: '',
      shareInfoStatus: false,
      productId: 0,
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getArticleOne()
  },
  methods: {
    getArticleOne() {
      let that = this;
      getArticleDetails(that.id).then(res => {
        that.articleInfo = res.data
        that.productId = res.data.productId
        if (res.data.productId) {
          that.goodInfo(res.data.productId);
        }
        that.content = res.data.content;
      });
    },
    goodInfo(id) {
      getProductDetail(id).then(res => {
        this.store_info = res.data.storeInfo ? res.data.storeInfo : {}
      })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;

  .item-font {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
  }
}

</style>

<template>
  <div class="wrapper">
    <div class="mb-8 box">
      <a-card :bordered="false" class="box-card">
        <a-steps :current="current" disabled>
          <a-step>
            <template #icon>
              <Icon custom="iconfont icon-gerenzhongxindingdandaifukuan" class="text-bold" :size="32"/>
            </template>
          </a-step>
          <a-step>
            <template #icon>
              <Icon custom="iconfont icon-daifahuo" class="text-bold" :size="32"/>
            </template>
          </a-step>
          <a-step>
            <template #icon>
              <Icon custom="iconfont icon-gerenzhongxindingdandaishouhuo" class="text-bold" :size="32"/>
            </template>
          </a-step>
          <a-step>
            <template #icon>
              <Icon custom="iconfont icon-yishouhuo" class="text-bold" :size="32"/>
            </template>
          </a-step>
        </a-steps>
      </a-card>
    </div>

    <div class="mb-8 box">
      <a-card :bordered="false" :body-style="{padding: '0'}" :loading="loading">
        <a-row :gutter="24">
          <a-col :span="10" class="bg-gray pa-6">
            <div class="order-title mb-5 ml-8">订单信息</div>
            <div class="order-info ml-8">
              <div class="mb-1">收货人: <span class="text-gray">{{ merchantOrder.realName }}</span></div>
              <div class="mb-1">地址: <span class="text-gray">{{ merchantOrder.userAddress }}</span></div>
              <div class="mb-1">联系电话: <span class="text-gray"> {{
                  '+' + merchantOrder.userCountryCode + '    ' + merchantOrder.userPhone
                }}</span></div>
              <div class="mb-1">订单编号: <span class="text-gray"> {{ order_pay_info.orderNo }}</span></div>
              <div class="mb-5">下单时间: <span class="text-gray">{{ order_pay_info.createTime }}</span></div>
            </div>
          </a-col>
          <a-col :span="14">
            <div class="pa-15" v-if="order_pay_info.status === 0">
              <div class="d-flex">
                <Icon class="text-gray mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                <div class="status">订单状态: 待付款</div>
              </div>
              <div class="d-flex">
                <div class="mt-5 cursor-pointer cu-center queren-btn-blue"
                     @click="() => {$router.push({path:'/Payment', query:{orderNo: order_pay_info.orderNo, payPrice: order_pay_info.payPrice}})}">
                  立即付款
                </div>

                <div class="ml-3 mt-5 cursor-pointer cu-center queren-btn"
                     @click="handleCancelOrder">
                  取消订单
                </div>
              </div>
            </div>

            <div class="pa-15" v-if="order_pay_info.status === 1">
              <div class="d-flex">
                <Icon class="text-gray mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                <div class="status">订单状态: 待发货</div>
              </div>
            </div>

            <div class="pa-15" v-if="order_pay_info.status === 4">
              <div class="d-flex">
                <Icon class="text-gray mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                <div class="status">订单状态: 待收货</div>
              </div>
              <div v-if="InvoiceList">
                <div class="mt-3 text-gray">物流: {{ InvoiceList.expressName }}</div>
                <div class="mt-1 text-gray">运单号: {{ InvoiceList.trackingNumber }}</div>
              </div>
              <div v-else>
                <div class="mt-3 text-gray">虚拟物品</div>
              </div>
              <div class="d-flex">
                <div class="mt-5 cursor-pointer cu-center queren-btn-blue"
                     @click="received">
                  确认收货
                </div>
              </div>
            </div>

            <div v-if="order_pay_info.status === 5">
              <div class="pa-15">
                <div class="d-flex">
                  <Icon class="text-green mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                  <div class="status">订单状态: 已收货</div>
                </div>
                <div v-if="InvoiceList">
                  <div class="mt-3 text-gray">物流: {{ InvoiceList.expressName }}</div>
                  <div class="mt-1 text-gray">运单号: {{ InvoiceList.trackingNumber }}</div>
                </div>
                <div v-else>
                  <div class="mt-3 text-gray">虚拟物品</div>
                </div>
              </div>
            </div>

            <div v-if="order_pay_info.status === 6">
              <div class="pa-15">
                <div class="d-flex">
                  <Icon class="text-green mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                  <div class="status">订单状态: 已完成</div>
                </div>

                <div v-if="InvoiceList">
                  <div class="mt-3 text-gray">物流: {{ InvoiceList.expressName }}</div>
                  <div class="mt-1 text-gray">运单号: {{ InvoiceList.trackingNumber }}</div>
                </div>
                <div v-else>
                  <div class="mt-3 text-gray">虚拟物品</div>
                </div>

                <div class="d-flex">
                  <div class="ml-1 mt-5 cursor-pointer cu-center queren-btn" @click="delOrder">
                    删除订单
                  </div>
                </div>
              </div>
            </div>

            <div class="pa-15" v-if="order_pay_info.status === 9">
              <div class="d-flex">
                <Icon class="text-gray mr-1 cu-center" type="ios-checkmark-circle" :size="24"/>
                <div class="status">订单状态: 已取消</div>
              </div>
              <div class="d-flex">
                <div class="ml-1 mt-5 cursor-pointer cu-center queren-btn" @click="delOrder">
                  删除订单
                </div>
              </div>
            </div>

          </a-col>
        </a-row>
      </a-card>
    </div>

    <div class="mb-10 box">
      <a-card :bordered="false" :body-style="{padding: '20px'}" :loading="loading">
        <a-row :gutter="[16,24]">
          <a-col :span="8">
            <div class="d-flex align-content-start flex-wrap mt-2 cursor-pointer mb-2"
                 @click="()=>{$router.push({path:'/MerHome', query:{merId: merchantOrder.merId}})}">
              <div>
                <Icon class="mr-1 text-gray" :size="20" custom="iconfont icon-shangdian"/>
              </div>
              <div class="mr-1" style="font-size: 15px;">{{ merchantOrder.merName }}</div>
              <div class="mr-1 text-gray">
                <Icon class="mr-1 text-gray" :size="20" type="ios-arrow-forward"/>
              </div>
            </div>
            <div class="mt-2 ml-1 d-flex justify-start">
              <div class="rounded">
                <a-image :src="orderInfo.image" :width="80" :height="80"/>
              </div>
              <div class="ml-2 pro-name line3">{{ orderInfo.productName }}</div>
            </div>
          </a-col>
          <a-col :span="2" class="cu-center">
            <div class="text-grey" v-if="orderInfo.sku">{{ orderInfo.sku }}</div>
          </a-col>
          <a-col :span="5" class="cu-center">
            <div class="text-red">{{ orderInfo.price || orderInfo.productPrice }} USDT</div>
          </a-col>
          <a-col :span="5" class="cu-center">
            <div>x {{ orderInfo.payNum }}</div>
          </a-col>
          <a-col class="text-right d-flex align-center justify-end" :span="4">
            <div>{{ orderInfo.price || orderInfo.productPrice }} USDT</div>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <div class="mb-8">
          <div class="text-right">
            <div>合计: <span class="money text-red">{{ order_pay_info.proTotalPrice }} USDT</span></div>
          </div>
        </div>
        <div class="mb-8">
          <div class="text-right ml-1 mt-5 cursor-pointer cu-center queren-btn" @click="toCustomer"
               style="float:right;">
            联系客服
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { getOrderDetail, orderCancel, orderDel, orderInvoiceListInfo, orderTake } from '@/api/order'
import { Modal } from 'ant-design-vue'

export default {
  name: 'OrderDetails',
  data() {
    return {
      orderNo: this.$route.query.orderNo,
      merchantOrder: {},
      loading: false,
      current: 0,
      orderInfo: {},
      order_pay_info: {
        paid: '',
        orderNo: '',
        createTime: '',
        payPrice: '',
      },
      deliveryNum: '',
      num: 0,
      InvoiceList: '',
      dbMerId: 0,
      dbOrderNo: '',
    }
  },
  mounted() {
    if(!this.$route.query.orderNo){
      this.$router.push({path:'/MyOrder'})
    }
    this.getOrderPayInfo()
  },
  methods: {
    toCustomer() {
      this.dbMerId = this.orderInfo.merId
      this.dbOrderNo = this.orderNo
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: this.dbMerId,
          dbOrderNo: this.dbOrderNo
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    received() {
      Modal.confirm({
        title: '确认收货',
        content: '为保障权益，请收到货确认无误后，再确认收货',
        onOk: () => {
          orderTake(this.order_pay_info.orderNo)
              .then(res => {
                this.$router.replace({ name: 'MyOrder' })
              })
        }
      })
    },
    getOrderInvoiceListInfo() {
      orderInvoiceListInfo(this.order_pay_info.orderNo)
          .then(res => {
            let data = res.data
            this.deliveryNum = data.deliveryNum
            this.num = data.num
            this.InvoiceList = data.invoiceList[0]
            console.log(this.InvoiceList)
          })
    },
    handleCancelOrder() {
      Modal.confirm({
        title: '确认取消',
        onOk: () => {
          orderCancel(this.order_pay_info.orderNo)
              .then(res => {
                this.$router.replace({ name: 'MyOrder' })
              })
        }
      })
    },
    getOrderPayInfo: function () {
      let that = this
      this.loading = true
      getOrderDetail(that.orderNo)
          .then(res => {
            that.order_pay_info = res.data
            that.merchantOrder = that.order_pay_info.merchantOrderList[0]
            if (that.merchantOrder.deliveryType !== 'fictitious') {
              this.getOrderInvoiceListInfo()
            }
            this.orderInfo = this.merchantOrder.orderInfoList[0]
            that.current = that.order_pay_info.status
            if (that.current === 4) {
              that.current = 2
            }
            if (that.current === 5) {
              that.current = 3
            }
            if (that.current === 6) {
              that.current = 4
            }
            if (that.current === 9) {
              that.current = -1
            }
            this.loading = false
          })
    },
    delOrder() {
      Modal.confirm({
        title: '确认删除?',
        onOk: () => {
          orderDel(this.orderNo)
              .then(res => {
                this.$router.replace({ name: 'MyOrder' })
              })
        },
      })
    },
  }
}
</script>

<style scoped lang="scss">
.queren-btn-blue {
  width: 93px;
  height: 30px;
  border-radius: 50px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #2b85e4;


  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #2b85e4;
}

.queren-btn {
  width: 93px;
  height: 30px;
  border-radius: 50px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #C3C3C3;


  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #3D3D3D;
}

.money {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  font-family: Source Han Sans;
  font-weight: 700;
  font-size: 16px;
}

.order-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0em;
}

.order-info {
  font-size: 13px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0em;
  color: #515151;
}

:deep(.ant-steps .ant-steps-item-title::after) {
  top: 20px !important;
}

.wrapper {
  margin-bottom: 40px;
}

.box {
  overflow: hidden;
  width: 100%;
  border-radius: 12px;
  opacity: 1;

  .box-card {
    padding: 26px;
    height: 154px;
  }
}

</style>

export default {
  data() {
    return {
      disabled: false,
      text: '获取验证码',
      run: null
    }
  },
  methods: {
    sendCode() {
      if (this.disabled) return
      this.disabled = true
      let n = 60
      this.text = n + 's' + ' 后重新发送'
      this.run = setInterval(() => {
        n = n - 1
        if (n < 0) {
          clearInterval(this.run)
        }
        this.text = n + 's' + ' 后重新发送'
        if (this.text < 0 + 's' + ' 后重新发送') {
          this.disabled = false
          this.text = '重新获取'
        }
      }, 1000)
      return this.run
    }
  }
}

<template>
  <div class="page-container-all width_1200_auto">
    <a-image :preview="false" class="all-page-container" :src="require('@/assets/share/rank_back.png')"></a-image>
    <div class="page-join-content">
      <div style="width:100%; margin-bottom: 60px;">
        <a-row :gutter="[16, 24]">
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <div class="top-image-2">
              <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                       :width="156" :height="156"
                       :src="Two.avatar"
                       :fallback="require('@/assets/my/logo.png')"/>
              <div class="text-white mt-2 cu-center text-lg">{{ Two.nickname }}</div>
              <div class="top-money-2">{{ Two.brokeragePrice }} USDT</div>
            </div>
          </a-col>
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <a-image :preview="false" style="border-radius: 50%;border: 2px solid #fff;" :width="216" :height="216"
                     class="top-image border-image"
                     :src="One.avatar"
                     :fallback="require('@/assets/my/logo.png')"/>
            <div class="text-white mt-2 cu-center text-lg">{{ One.nickname }}</div>
            <div class="top-money-1">{{ One.brokeragePrice }} USDT</div>
          </a-col>
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <div class="top-image-2">
              <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                       :width="156" :height="156" :src="Three.avatar"
                       :fallback="require('@/assets/my/logo.png')"/>
              <div class="text-white mt-2 cu-center text-lg">{{ Three.nickname }}</div>
              <div class="top-money-2">{{ Three.brokeragePrice }} USDT</div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="bg-image">
        <div class="page-btns">
          <a-card :bodyStyle="{padding: '0'}">
            <a-table :pagination="false" :show-header="false" :columns="columns" :loading="loading"
                     :dataSource="listData">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'rownum'">
                  <div v-if="showMy" class="text-bold">
                    {{ record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3) }}
                  </div>
                  <div v-if="!showMy" class="text-bold">
                    {{ index + 4 }}
                  </div>
                </template>
                <template v-if="column.key === 'avatar'">
                  <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                           :width="48" :height="48"
                           :src="record.avatar"
                           :fallback="require('@/assets/my/logo.png')"/>
                </template>
                <template v-if="column.key === 'nickname'">
                  <div class="text-bold">{{ record.nickname }}</div>
                </template>
                <template v-if="column.key === 'brokeragePrice'">
                  <div class="text-bold text-red text-right">
                    {{ record.brokeragePrice <= 0 ? '0.00' : record.brokeragePrice }} USDT
                  </div>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { myRankData, rankDataList, } from '@/api/tg.js'

export default {
  data() {
    return {
      visible2: false,
      promotionInfo: {},
      bgColor: '#e93323',
      invitationCode: '',
      spreadDataInfo: {},
      showBotm: false,
      urlPre: '',
      loading: false,
      listData: [],
      myRank: {},
      showMy: false,
      columns: [
        {
          dataIndex: 'rownum',
          key: 'rownum'
        },
        {
          dataIndex: 'avatar',
          key: 'avatar'
        },
        {
          dataIndex: 'nickname',
          key: 'nickname'
        },
        {
          dataIndex: 'brokeragePrice',
          key: 'brokeragePrice'
        }
      ],
      One: {},
      Two: {},
      Three: {},
    }
  },
  created() {
    if (this.$store.getters.token) {
      this.getRanklist()
    } else {
      this.$router.push({
        path: '/',
        query: {
        }
      })
    }
  },
  methods: {
    getRanklist: function () {
      let that = this
      that.loading = true
      this.getMyRankData()
      this.listData = []
      setTimeout(() => {
        rankDataList()
            .then(res => {
              this.list = res.data
              if(this.list && this.list.length > 0){
                this.list.forEach(item => {
                  if(item.id === this.$store.getters.uid){
                    item.nickname = '我'
                  }
                })
              }
              that.One = this.list[0] || {}
              that.Two = this.list[1] || {}
              that.Three = this.list[2] || {}
              let listFilter = that.list.filter((v, i) => {
                return i > 2
              })
              if (that.myRank && that.myRank.rownum > 10) {
                that.showMy = true
                that.listData[0] = that.myRank
                listFilter.forEach((item, index) => {
                  that.listData.push(item)
                })
              } else {
                that.listData = listFilter
              }
              that.loading = false
            })
      }, 300)
    },
    getMyRankData: function () {
      myRankData()
          .then(res => {
            this.myRank = res.data
            this.myRank.nickname = '我'
          })
    },
  }
}
</script>

<style scoped lang="scss">
.border-image {
  border: 2px solid #fff !important;
}

.top-image-2 {
  margin-bottom: -250px;

  .top-money-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    height: 35px;
    transform: rotate(0deg);
    border-radius: 50px;
    background: rgba(22, 22, 22, 0.5);
    color: #fff;
  }
}

.top-money-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  width: 222px;
  height: 35px;
  transform: rotate(0deg);
  border-radius: 50px;
  background: rgba(22, 22, 22, 0.5);
  color: #fff;
}


.page-container-all {
  position: relative;
  //width: 100%;
  margin-top: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 1600px;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
  }

  .page-join-content {
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .bg-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 740px;
      height: 403px;
      background: url('@/assets/share/ran_tai.png') repeat-y;
      background-size: cover;
      box-sizing: border-box;
    }

    .page-btns {
      position: absolute;
      top: 280px;
      width: 1200px;
    }
  }
}
</style>

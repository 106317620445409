export default {
  token: state => state.app.token,
  isLogin: state => !!state.app.token,
  backgroundColor: state => state.app.backgroundColor,
  userInfo: state => state.app.userInfo || {},
  uid: state => state.app.uid,
  homeActive: state => state.app.homeActive,
  home: state => state.app.home,
  chatUrl: state => state.app.chatUrl,
  systemPlatform: state => state.app.systemPlatform,
  productType: state => state.app.productType || 'normal',
  globalData: state => state.app.globalData,
  merchantClassify: state => state.app.merchantClassify,
  merchantType: state => state.app.merchantType,
  merchantAPPInfo: state => state.app.merchantAPPInfo,
  merSttledData: state => state.app.merSttledData,
  bottomNavigationIsCustom: state => state.app.bottomNavigationIsCustom,
  merTokenIsExist: state => state.app.merTokenIsExist,
  discoverTopic: state => state.app.discoverTopic,
  language: state => state.app.language,
  dark: state => state.app.dark,
  chatmsgcount: (state) => state.app.chatmsgcount,
  readCount: (state) => state.app.readCount,
  visitorUserId: (state) => state.app.visitorUserId
}

module.exports = {
    //配置显示在浏览器标签的title、底部信息、部分信息展示的值
    title: '51担保',
    company: {
        href: "https://www.51danbao.com",
        app: "https://h5.51danbao.com",
        name: "51担保 保留所有权利",
    }, //公司信息
    aMapSwitch: false, //是否开启高德定位
    enableCDN: true, //生产环境 是否启用cdn加载 vue等js
    port: 10000, //端口
    inputMaxLength: '140', //全局输入框默认最大输入长度字
    //token
    LOGIN_STATUS: 'LOGIN_STATUS_TOKEN',
    // uid
    UID: 'UID',
    //用户信息
    USER_INFO: 'USER_INFO',
    //token 过期时间
    EXPIRES_TIME: 'EXPIRES_TIME',
    //wx授权
    WX_AUTH: 'WX_AUTH',
    //wx授权状态
    STATE_KEY: 'wx_authorize_state',
    //登录类型
    LOGINTYPE: 'loginType',
    //登录回调地址
    BACK_URL: 'login_back_url',
    // 小程序授权状态
    STATE_R_KEY: 'roution_authorize_state',
    //logo Url
    LOGO_URL: 'LOGO_URL',
    //模板缓存
    // SUBSCRIBE_MESSAGE: 'SUBSCRIBE_MESSAGE',
    TIPS_KEY: 'TIPS_KEY',
    SPREAD: 'SPREAD',
    //缓存经度
    CACHE_LONGITUDE: 'LONGITUDE',
    //缓存纬度
    CACHE_LATITUDE: 'LATITUDE',
    //app手机信息
    PLATFORM: 'systemPlatform',
    GLOBAL_DATA: 'globalData',
    BASE_URL: process.env.VUE_APP_BASE_API,
    VUE_FRONT_WSS_API: process.env.VUE_APP_FRONT_WSS_API,
    VUE_ADMIN_WSS_API: process.env.VUE_APP_ADMIN_WSS_API
};

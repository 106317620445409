import request from '@/utils/api_req.js'

export function inforList(params) {
  return request.get('infor/lists', params, { noAuth: true }, params)
}

export function saveInfor(data) {
  return request.post('infor/saveInfor', data)
}

export function updateInfor(data) {
  return request.post('infor/updateInfor', data)
}

export function delOnce(data) {
  return request.post('infor/delOnce', data)
}

export function numCountPlus(data) {
  return request.post('infor/numCountPlus', data)
}

export function infoById(infoId) {
  return request.get('infor/info/' + infoId, {}, { noAuth: true }, {})
}

export function infoInById(infoId) {
  return request.get('infor/infoIn/' + infoId)
}

export function infoRemark(data) {
  return request.post('infor/remark', data)
}

export function inforemarkZan(data) {
  return request.post('infor/remarkZan', data)
}

export function infoRemarkList(data) {
  return request.get('infor/remarkList', data, { noAuth: true }, data)
}

export function remarkZanSecond(data) {
  return request.get('infor/remarkZanSecond', data)
}

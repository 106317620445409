<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="460"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">TRX兑换</div>
      </template>
      <a-spin :spinning="loading">
        <div class="padding-top-xs">
          <div>• 当前汇率：1 USDT = <span class="text-blue text-bold">{{ trxInfo.rate }}</span> TRX</div>
          <div>• 可兑余额：$ <span class="text-blue text-bold">{{ trxInfo.balanceUsdt }} </span> USDT</div>
          <div>• 服务费：<span class="text-blue text-bold">1%-2% </span>（按照欧意实时买价，全行业最低）</div>
          <div class="margin-top-xs cu-center text-bold">USDT-TRC20地址</div>
          <div class="cu-center">
            <a-qrcode :size="240" :value="trxInfo.address" :bordered="false"/>
          </div>
          <div class="text-blue text-bold text-center cursor-pointer"  @click="$Copy({text: trxInfo.address})">{{ trxInfo.address }} <span class="margin-left-xs cursor-pointer" @click="$Copy({text: trxInfo.address})"><CopyOutlined/></span></div>
          <div class="margin-top-xs">地址细分：前6位 <span class="text-blue text-bold">TRrpsB</span> | 中间6位 <span class="text-blue text-bold">W4HSGZ</span> | 后6位 <span class="text-blue text-bold">155151</span></div>
          <div class="margin-top-xs">兑换说明：</div>
          <div class="margin-top-xs text-gray">1.自动兑换：转账后，系统会自动处理并返还TRX。单笔兑换需不低于<span class="text-blue text-bold"> 20 </span>USDT，总额不超过可兑余额。</div>
          <div class="margin-top-xs text-gray">2.账户提醒：请勿使用交易所账户进行转账，以防因地址特性导致资金丢失，平台概不负责。</div>
          <div class="margin-top-xs text-gray">3.到账时间：交易确认后，约两分钟内到账。</div>
          <div class="margin-top-xs cursor-pointer text-blue" @click="toCus">
            ❥遇到问题:[联系客服]
          </div>
        </div>
      </a-spin>
      <template #footer>
      </template>
    </a-modal>
  </div>
</template>


<script>
import { exchangeTrx } from '@/api/api'
import { CopyOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    CopyOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.exchangeTrxQuery()
        }
      }
    }
  },
  data() {
    return {
      trxInfo: {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      },
      loading: false,
    }
  },
  methods: {
    toCus() {
      window.open('https://t.me/dbw51a', '_blank')
    },
    exchangeTrxQuery() {
      this.loading = true
      exchangeTrx()
          .then(res => {
            this.trxInfo = res.data
            this.loading = false
          })
          .catch(err => {
            this.$Message.error(err)
          })
    },
    cancel() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
    onFinish() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
  }
}
</script>
<style scoped lang="scss">

</style>

import store from "@/store";
import router from "@/router";
import service from "@/utils/request";

const request = {};

['options', 'get', 'post', 'put', 'head', 'delete', 'trace', 'connect'].forEach((method) => {
    request[method] = (api, data, opt, params) => baseRequest(api, method, data, opt || {}, params)
});

/**
 * 发送请求
 */
function baseRequest(url, method, data, {
    noAuth = false,
    noVerify = false
}, params) {
    if (!noAuth) {
        //登录过期自动登录
        if (!store.state.app.token) {
            router.push({path: '/login'})
            return Promise.reject({
                msg: '未登录'
            });
        }
    }
    if (params) {
        return service({
            url: url,
            method: method,
            params
        });
    }
    return service({
        url: url,
        method: method,
        data
    });
}

export default request;

const menusAll =
  [
    {
      title: '个人中心',
      path: 'Profile'
    },
    {
      title: '我的钱包',
      path: 'MyWallet'
    },
    {
      title: '我的订单',
      path: 'MyOrder'
    },
    {
      title: '我的账户',
      path: 'AccountSafe',
      menus: [
        {
          title: '个人资料',
          path: 'UserInfo'
        },
        {
          title: '地址管理',
          path: 'MyAddress'
        },
        {
          title: '账户设置',
          path: 'AccountSafe'
        },
        {
          title: 'USDT地址薄',
          path: 'WalletList'
        }
      ],
    },
    {
      title: '消息中心',
      path: 'Message'
    },
    {
      title: '我的优惠券',
      path: 'MyCoupon'
    },
    {
      title: '我的供需',
      path: 'MySupply'
    },
    {
      title: '我的收藏',
      path: 'MyCollect'
    },
    {
      title: '关注店铺',
      path: 'Attention'
    },
    {
      title: '我的足迹',
      path: 'History'
    },
    // {
    //   title: '帮助中心',
    //   path: 'Help'
    // }
  ]

export default menusAll

<template>
  <div v-if="pageData">
    <Index :page-data="pageData" />
  </div>
</template>

<script>
import Index from './Index.vue'
export default {
  data() {
    return {
      pageData: ""
    }
  },
  components: { Index },
  mounted() {
    // 接收id进行查询
    const id = this.$route.query.id
    this.init(id)
  },
  methods: {
    async init(id) {
    }
  }
}
</script>

<style lang="scss" scoped></style>

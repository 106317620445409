<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="620"
             @close="cancel"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">添加评价</div>
      </template>
      <a-form
          :model="formData"
          name="basic"
          autocomplete="off"
          @finish="onFinish">
        <a-form-item label="商品质量" name="rateProduct" :rules="[{ required: true, message: '请设置商品质量评分' }]">
          <a-rate v-model:value="formData.rateProduct"></a-rate>
        </a-form-item>
        <a-form-item label="服务态度" name="rateService" :rules="[{ required: true, message: '请设置商品质量评分' }]">
          <a-rate v-model:value="formData.rateService"></a-rate>
        </a-form-item>
        <a-form-item name="evaluate" :rules="[{ required: true, message: '请输入您宝贵的评价' }]">
          <div class="bg-gray rounded">
            <a-textarea v-model:value="formData.evaluate"
                        placeholder="商品满足你的期待么? 说说你的想法, 分享给想买的他们吧!"
                        :rows="8"
                        :bordered="false"
            ></a-textarea>

            <div class="ml-2">
              <a-upload :action="uploadUrl"
                        list-type="picture-card"
                        :accept="acceptStr"
                        :name="'multipart'"
                        :maxCount="6"
                        multiple
                        @remove="removes"
                        @change="handleChange"
                        @beforeUpload="beforeUpload"
                        @preview="handlePreview">
                <div>
                  <icon-image/>
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
                <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage"/>
                </a-modal>
              </a-upload>
            </div>
          </div>
        </a-form-item>
        <a-form-item>
          <div class="text-right">
            <a-button type="primary" html-type="submit">添加评价</a-button>
          </div>
        </a-form-item>
      </a-form>
      <template #footer/>
    </a-modal>
  </div>
</template>
<script>
import { BASE_URL } from '@/config/index.js'

export default {
  name: 'Evaluate',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      previewVisible: false,
      previewTitle: '',
      uploadUrl: '',
      previewImage: '',
      acceptStr: 'image/*',
      formData: {
        rateProduct: '',
        rateService: '',
        evaluate: '',
        pics: []
      }
    }
  },
  created() {
    this.formData = {
      rateProduct: '',
      rateService: '',
      evaluate: '',
      pics: []
    }
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
  },
  methods: {
    onFinish() {
      this.$emit('change', this.formData)
      this.formData = {
        rateProduct: '',
        rateService: '',
        evaluate: '',
        pics: []
      }
    },
    cancel() {
      this.$emit('change')
      this.formData = {
        rateProduct: '',
        rateService: '',
        evaluate: '',
        pics: []
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
    },
    removes(e) {
      return new Promise((resolve, reject) => {
        let uploadsFile = []
        this.formData.pics.forEach(item => {
          if (item !== e.response.data.url) {
            uploadsFile.push(item)
          }
        })
        this.formData.pics = uploadsFile
        resolve(true)
      })
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    handleChange(info) {
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        this.formData.pics.push(response.data.url)
      }
    }
  },
}
</script>

<style scoped lang="scss">

textarea{
  resize:none
}
</style>

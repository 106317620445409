<template>
  <div>
    <div class="container width_1200">
      <div class="mb-4 ml-1">
        <div class="d-flex">
          <span class="text-gray mr-1">您的当前位置: </span>
          <a-breadcrumb separator=">">
            <a-breadcrumb-item>
              <router-link :to="'/'">
                首页
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item v-if="link === 'OrderDetails'">
              <router-link :to="'/MyOrder'">
                我的订单
              </router-link>
            </a-breadcrumb-item>

            <a-breadcrumb-item v-if="link === 'MyAdd'">
              <router-link :to="'/MySupply'">
                我的供需
              </router-link>
            </a-breadcrumb-item>

            <a-breadcrumb-item>
              {{ getLink(link) }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>

      <a-layout class="layoutAll">
        <a-layout-sider class="side-bar" ref="side" :collapsed-width="78" theme="light">
          <div class="user-icon">
            <div class="user-img">
              <a-avatar
                  :src="userInfo.avatar"
                  style="width: 100%; height: 100%"
                  alt>
                <template #icon>
                  <a-image :src="require('@/assets/images/default.png')" :preview="false" />
                </template>
              </a-avatar>
            </div>
            <p>{{ userInfo.nickname }}</p>
          </div>
          <!-- 列表 -->
          <a-menu class="side-menu"
                  mode="inline"
                  v-model:openKeys="openKeys"
                  v-model:selectedKeys="selectedKeys"
                  @select="onSelect">
            <div v-for="(item, index) in menuList" :key="index">
              <a-menu-item v-if="!item.menus" :key="item.path">
                <div v-if="item.path === 'Message'" class="item-font">
                  <a-badge :dot="msgCount">
                    {{ item.title }}
                  </a-badge>
                </div>
                <div v-else class="item-font">{{ item.title }}</div>
              </a-menu-item>
              <a-sub-menu v-else :title="item.title" :key="item.path">
                <template #title>
                  <div class="item-font">{{ item.title }}</div>
                </template>
                <template #icon></template>
                <div v-for="(items, indexs) in item.menus" :key="indexs">
                  <a-menu-item :key="items.path">
                    <div class="item-font">{{ items.title }}</div>
                  </a-menu-item>
                </div>
              </a-sub-menu>
            </div>
          </a-menu>
        </a-layout-sider>

        <a-layout class="layout ml_10">
          <a-layout-content class="content">
            <transition mode="out-in">
              <router-view :key="$route.query.t"/>
            </transition>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>

<script>
import menuList from './menu'
import { Divider } from 'view-ui-plus'
import { getReadCount } from '@/api/message'
import MyAdd from '@/views/supply/myAdd.vue'

export default {
  name: 'Home',
  components: { Divider },
  data() {
    return {
      link: 'Profile',
      openLink: '',
      menuList, // 会员中心左侧列表
      userInfo: {},
      darkStatus: '',
      selectedKeys: ['Profile'],
      openKeys: [],
      msgCountInterval: '',
      msgCount: false
    }
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
        }
      }
    },
    '$store.getters.userInfo': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.userInfo = newVal
        })
      }
    },
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (this.$route.name === 'UserHome') {
            this.$router.push({
              name: 'Profile',
              query: {}
            })
          }
          if (this.$route.name === 'OrderDetails') {
            this.link = 'OrderDetails'
            this.selectedKeys[0] = 'MyOrder'
          } else if (this.$route.name === 'MyAdd') {
            this.link = 'MyAdd'
            this.selectedKeys[0] = 'MySupply'
          } else {
            this.link = this.$route.name
            this.selectedKeys[0] = this.link
          }
        })
      }
    },
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
            if (this.msgCountInterval) {
              clearInterval(this.msgCountInterval)
              this.msgCountInterval = null
            }
          } else {
            if (this.msgCountInterval) {
              clearInterval(this.msgCountInterval)
              this.msgCountInterval = null
            }
            this.IntervalMsgCount()
          }
        })
      }
    }
  },
  created() {
    if (this.$route.name === 'UserHome') {
      this.link = 'Profile'
      this.selectedKeys[0] = this.link
      this.$router.push({
        name: 'Profile',
        query: {}
      })
    } else {
      this.link = this.$route.name
      this.selectedKeys[0] = this.link
      if (this.link === 'UserInfo' || this.link === 'MyAddress' || this.link === 'AccountSafe' || this.link === 'WalletList') {
        this.openLink = 'AccountSafe'
        this.openKeys[0] = this.openLink
      }
      this.$router.push({
        name: this.$route.name,
        query: {}
      })
    }
    // 用户信息
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
      this.IntervalMsgCount()
    }
  },
  methods: {
    IntervalMsgCount() {
      this.getAllReadCount()
    },
    getAllReadCount() {
      getReadCount()
          .then(res => {
            if (res.data > 0) {
              this.msgCount = true
            } else {
              this.msgCount = false
            }
          })
    },
    getLink(name) {
      const statusMap = {
        'Profile': '个人中心',
        'MyOrder': '我的订单',
        'UserInfo': '个人资料',
        'MyAddress': '地址管理',
        'AccountSafe': '账户设置',
        'WalletList': 'USDT地址薄',
        'MyWallet': '我的钱包',
        'OrderDetails': '订单详情',
        'MyAdd': '编辑供需',
        'Message': '消息中心',
        'MySupply': '我的供需',
        'MyCoupon': '我的优惠券',
        'MyCollect': '我的收藏',
        'Attention': '关注店铺',
        'History': '我的足迹',
        'Help': '帮助中心'
      }
      return statusMap[name]
    },
    // 每次点击左侧bar的callback
    onSelect(name) {
      this.link = name.key
      this.$router.push({
        name: name.key,
        query: {}
      })
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.ant-menu-submenu-title) {
  padding-left: 34px !important;
}

.item-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
  padding-left: 10px;
}

.ivu-layout {
  background: inherit !important;
}

.content {
  border-radius: 12px;
}

.header {
}

.side-menu,
.side-bar,
.content {
  border-radius: 12px;
}

.side-bar {
  min-height: 600px;
  height: auto;
  border-radius: 12px;
}

.layoutAll {
  min-height: 1200px;
  border-radius: 12px;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
}

.side-bar a {
}

.user-icon {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.user-img {
  margin-bottom: 15px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  overflow: hidden;
}

.layout-footer-center {
  padding: 0px 15px;

  padding-bottom: 15px;
  text-align: center;
}
</style>

<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Enter">
          商家入驻
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        确认入驻申请
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="page-container">
      <a-card :body-style="{padding :'30px'}" title="入驻信息">
        <div class="flex">
          <div class="content-title">商户名:</div>
          <div class="content-info">{{ merchantData.name }}</div>
        </div>
        <div class="flex">
          <div class="content-title">登录用户名:</div>
          <div class="content-info">{{ merchantData.realName }}</div>
        </div>
        <div class="flex">
          <div class="content-title">电报:</div>
          <div class="content-info">{{ merchantData.phone }}</div>
        </div>
        <div class="flex">
          <div class="content-title">{{ $t('pwd.pwd_003') }}:</div>
          <div class="content-info">{{ merchantData.email }}</div>
        </div>
        <div class="flex">
          <div class="content-title">商户分类:</div>
          <div class="content-info">{{ merchantCategoryName }}</div>
        </div>
        <div class="flex">
          <div class="content-title">交易手续费:</div>
          <div class="content-info">{{ merchantData.transactionFee }} %</div>
        </div>
        <div class="flex">
          <div class="content-title">担保金:</div>
          <div class="content-info">{{ merchantData.guaranteeFund }} USDT</div>
        </div>
      </a-card>

      <a-card :body-style="{padding :'30px'}" class="mt-6" title="价格明细">
        <div class="flex">
          <div class="content-title">店铺费:</div>
          <div class="content-info">{{ merchantData.handlingFee + ' USDT' }}</div>
        </div>
        <div class="flex">
          <div class="content-title">入驻优惠券:</div>
          <div class="content-info cursor-pointer" @click="showPop">
            <a-image :src="require('@/assets/join/line.png')" :preview="false" width="10px" height="21px"/>
            <span class="text-red">{{ checkAmount > 0 ? (checkConp + checkAmount + ' USDT') : '请选择优惠券' }}</span>
            <a-image style="transform: rotateY(180deg);" :src="require('@/assets/join/line.png')" :preview="false"
                     width="10px" height="21px"/>
          </div>
          <Icon class="cursor-pointer" v-if="checkAmount > 0" @click="clear" type="ios-close-circle" :size="18"
                color="#F44336"/>
        </div>
        <div class="flex">
          <div class="content-title">合计:</div>
          <div class="content-info" style="color: #F44336;font-weight: 500;">{{ endPrice + ' USDT' }}</div>
        </div>
      </a-card>

      <div class="process-step-btm">
        <div class="btm-right cursor-pointer" @click="payMent">去支付</div>
      </div>
    </div>

    <Payment :visible="payVisible" v-if="payVisible" @complete="onComplete"/>
    <ShopTip :show-model="showModel" :tips="tipsOne" @close="showModel = false"></ShopTip>

    <a-modal v-model:open="xYVisible" title="选择优惠券"
             :width="520"
             :maskClosable="false"
             cancelText="取消"
             okText="确定">
      <div class="padding-xs">
        <div class="margin-xs" v-for="(item,index) in cartArr" :key="index">
          <div class="bg-img-cus">
            <div class="money-hour" v-if="item.showFlag === 1">
              {{ item.month }}
              <text class="money-dw">
                {{ item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : '' }}
              </text>
            </div>
            <div class="money" v-if="item.showFlag === 0">
              {{ item.money }}
              <text class="money-dw">USDT</text>
            </div>
            <div class="item">
              <div class="content">
                <div class="text-grey text-font-s">{{ item.name }}</div>
                <div class="line2 text-font-xs">{{ item.content }}
                  <div class="text-gray text-sm flex">
                    <div class="text-cut text-font-s-1">
                      {{ item.receiveEndTime }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="action cursor-pointer">
                <div class="text-cus-ss" v-if="!item.isUserReceive" @click="onlLqCon(item)">
                  领取
                </div>
                <div class="text-cus-ss" v-if="item.isUserReceive && !item.isUse"
                     @click="selectCop(item)">
                  使用
                </div>
                <div class="text-cus-ss" v-if="item.isUserReceive && item.isUse">
                  已使用
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>
<script>
import { createCoupon, getCouponJoinProList, merchantQuery, vipPay } from '@/api/order.js'
import { isSet } from '@/api/user'

import { mapGetters } from 'vuex'

import Payment from '@/components/payment/index.vue'
import ShopTip from '@/components/shopTip/index.vue'

export default {
  components: {
    Payment,
    ShopTip
  },
  computed: {
    ...mapGetters(['merchantClassify'])
  },
  data() {
    return {
      merchantData: {},
      merchantCategoryIndex: 0,
      merchantCategoryName: '',
      endPrice: 0,
      cartArr: '',
      xYVisible: false,
      checkAmount: 0,
      checkConp: '',
      couponId: '',
      payVisible: false,
      showModel: false,
      tipsOne: '您的余额不足,去充值?',
    }
  },
  created() {
    this.queryOrderById(this.$route.query.id)
  },
  methods: {
    clear() {
      this.checkAmount = 0
      this.endPrice = this.merchantData.handlingFee
      this.checkConp = '请选择优惠券'
      this.couponId = 0
    },
    onComplete(e) {
      this.payVisible = false
      if (e) {
        this.payClick(e)
      }
    },
    payClick(res) {
      this.orderNo = 'joinvip'
      vipPay({
        orderId: this.merchantData.id,
        orderNo: this.merchantData.orderNo,
        payType: 'yue',
        payChannel: 'yue',
        payPassword: res,
        couponId: this.couponId,
      })
          .then(res => {
            if (res.code === 200) {
              if (!res.data) {
                this.showModel = true
              } else {
                this.$router.push({
                  path: '/PaySuc',
                  query: {
                  }
                })
              }
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    payMent() {
      this.checkPayPwd()
    },
    checkPayPwd() {
      isSet()
          .then((res) => {
            if (!res.data) {
              this.payVisible = true
            } else {
              this.tipsOne = '未设置支付密码,前去设置?'
              this.showModel = true
            }
          })
    },
    selectCop(conp) {
      this.checkAmount = conp.money
      this.endPrice = (this.merchantData.handlingFee - conp.money) <= 0 ? 0 : (this.merchantData.handlingFee - conp.money)
      this.checkConp = conp.name
      this.couponId = conp.id
      this.xYVisible = false
    },
    onlLqCon(conp) {
      createCoupon(conp.id)
          .then((res) => {
            this.getSysCouponProList()
          })
    },
    showPop() {
      this.getSysCouponProList()
    },
    getSysCouponProList() {
      let data = {
        page: 1,
        limit: 100,
        category: 7
      }
      getCouponJoinProList(data)
          .then((res) => {
            this.cartArr = res.data.list
            if (!this.cartArr || this.cartArr.length <= 0) {
              this.$Message.error('优惠券已领完')
            } else {
              this.xYVisible = true
            }
          })
    },
    queryOrderById(id) {
      merchantQuery(id)
          .then((res) => {
            this.merchantData = res.data
            let index = this.merchantClassify.findIndex(item => this.merchantData.categoryId == item.id)
            this.merchantCategoryIndex = index
            if (this.merchantClassify[index]) {
              this.merchantCategoryName = this.merchantClassify[index].name
            }
            this.endPrice = this.merchantData.handlingFee
          })
    },
  }
}
</script>

<style scoped lang="scss">
.process-step-btm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  .btm-right {
    width: 279px;
    height: 48px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
}

.content-title {
  width: 121px;
  height: 35px;
  text-align: left;

  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #999999;
}

.page-container {
  margin-top: 20px;
}

.submit-btn {
  width: 273px;
  height: 46px;
  border-radius: 8px;
  background: #269FFA;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;
}


.bg-img-cus {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 100px;
  background-image: url('@/assets/coupon/list-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .item {
    position: relative;
    display: flex;
    margin-top: 20px;
    padding-right: 10px;
    height: 95px;
    justify-content: flex-end;

    align-items: center;

    .content {
      position: absolute;
      top: 10px;
      left: 125px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-around;

      color: #000;
      width: calc(100% - 96px - 120px);

      .text-font-s {
        font-size: 16px;
        color: #3D3D3D;
      }

      .text-font-xs {
        font-size: 10px;
        color: #999999;
        margin-top: 5px;
      }

      .text-font-xs-time {
        font-size: 11px;
        color: #FF8529;
        margin-top: 3px;
      }

      .text-font-s-1 {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
      }
    }

    .action {
      width: 100px;
      text-align: center;
      width: 72px;
      height: 28px;
      border-radius: 50px;
      background: #FF984A;
      box-shadow: 0px 3px 0px 0px rgba(255, 123, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .text-cus-ss {
        font-size: 14px;
        line-height: normal;
        color: #FFFFFF;
      }

      .text-cus-ss-1 {
        font-size: 14px;
        line-height: normal;
        color: #FFFFFF;
      }
    }
  }

  .money-hour {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 95px;

    font-size: 43px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .money {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 95px;

    font-size: 28px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FFFFFF;
    }
  }
}

</style>

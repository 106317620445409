import request from '@/utils/api_req.js'

/**
 * 获取消息列表
 *
 */
export function getMsgs(data) {
  return request.get('msg/msgs', data)
}

/**
 * 单个读
 *
 */
export function getReadOnce(data) {
  return request.post('msg/readOnce', data)
}

/**
 * 全部读
 *
 */
export function getReadAll() {
  return request.get('msg/readAll')
}

/**
 * 未读个数
 */
export function getReadCount() {
  return request.get('msg/readCount')
}

/**
 * 按类型未读个数
 */
export function getReadCountByType(data) {
  return request.post('msg/readCountByType', data)
}

/**
 * 按类型未读个数
 */
export function getReadMsgs() {
  return request.get('msg/msgs/new')
}

/**
 * 按类型未读个数
 */
export function getSearchMsgs(keyword) {
  return request.get('msg/msgs/search/' + keyword)
}

/**
 * 客服打开保存
 */
export function msgCusInfoSave(data) {
  return request.post('msg/cus/save', data, {
    noAuth: true
  })
}

/**
 * 客服打开保存
 */
export function msgCusTempSave(data) {
  return request.post('msg/cus/tempSave', data, {
    noAuth: true
  })
}

/**
 * 消息列表
 */
export function msgCusList(data) {
  return request.post('msg/cus/list', data, {
    noAuth: true
  })
}

/**
 * 客服打开保存
 */
export function cusInfoSave(data) {
  return request.post('msg/cus/saveInfo', data, {
    noAuth: true
  })
}

/**
 * 消息详情列表
 */
export function msgCusInfoList(data) {
  return request.get('msg/cus/msglist', data, {
    noAuth: true
  })
}

<template>
  <div class="wrapper">
    <div class="container">
      <!-- 商品筛选栏 -->
      <GoodsClassNav @getParams="getParams" :keywords="keywordsName"></GoodsClassNav>
      <!-- 商品展示容器 -->
      <div class="goods-box">
        <!-- 商品列表 -->
        <div class="goods-list-box">
          <!-- 排序 -->
          <a-card :body-style="{padding :'0'}" class="goods-list-tool" v-if="selectFlag === '0'">
            <ul>
              <li v-for="(item, index) in goodsTool"
                  :key="index"
                  @click="orderBy(item, index)">
                <span :class="{ 'goods-list-tool-active': index === sortIndex }">
                  {{ item.title }}
                  <Icon v-if="item.en && !item.sort" type="ios-arrow-down"/>
                  <Icon v-if="item.en && item.sort" type="ios-arrow-up"/>
                </span>
              </li>
            </ul>
          </a-card>
          <a-spin size="large" :spinning="loading">
            <!-- 列表 -->
            <div class="goods-list" v-if="selectFlag === '0'">
              <empty v-if="productList.length === 0 && !loading"/>
              <a-card :body-style="{padding: '0px'}" v-else class="goods-show-info" v-for="(item, index) in productList"
                      :key="index" :loading="loading">
                <div @click="goGoodsDetail(item.id)">
                  <div class="goods-show-img">
                    <img class="goods-show-img" width="220" height="220" :src="item.image"/>
                  </div>
                  <div class="goods-show-price">
                <span>
                  <span class="seckill-price text-danger">{{
                      item.price
                    }} USDT</span>
                </span>
                  </div>
                  <div class="goods-show-detail">
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="goods-show-num">
                    <span>{{ item.sales || 0 }}</span>人付款
                  </div>
                </div>
                <div class="goods-show-seller">
                  <div class="mer-class">
                    <div class="text-bottom">{{
                        item.merName
                      }}
                    </div>
                    <div class="join-mer cursor-pointer"
                         @click="() => {$router.push({path:'/MerHome', query:{ merId: item.merId}})}">进入商铺
                    </div>
                  </div>
                </div>
              </a-card>
            </div>

            <div class="goods-list" v-else>
              <empty v-if="merchantList.length === 0 && !loading"/>
              <a-row v-else :gutter="24" :class="merchantList.length === 1 ? 'with-one' : ''">
                <a-col class="mer-card" :span="12" v-for="(item, index) in merchantList" :key="index">
                  <a-card :loading="loading" :bordered="false" :bodyStyle="{padding: '10px'}">
                    <div class="mer-card-content">
                      <div class="mer-card-title cursor-pointer"
                           @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                        <div class="avatar">
                          <a-avatar :src="item.avatar" :size="52"></a-avatar>
                        </div>
                        <div>
                          <div class="mer-name">{{ item.name }}</div>
                          <!--                          <div class="mer-level">-->
                          <!--                            店铺等级:-->
                          <!--                            <a-rate v-model:value="item.starLevel" disabled>-->
                          <!--                              <template #character="{ index }">-->
                          <!--                                <a-image v-if="index < item.starLevel" :preview="false" :width="15" :height="12"-->
                          <!--                                         :src="require('@/assets/index/start.png')"/>-->
                          <!--                                <a-image v-else :preview="false" :width="15" :height="12"-->
                          <!--                                         :src="require('@/assets/index/start_v.png')"/>-->
                          <!--                              </template>-->
                          <!--                            </a-rate>-->
                          <!--                          </div>-->
                          <div class="custom-font">保证金：{{ item.earnestMoney }} USDT</div>
                        </div>
                      </div>

                      <div class="mer-card-content sroll-product" id="nav">
                        <arco-scrollbar type="embed" style="height:120px;overflow: auto;">
                          <div class="flex">
                            <div v-for="(items, index) in item.proList" :key="index" class="cursor-pointer"
                                 @click="() => {$router.push({path:'/Product', query:{ id: items.id}})}">
                              <div class="imgs">
                                <a-image :preview="false" :src="items.image" :width="109" :height="106"
                                         style="border-radius: 10px;"
                                         :fallback="require('@/assets/index/logo.png')"/>
                                <div class="usdt-bottom">
                                  {{ items.price }}USDT
                                </div>
                              </div>
                            </div>
                          </div>
                        </arco-scrollbar>
                      </div>
                    </div>
                  </a-card>
                </a-col>
              </a-row>
            </div>
          </a-spin>
        </div>
      </div>
      <div class="goods-page">
        <a-pagination :page-size="where.limit" :total="total" show-less-items @change="changePageNum"
                      :show-size-changer="false"
                      @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsClassNav from '@/components/nav/GoodsClassNav'
import * as apiGoods from '@/api/product'
import * as apiMers from '@/api/merchant'

export default {
  name: 'GoodsList',
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0)
    next()
  },
  data() {
    return {
      sortIndex: 0, // 排序状态
      sortPriceIndex: false, // 判断价格升序还是降序
      goodsTool: [
        // 排序类型
        {
          title: '综合',
          en: '',
          sort: false
        },
        {
          title: '价格',
          en: 'priceOrder',
          sort: false
        },
        {
          title: '销量',
          en: 'salesOrder',
          sort: false
        },
      ],
      merchantList: [],
      productList: [], // 商品列表
      loading: false, // 加载状态
      total: 0, // 列表总数
      where: {
        keyword: '',
        keywords: '',
        priceOrder: '',
        salesOrder: '',
        //news: 0,
        page: 1,
        limit: 20,
        cid: '',
        merId: '',
        categoryId: '',
        selectFlag: '0',
      },
      selectFlag: '0',
      keywordsName: '',
    }
  },
  watch: {
    '$route': { // 监听路由
      handler(val, oVal) {
        if (val.name !== 'GoodsList') {
          return
        }
        const keyword = this.$route.query.keyword
        const selectFlag = this.$route.query.selectFlag
        if (!selectFlag) {
          this.selectFlag = '0'
        } else {
          this.selectFlag = selectFlag
        }
        if (keyword) {
          this.search({
            keyword: keyword,
            selectFlag: selectFlag
          })
          this.keywordsName = keyword
        }
        if (this.$route.query.categoryId) {
          let cateId = this.$route.query.categoryId.split(',')
          Object.assign(this.where, this.$route.query)
          this.where.categoryId = cateId[cateId.length - 1]
        }
        if (this.$route.query.promotionType) {
          this.where.promotionType = this.$route.query.promotionType
        }
        if (this.$route.query.promotionsId) {
          this.where.promotionsId = this.$route.query.promotionsId
        }
        this.getGoodsList()
      }
    },
  },
  created() {
    if (this.$route.query.categoryId) {
      let cateId = this.$route.query.categoryId.split(',')
      Object.assign(this.where, this.$route.query)
      this.where.categoryId = cateId[cateId.length - 1]
    } else {
      Object.assign(this.where, this.$route.query)
    }
    const keyword = this.$route.query.keyword
    if (keyword) {
      this.where.keyword = encodeURIComponent(keyword)
      this.keywordsName = keyword
    }
    const selectFlag = this.$route.query.selectFlag
    if (!selectFlag) {
      this.selectFlag = '0'
    } else {
      this.selectFlag = selectFlag
    }
    this.where.page = 1
    this.getGoodsList()
  },
  methods: {
    load({ done }) {
      done('empty')
    },
    // 搜索
    search(key) {
      this.keywordsName = key.keyword
      this.selectFlag = key.selectFlag
      this.where.keyword = encodeURIComponent(key.keyword)
      this.where.page = 1
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: key.keyword,
          selectFlag: key.selectFlag,
        },
      })
      // this.getGoodsList()
    },
    orderBy(data, index) {
      this.sortIndex = index
      if (data.sort) {
        data.sort = false
      } else {
        data.sort = true
      }
      if (data.en === 'priceOrder') {
        if (data.sort) {
          this.where.salesOrder = ''
          this.where.priceOrder = 'asc'
        } else {
          this.where.salesOrder = ''
          this.where.priceOrder = 'desc'
        }
      }
      if (data.en === 'salesOrder') {
        if (data.sort) {
          this.where.priceOrder = ''
          this.where.salesOrder = 'asc'
        } else {
          this.where.priceOrder = ''
          this.where.salesOrder = 'desc'
        }
      }
      this.getGoodsList()
    },
    goGoodsDetail(goodsId) {
      // 跳转商品详情
      this.$router.push({
        path: '/Product',
        query: {
          id: goodsId,
        }
      })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.where.page = val
      this.getGoodsList()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.where.page = 1
      this.where.limit = val
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList() {
      if (this.selectFlag === '0') {
        this.loading = true
        this.where.name = null
        this.productList = []
        this.merchantList = []
        apiGoods.productList(this.where)
            .then(res => {
              this.$nextTick(() => {
                this.productList = res.data.list
              })
              this.loading = false
              this.where.salesOrder = ''
              this.where.priceOrder = ''
              this.total = res.data.total
            })
      }
      if (this.selectFlag === '1') {
        if (this.loadend) return
        if (this.loading) return
        this.loading = true
        this.productList = []
        this.merchantList = []
        this.where.keywords = this.where.keyword
        apiMers.getMerSearchApi(this.where)
            .then(res => {
              this.merchantList = res.data.list
              this.loading = false
              this.total = res.data.total
            })
      }
    },
    getParams(key) {
      // 筛选条件回显
      this.where.page = 1
      Object.assign(this.where, key)
      this.$router.push({
        path: '/GoodsList',
        query: this.where,
      })
    },
  },
  components: {
    GoodsClassNav,
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/goodsList.scss";

.menus-item {
}

.with-one {
  width: 100%;
}

.goods-show-img {
  border-radius: 10px;
}

.mer-card {
  margin-bottom: 16px;
}

.custom-font {
  font-size: 13px;
  color: #FF6600;
}

.mer-card-title {
  height: 68px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .mer-level {
    font-size: 13px;
    color: #269FFA;
  }

  .mer-name {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .avatar {
    height: 66px;
    margin-right: 15px;
  }
}

.mer-card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 190px;

  .sroll-product {
    margin-top: 10px;

    .imgs {
      margin-right: 15px;
      position: relative;

      .usdt-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;

        font-size: 12px;
        font-weight: 900;
        line-height: 26px;
        color: #FFFFFF;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}


.cate-nav {
  margin-top: 10px;
}

.goods-show-info {
  //border-radius: 10px;
}

.goods-show-info > .goods-show-seller > .goods-show-buyer {
  width: 35px;
  height: 17px;
  white-space: nowrap;
  line-height: 17px;
  text-align: center;
  align-content: center;
  padding: 0 3px;
  background-color: #e23a3a;
}

.goods-show-tag {
  height: 18px;
  width: 32px;
  line-height: 14px;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  padding: 0 3px;
}

.goods-show-seller {
  // padding:3px 0;
  vertical-align: middle;
}

.container {
  margin: 0 auto;
  width: 1200px;
  min-height: 1200px;
  position: relative;
}

.price-sort:hover {
  color: #e23a3a;
}

.goods-box {
  display: flex;
}

/* ---------------侧边广告栏开始------------------- */

.goods-show-right {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}


/* ---------------商品栏开始------------------- */
.goods-list-box {
  position: relative;
  width: 100%;
  // margin-left: 15px;
  // width: calc(100% - 215px);
}

.goods-list-tool {
  width: 100%;
  height: 52px;
  //border: 1px solid #ccc;
  //background-color: #f1f1f1;
}

.goods-list-tool ul {
  padding-left: 15px;
  font-size: 12px;
  margin-top: 10px;

  &::after {
    content: "";
    display: block;
    clear: left;
  }

  .price-sort {
    span {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 22px;
      position: relative;
      line-height: 15px;
      top: -2px;
      left: 0;
    }

    span > div {
      display: inline-block;

      .ivu-icon {
        font-size: 12px;
        position: absolute;

        &:nth-child(1) {
          top: 1px;
        }

        &:nth-child(2) {
          top: 7px;
        }
      }

      .price-color {
        color: #b3b3b3;
      }
    }
  }
}

.goods-list-tool li {
  cursor: pointer;
  float: left;
}

.goods-list-tool span {
  padding: 3px 5px;
  //border: 1px solid #ccc;
  //margin-left: -1px;
  //background-color: #fff;

  margin-right: 10px;

  width: 66px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #5D5D5D;

  border: 1px solid #ccc;

  background: #F3F3F3;
}

.goods-list-tool span:hover {
  border-color: #2b85e4;
  position: relative;
  text-decoration: none;
  z-index: 1;
}

.goods-list-tool .ivu-icon {
  font-weight: bold;
  font-size: 16px;
}

.goods-list-tool-active {
  color: #fff !important;
  border-color: #269FFA !important;
  background-color: #269FFA !important;
}

/* ---------------商品栏结束------------------- */
</style>

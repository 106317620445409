<template>
  <div class="container width_1200_auto">
    <div class="mer-top-card">
      <MerHome :show-join="true" :merchant-info="merchantInfo" :isCollect="isCollect"
               :store="store"
               @followToggle="followToggle"></MerHome>

      <div class="margin-top-xs">
        <a-card title="店铺简介">
          <div class="intro">
            {{ store.intro }}
          </div>
          <a-divider></a-divider>
          <div>
            <div class="create-time">开店时间</div>
            <div class="create-time-info">{{ store.createTime }}</div>
          </div>
          <a-divider></a-divider>
          <div>
            <div class="create-time">店铺链接</div>
            <div class="create-time-info cursor-pointer" @click="$Copy({text: tagUrl})">
              {{ tagUrl }}
              <Icon type="md-copy" :size="20"/>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getMerCollectAddApi,
  getMerCollectCancelApi,
  getMerIndexInfoApi,
  getMerDetailApi,
} from '@/api/merchant.js'
import MerHome from '@/components/merTop/index.vue'

import { company } from '@/config'

export default {
  components: { MerHome },
  data() {
    return {
      merid: 0,
      merchantInfo: {},
      isCollect: false,
      store: {},
      score: '',
      tagUrl: company.href + '/MerHome?merId=',
    }
  },
  created() {
    this.merid = this.$route.query.merId
    this.tagUrl = this.tagUrl + this.merid
    this.getMerIndexInfo()
    this.getStore()
  },
  methods: {
    getStore() {
      getMerDetailApi(this.merid)
          .then(res => {
            this.store = res.data
          })
    },
    getMerIndexInfo() {
      getMerIndexInfoApi(this.merid)
          .then(res => {
            this.merchantInfo = res.data
            this.isCollect = this.merchantInfo.isCollect
            this.$store.commit('MERCHANTJINFO', res.data)
          })
    },
    // 设置是否关注
    followToggle() {
      if (this.merchantInfo.isCollect) {
        getMerCollectCancelApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      } else {
        getMerCollectAddApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      }
    },
  }
}
</script>
<style scoped lang="scss">
.create-time {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.create-time-info {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  color: #999999;
}

.intro {
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0em;
  color: #787878;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;

  .mer-top-card {
    width: 100%;
  }
}
</style>

// 创建组件
<template>
  <!--
    文档搜索列表展开/收起组件
    实现了文档搜索列表页 文档超过5行会显示展开/收起的功能
  -->
  <div class="mj-text-hide">
    <input :id="`group-members_exp${index}`" class="group-members_exp" type="checkbox"/>
    <div class="more-text" line-clamp="5" style="background: #fff;width: 100%;">
      <label style="margin-top: 0;" class="more-btn cur" :for="`group-members_exp${index}`">
        <span class="text"></span>
        <i class="el-icon-arrow-down"></i>
      </label>
      <div style="font-size: 13px;display: inline;" v-html="value.replace(/\n/g, '<br>')" @click="handlePreview($event,item)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MjTextHide',
  props: {
    value: { // 文字
      type: String,
      default: ''
    },
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    /**
     * 点击高亮词定位
     * @param e
     * @param item
     */
    handlePreview(e, item) {
      this.$emit('handlePreview', e, item)
    }
  }
}
</script>

<style scoped lang="scss">
.mj-text-hide {
  display: flex;
  width: 100%;

  [line-clamp="3"] {
    max-height: 100px;
  }

  .more-text {
    overflow: hidden;
    line-height: 1.5;
    max-height: 105px;
    position: relative;

    &::before {
      content: '';
      float: right;
      width: 0;
      height: calc(100% - 20px);
      /*100%减去一个按钮的高度即可*/
      background: #FFFFFF;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #FFFFFF;
    }

    .more-btn {
      float: right;
      clear: both;
      color: #c8c8c8;
      font-size: 13px;
      cursor: pointer;

      &::before {
        content: '…';
        margin-right: 15px;
        color: #c8c8c8;
        font-size: 13px;
        transform: translateX(-100%);
      }

      .text::after {
        content: '展开';
      }
    }
  }

  .group-members_exp {
    display: none;

    &:checked + .more-text {
      -webkit-line-clamp: 999;
      max-height: none;
    }

    &:checked + .more-text .more-btn .text::after {
      content: '收起';
    }

    &:checked + .more-text .more-btn .el-icon-arrow-down {
      transform: rotate(180deg);
    }

    &:checked + .more-text .more-btn::before {
      display: none;
    }

    &:checked + .more-text::after {
      display: none;
    }
  }
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/Index'
import Login from '@/views/Login'
import SignUp from '@/views/SignUp'
import ForgetPassword from '@/views/ForgetPassword'
import topic from '@/views/Topic'
import UserHome from '@/views/user/Home.vue'
import Supply from '@/views/supply/index.vue'
import SupplyInfo from '@/views/supply/info.vue'
import AddSupply from '@/views/supply/add.vue'
import IndexPage from '@/views/home/index.vue'
import Guarantee from '@/views/guarantee/index.vue'
import GuaranteeRecord from '@/views/guarantee/record.vue'
import GoodsList from '@/views/goods/GoodsList.vue'
import Enter from '@/views/enter/index.vue'
import JoinPay from '@/views/enter/joinPay.vue'
import PaySuc from '@/views/enter/paySuc.vue'
import JoinHis from '@/views/enter/joinHis.vue'
import AuditSuc from '@/views/enter/result/auditSuc.vue'
import AuditErr from '@/views/enter/result/auditErr.vue'
import Share from '@/views/share/index.vue'
import IncomeShare from '@/views/share/income.vue'
import InviteShare from '@/views/share/invite.vue'
import RankShare from '@/views/share/rankList.vue'
import PosterShare from '@/views/share/poster.vue'
import Partner from '@/views/partner/index.vue'
import PartnerJoin from '@/views/partner/enter.vue'

import Profile from '@/views/memberCenter/Profile'
import MyOrder from '@/views/order/MyOrder.vue'
import UserInfo from '@/views/memberCenter/MyInfo.vue'
import MyAddress from '@/views/memberCenter/MyAddress.vue'
import AccountSafe from '@/views/memberCenter/AccountSafe.vue'
import WalletList from '@/views/memberCenter/Walletlist.vue'
import MyWallet from '@/views/memberCenter/MyWallet.vue'
import MyCoupon from '@/views/memberCenter/MyCoupon.vue'
import MyCollect from '@/views/collect/index.vue'
import Attention from '@/views/attention/index.vue'
import History from '@/views/history/index.vue'
import Help from '@/views/help/index.vue'

import MerHome from '@/views/merchant/index.vue'
import MerInfo from '@/views/merchant/MerInfo.vue'

import Product from '@/views/product/index.vue'
import OrderConfirm from '@/views/order/OrderConfirm.vue'
import Payment from '@/views/order/Payment.vue'
import OrderResult from '@/views/order/result.vue'
import OrderDetails from '@/views/order/OrderDetails.vue'

import Message from '@/views/message/index.vue'
import Coupon from '@/views/coupon/index.vue'
import Notice from '@/views/notice/index.vue'
import MySupply from '@/views/supply/MySupply.vue'
import MyAdd from '@/views/supply/myAdd.vue'
import Infor from '@/views/info/index.vue'
import Details from '@/views/info/details.vue'
import About from '@/views/about/index.vue'
import FooterHelp from '@/views/footerHelp/index.vue'
import Privacy from '@/views/privacy/index.vue'
import ChatIm from '@/views/im/index.vue'

const routes = [
  {
    path: '/', // 首页
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/',
        name: 'IndexPage',
        component: IndexPage,
        meta: {
          title: '首页',
        },
      },
      {
        path: '/MerHome',
        name: 'MerHome',
        component: MerHome,
        meta: {
          title: '商户',
        },
      },
      {
        path: '/MerInfo',
        name: 'MerInfo',
        component: MerInfo,
        meta: {
          title: '商户信息',
        },
      },
      {
        path: '/Coupon',
        name: 'Coupon',
        component: Coupon,
        meta: {
          title: '优惠券',
        },
      },
      {
        path: '/Product',
        name: 'Product',
        component: Product,
        meta: {
          title: '商品信息',
        },
      },
      {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
          title: '关于我们',
        },
      },
      {
        path: '/FooterHelp',
        name: 'FooterHelp',
        component: FooterHelp,
        meta: {
          title: '政策与隐私',
        },
      },
      {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy,
        meta: {
          title: '政策与隐私',
        },
      },
      {
        path: '/OrderConfirm',
        name: 'OrderConfirm',
        component: OrderConfirm,
        meta: {
          title: '订单确认',
        },
      },
      {
        path: '/Payment',
        name: 'Payment',
        component: Payment,
        meta: {
          title: '订单支付',
        },
      },
      {
        path: '/OrderResult',
        name: 'OrderResult',
        component: OrderResult,
        meta: {
          title: '订单结果',
        },
      },
      {
        path: '/Guarantee',
        name: 'Guarantee',
        component: Guarantee,
        meta: {
          title: '担保',
        },
      },
      {
        path: '/GuaranteeRecord',
        name: 'GuaranteeRecord',
        component: GuaranteeRecord,
        meta: {
          title: '担保记录',
        },
      },
      {
        path: '/Notice',
        name: 'Notice',
        component: Notice,
        meta: {
          title: '公告信息',
        },
      },
      {
        path: '/GoodsList',
        name: 'GoodsList',
        component: GoodsList,
        meta: {
          title: '商品列表',
        },
      },
      {
        path: '/enter',
        name: 'enter',
        component: Enter,
        meta: {
          title: '商户入驻',
        },
      },
      {
        path: '/Infor',
        name: 'Infor',
        component: Infor,
        meta: {
          title: '资讯曝光',
        },
      },
      {
        path: '/Details',
        name: 'Details',
        component: Details,
        meta: {
          title: '资讯详情',
        },
      },
      {
        name: 'UserHome', // 忘记密码
        path: '/UserHome', // 主页
        component: UserHome,
        children: [
          {
            path: '/Message',
            name: 'Message',
            component: Message,
            meta: {
              title: '消息中心',
            },
          },
          {
            path: '/MyCoupon',
            name: 'MyCoupon',
            component: MyCoupon,
            meta: {
              title: '我的优惠券',
            },
          },
          {
            path: '/MySupply',
            name: 'MySupply',
            component: MySupply,
            meta: {
              title: '我的供需',
            },
          },
          {
            path: '/MyAdd',
            name: 'MyAdd',
            component: MyAdd,
            meta: {
              title: '编辑供需',
            },
          },
          {
            path: '/History',
            name: 'History',
            component: History,
            meta: {
              title: '我的足迹',
            },
          },
          {
            path: '/Help',
            name: 'Help',
            component: Help,
            meta: {
              title: '帮助中心',
            },
          },
          {
            path: '/MyCollect',
            name: 'MyCollect',
            component: MyCollect,
            meta: {
              title: '我的收藏',
            },
          },
          {
            path: '/Profile',
            name: 'Profile',
            component: Profile,
            meta: {
              title: '个人中心',
            },
          },
          {
            path: '/OrderDetails',
            name: 'OrderDetails',
            component: OrderDetails,
            meta: {
              title: '订单详情',
            },
          },
          {
            path: '/MyWallet',
            name: 'MyWallet',
            component: MyWallet,
            meta: {
              title: '我的钱包',
            },
          },
          {
            path: '/MyOrder',
            name: 'MyOrder',
            component: MyOrder,
            meta: {
              title: '订单中心',
            },
          },
          {
            path: '/UserInfo',
            name: 'UserInfo',
            component: UserInfo,
            meta: {
              title: '我的资料',
            },
          },
          {
            path: '/Attention',
            name: 'Attention',
            component: Attention,
            meta: {
              title: '关注店铺',
            },
          },
          {
            path: '/MyAddress',
            name: 'MyAddress',
            component: MyAddress,
            meta: {
              title: '地址管理',
            },
          },
          {
            path: '/AccountSafe',
            name: 'AccountSafe',
            component: AccountSafe,
            meta: {
              title: '账户设置',
            },
          },
          {
            path: '/WalletList',
            name: 'WalletList',
            component: WalletList,
            meta: {
              title: 'USDT地址薄',
            },
          }
        ]
      },
      {
        path: '/Supply',
        name: 'Supply',
        component: Supply,
        meta: {
          title: '供需',
        },
      },
      {
        path: '/JoinPay',
        name: 'JoinPay',
        component: JoinPay,
        meta: {
          title: '供需支付',
        },
      },
      {
        path: '/PaySuc',
        name: 'PaySuc',
        component: PaySuc,
        meta: {
          title: '入驻申请',
        },
      },
      {
        path: '/JoinHis',
        name: 'JoinHis',
        component: JoinHis,
        meta: {
          title: '申请历史',
        },
      },
      {
        path: '/AuditSuc',
        name: 'AuditSuc',
        component: AuditSuc,
        meta: {
          title: '申请审核成功',
        },
      },
      {
        path: '/AuditErr',
        name: 'AuditErr',
        component: AuditErr,
        meta: {
          title: '申请审核驳回',
        },
      },
      {
        path: '/SupplyInfo',
        name: 'SupplyInfo',
        component: SupplyInfo,
        meta: {
          title: '供需详情',
        },
      },
      {
        path: '/AddSupply',
        name: 'AddSupply',
        component: AddSupply,
        meta: {
          title: '新增供需',
        },
      },
      // 推广分享
      {
        path: '/Share',
        name: 'Share',
        component: Share,
        meta: {
          title: '推广分享',
        },
      },
      // 收益详情
      {
        path: '/IncomeShare',
        name: 'IncomeShare',
        component: IncomeShare,
        meta: {
          title: '收益详情',
        },
      },
      // 邀请详情
      {
        path: '/InviteShare',
        name: 'InviteShare',
        component: InviteShare,
        meta: {
          title: '邀请详情',
        },
      },
      // 邀请排行榜
      {
        path: '/RankShare',
        name: 'RankShare',
        component: RankShare,
        meta: {
          title: '邀请排行榜',
        },
      },
      // 分享海报
      {
        path: '/PosterShare',
        name: 'PosterShare',
        component: PosterShare,
        meta: {
          title: '分享海报',
        },
      },
      // 成为合伙人
      {
        path: '/Partner',
        name: 'Partner',
        component: Partner,
        meta: {
          title: '成为合伙人',
        },
      },
      // 申请成为合伙人
      {
        path: '/PartnerJoin',
        name: 'PartnerJoin',
        component: PartnerJoin,
        meta: {
          title: '申请成为合伙人',
        },
      },
      {
        path: '/topic', // 首页
        name: 'topic',
        component: topic,
      },
    ],
  },
  {
    path: '/login', // 登陆
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/SignUp', // 注册
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: '注册',
    },
  },
  {
    path: '/forgetPassword', // 忘记密码
    name: 'forgetPassword',
    component: ForgetPassword,
    meta: {
      title: '忘记密码',
    },
  },
  {
    path: '/ChatIm', // 忘记密码
    name: 'ChatIm',
    component: ChatIm,
    meta: {
      title: '忘记密码',
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router

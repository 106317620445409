<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Enter">
          商家入驻
        </router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="margin-top-sm">
      <a-card :bordered="false" :body-style="{height: '475px'}">
        <div class="cu-center title margin-top-xl">
          <Icon type="md-checkmark-circle" color="#52C41A" size="34"/>
          入驻申请成功！
        </div>

        <div class="content-font-1 margin-top-xl">
          平台将于24小时内审核完成！审核结果将通过邮箱发送至您，请届时注意查收！
        </div>

        <div class="content-font-2 margin-top-xs">
          您也可以通过申请记录跟踪实时进度！如遇问题请联系在线客服为您服务！
        </div>

        <div class="cu-center margin-top-xl">
          <div class="content-btn cursor-pointer" @click="suc">已完成</div>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    suc() {
      this.$router.replace({ path: '/' })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;

  .card-cu {
    height: 475px;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 25px;
  }

  .content-font-1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
  }

  .content-font-2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #999999;

    .conten-customer {
      color: #269FFA;
    }
  }

  .content-btn {
    width: 115px;
    height: 38px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #FFFFFF;
  }
}
</style>

<template>
  <div class="page-container-all width_1200_auto">
    <a-image :preview="false" class="all-page-container" :src="require('@/assets/share/top.png')"></a-image>
    <div class="page-join-content">
      <div class="font-top1">
        <!--        <a-image :preview="false" :width="460" :height="50"  :src="require('@/assets/share/topfont.png')" />-->
      </div>
      <div class="font-top2">
        <!--        在51担保赚取店铺租金68+48+18返佣-->
      </div>
      <div class="page-join cursor-pointer">
        <!--        以及高达50%永久交易返佣-->
        <div class="font-cus"></div>
      </div>
      <div class="page-container">
        <div class="page-btns">
          <div class="btn--1 btn-font  cursor-pointer" @click="()=>{$router.push({path:'/PosterShare'})}">
            <Icon type="ios-images-outline" size="20" style="margin-right: 5px"/>
            生成海报
          </div>
          <div class="btn--2 btn-font cursor-pointer">
            <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
              <template #title>
                <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
              </template>
              <template #content>
                <div class="mt-6">
                  <div class="text-gray tg-url-share d-flex justify-space-between">
                    <div>{{ invitationCode }}</div>
                    <div class="copy-btn" @click="$Copy({text: invitationCode})">复制</div>
                  </div>
                  <div class="telegram-btn" @click="toTelegram">
                    将链接分享至Telegram
                  </div>
                </div>
              </template>
              <div class="btn-2 cursor-pointer">
                邀请码: {{ invitationCode }}
                <Icon type="md-copy" size="20" style="margin-left: 5px"/>
              </div>
            </a-popover>
          </div>
          <div class="btn--3 btn-font cursor-pointer line1">
            <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
              <template #title>
                <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
              </template>
              <template #content>
                <div class="mt-6">
                  <div class="text-gray tg-url-share d-flex justify-space-between">
                    <div>{{ urlPre + '/pages/users/invite/index...' }}</div>
                    <div class="copy-btn" @click="copyShareUrl">复制</div>
                  </div>
                  <div class="telegram-btn" @click="toTelegram">
                    将链接分享至Telegram
                  </div>
                </div>
              </template>
              {{ urlPre + '/pages/users/invite/index...' }}
              <Icon type="md-copy" size="20" style="margin-left: 5px"/>
            </a-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <a-card :bordered="false" :body-style="{padding: '20px', width:'1200px'}">
        <template #title>
          <div class="flex-title">
            <div class="line-shu"></div>
            <div class="ml-2">我的推广数据</div>
          </div>
        </template>
        <div>
          <a-row :gutter="[16, 24]">
            <a-col :span="8">
              <div class="content-cus">
                <div class="info-font">收益</div>
                <div class="info-font-1">{{
                    spreadDataInfo.myIncome ? spreadDataInfo.myIncome + ' USDT' : '0.00 USDT'
                  }}
                </div>
                <div class="botm-btns" @click="()=>{$router.push({path:'/IncomeShare'})}">查看收益详情</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="content-cus">
                <div class="info-font">我的邀请</div>
                <div class="info-font-1">{{ ' ' }}</div>
                <div class="botm-btns" @click="()=>{$router.push({path:'/InviteShare'})}">邀请详情</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="content-cus">
                <div class="info-font">排名</div>
                <div class="info-font-1">
                  <span class="text-red">No.{{ spreadDataInfo.rank ? spreadDataInfo.rank : 0 }}</span>
                  <span class="ml-3">{{ spreadDataInfo.income + ' USDT' }}</span>
                </div>
                <div class="botm-btns" @click="()=>{$router.push({path:'/RankShare'})}">查看排行榜</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>
    <div class="mt-8">
      <a-card :bordered="false" :body-style="{padding: '3px', width:'100%'}">
        <template #title>
          <div class="flex-title">
            <div class="line-shu"></div>
            <div class="ml-2">推广规则</div>
          </div>
        </template>
        <template #extra>
          <div class="flex-title-view cursor-pointer" @click="visible2 = true">
            全屏查看
          </div>
        </template>
        <div>
          <arco-image-preview :src="require('@/assets/share/tg.png')" v-model:visible="visible2"/>
          <arco-image :preview="false"
                      :src="require('@/assets/share/tg.png')" :width="1200" height="100%"/>
        </div>
      </a-card>
    </div>

    <div class="mt-8 cursor-pointer mb-6" @click="pointToPartner">
      <a-image :preview="false" :src="require('@/assets/share/btm.png')" :width="1200" :height="196"/>
    </div>
  </div>
</template>
<script>
import { getUserInfo, myPromotion } from '@/api/user.js'

import { spreadData, } from '@/api/tg.js'

import { company } from '@/config/index.js'
import { Scroll } from 'view-ui-plus'

export default {
  components: { Scroll },
  data() {
    return {
      visible2: false,
      promotionInfo: {},
      bgColor: '#e93323',
      invitationCode: '',
      spreadDataInfo: {},
      showBotm: false,
      urlPre: '',
    }
  },
  created() {
    if (this.$store.getters.token) {
      this.urlPre = company.app
      this.getMyPromotion()
      this.getMyUserInfo()
      this.getSpreadData()
    } else {
      this.$router.push({
        path: '/Login',
        query: {}
      })
    }
  },
  methods: {
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.urlPre}/pages/users/invite/index?spread=${this.invitationCode}`, '_blank')
    },
    pointToPartner() {
      this.$router.push({
        path: '/Partner',
        query: {}
      })
    },
    copyShareUrl() {
      this.$Copy({ text: this.urlPre + '/pages/users/invite/index?spread=' + this.invitationCode })
    },
    copyShare() {
      this.$Copy({ text: this.invitationCode })
    },
    getSpreadData() {
      spreadData()
          .then((res) => {
            this.spreadDataInfo = res.data
            if (this.spreadDataInfo.num >= 100) {
              this.showBotm = true
            }
          })
    },
    getMyUserInfo: function () {
      getUserInfo({
        page: 1,
        limit: 5
      })
          .then(res => {
            this.invitationCode = res.data.invitationCode
          })
    },
    /**
     * 获取个人用户信息
     */
    getMyPromotion: function () {
      myPromotion()
          .then(res => {
            this.promotionInfo = res.data
          })
    },
  }
}
</script>

<style scoped lang="scss">
.flex-title-view {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content-cus {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;

  .info-font {
    font-size: 16px;
    font-weight: normal;
    line-height: 38px;
    height: 38px;
    letter-spacing: 0px;
    color: #999999;
  }

  .info-font-1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    letter-spacing: 0px;
    margin-top: 10px;
  }

  .botm-btns {
    margin-top: 10px;
    width: 120px;
    height: 35px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;

    font-size: 14px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

.flex-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .line-shu {
    width: 3px;
    height: 17px;
    background: #269FFA;
    border-radius: 10px;
  }
}

.page-container {
  width: 1200px;
  margin-top: 320px;

  .page-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 50px;

    .btn-font {
      font-size: 15px;
      font-weight: normal;
      line-height: 25px;
      color: #FFFFFF;
      margin-right: 10px;
    }

    .btn--1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 192px;
      height: 50px;
      border-radius: 50px;
      background: #269FFA;
      margin-right: 10px;
    }

    .btn--2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 50px;
      border-radius: 50px;
      background: #FFBA18;
      margin-right: 10px;
    }

    .btn--3 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      width: 418px;
      height: 50px;
      border-radius: 50px;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 99%);

      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: #999999;
    }
  }
}


.page-container-all {
  margin-top: 3px;
  position: relative;
  //width: 100%;
  min-height: 1800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
  }

  .page-join-content {
    position: absolute;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .font-top1 {
      font-size: 50px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0px;
      color: #FF553B;
    }

    .font-top2 {
      margin-top: 30px;
      font-size: 22px;
      font-weight: 350;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FF6923;
    }

    .page-join {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 900;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FF6923;
    }
  }

}
</style>

<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}" :loading="loading">
      <div class="d-flex">
        <div class="text-df text-bold me-auto">我的足迹</div>
        <!--        <div v-if="!selected" class="text-gray cursor-pointer" @click="delList">批量删除</div>-->
        <!--        <div v-if="selected" class="text-gray cursor-pointer" @click="delList">全部删除</div>-->
      </div>
      <empty v-if="!list || list.length === 0"/>
      <div v-else>
        <div class="mt-3">共{{ total }}条，最多为您保存100条</div>
        <div class="mt-4">
          <a-card class="mb-5" v-for="(item,index) in list" :key="index" :bordered="false"
                  :bodyStyle="{padding: '0'}">
            <div class="text-df text-bold me-auto mb-3">{{ item.date }}</div>
            <div class="d-flex align-content-start flex-wrap ">
              <div class="mb-3 position-relative cursor-pointer" style="width: 20%" v-for="(items,indexs) in item.list"
                   :key="indexs"
                   @click="() => { $router.push({path: '/Product' , query:{
                  id: items.productId
                }})}">
                <a-image class="rounded" :src="items.image" :preview="false" :width="160" :height="160"/>
                <div class="cu-center text-red text-bold mt-2">{{ items.price }} USDT</div>

                <div class="card-del cu-center cursor-pointer" v-if="selected">
                  <Icon class="text-bold" type="ios-trash-outline" :size="14"/>
                </div>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  browseRecordApi,
} from '@/api/user.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      selected: false,
      total: 0,
      loading: false,
      list: [],
      allArr: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.list = []
      this.loading = true
      browseRecordApi()
          .then(({
            data
          }) => {
            this.list = data
            data.map(item => {
              this.total += item.list.length
              item.date = item.date.slice(5, 7) + '月' + item.date.slice(8, 10) + '日'
            })
            this.loading = false
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.card-del {
  position: absolute;
  top: 5px;
  right: 35px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  opacity: 0.8;
  background: #5D5D5D;

  color: #FFFFFF;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>

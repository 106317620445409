<template>
  <div id="uesrtext">
    <div>
      <a-popover class="emoBox">
        <template #content>
          <div class="emotionList">
            <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item,index) in faceList" :key="index"
               class="emotionItem">{{ item }}
            </a>
          </div>
        </template>
        <a-button id="emojiBtn" class="emotionSelect" slot="reference">
          <template #icon>
            <smile-outlined/>
          </template>
        </a-button>
      </a-popover>
      <a-upload v-model:file-list="fileList"
                :action="uploadUrl"
                :maxCount="1"
                accept="image/*"
                :name="'multipart'"
                @change="handleChange"
                @beforeUpload="beforeUpload"
                :showUploadList="false">
        <a-button id="uploadImgBtn">
          <template #icon>
            <FileImageOutlined/>
          </template>
        </a-button>
      </a-upload>
    </div>
    <textarea id="textarea" placeholder="按 Enter 发送" v-model="content" v-on:keyup="addMessage" @input="inPutSearch">
    </textarea>
    <a-button id="sendBtn" type="primary" size="mini" @click="addMessageByClick">发送</a-button>
  </div>
</template>

<script>
import { FileImageOutlined, SmileOutlined } from '@ant-design/icons-vue'
import { BASE_URL } from '@/config'
import { getOrderDetail } from '@/api/order'
import { cusInfoSave } from '@/api/message'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const appData = require('../../utils/emoji.json')//引入存放emoji表情的json文件

export default {
  name: 'uesrtext',
  components: {
    SmileOutlined,
    FileImageOutlined
  },
  props: {
    selectItem: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      faceList: [],//表情包数据
      content: '',
      uploadUrl: '',
      fileList: [],
      orderInfo: {},
      inviteUserId: ''
    }
  },
  mounted() {
    this.uploadUrl = BASE_URL + 'upload/image?model=product&pid=1'
    const emojiDate = appData.data.split(',')
    for (let i in emojiDate) {//读取json文件保存数据给数组
      this.faceList.push(emojiDate[i])
    }

    FingerprintJS.load()
        .then(fp => {
          fp.get()
              .then(result => {
                let visitorId = result.visitorId
                this.inviteUserId = visitorId
              })
        })
  },
  methods: {
    inPutSearch(e) {
      const regex = /^[A-Z0-9]+$/
      if (regex.test(this.content) && this.content.length === 23) {
        this.$nextTick(() => {
          this.getOrderInfo(this.content)
        })
      }
    },
    getOrderInfo(orderNo) {
      let that = this
      let tempMessages = null
      getOrderDetail(orderNo)
          .then(res => {
            that.orderInfo = res.data
            if (res.data) {
              tempMessages = {
                type: 1,
                merId: that.orderInfo.merId,
                linkId: orderNo,
                orderNo: orderNo,
                title: '',
                icon: that.orderInfo.merchantOrderList[0].orderInfoList[0].image,
                price: that.orderInfo.payPrice,
                describe: '',
                isSend: true,
                orderTime: that.orderInfo.createTime,
              }
              this.$emit('tempMessage', tempMessages)
              this.content = ''
            }
          })
    },
    handleChange(info) {
      this.$Spin.show()
      const { status } = info.file
      if (status === 'done') {
        const response = info.file.response
        this.imgUploadSend(response.data.url)
      }
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 15
      if (!isLt10M) {
        this.$Message.error('文件大小不能超过 15MB!')
      }
      return isLt10M
    },
    imgUploadSend(urlIcon) {
      let data = {
        type: 1,
        uid: this.selectItem.uid,
        merId: this.selectItem.merId,
        linkId: '',
        msgType: 1,
        orderNo: '',
        title: '',
        icon: urlIcon,
        price: '',
        isSend: false,
        msgTime: '',
        top1msg: '',
        top2msg: '',
        top3msg: '',
        inviteUserId: this.inviteUserId,
      }
      cusInfoSave(data)
          .then(res => {
            this.content = ''
            this.$Spin.hide()
            this.$emit('refresh')
          })
    },
    addMessageByClick() {
      //清空输入框
      this.sendMsg()
    },
    addMessage(e) {
      if (e.keyCode === 13 && this.content.length) {
        this.addMessageByClick()
      }
    },
    sendMsg(index) {
      let data = {}
      if (!this.content) {
        if (index === 1 || index === 2 || index === 3) {
          data = {
            type: 1,
            uid: this.selectItem.uid,
            merId: this.selectItem.merId,
            linkId: '',
            msgType: 0,
            orderNo: '',
            title: index === 1 ? this.top1msg : index === 2 ? this.top2msg : index === 3 ? this.top3msg : '',
            icon: '',
            price: '',
            isSend: false,
            msgTime: '',
          }
        } else {
          return
        }
      } else {
        data = {
          type: 1,
          uid: this.selectItem.uid,
          merId: this.selectItem.merId,
          linkId: '',
          msgType: 0,
          orderNo: '',
          title: this.content,
          icon: '',
          price: '',
          isSend: false,
          msgTime: '',
          inviteUserId: this.inviteUserId,
        }
      }
      cusInfoSave(data)
          .then(res => {
            this.content = ''
            this.$emit('refresh')
          })
    },
    //获取Emoji
    getEmo(index) {
      var textArea = document.getElementById('textarea')

      //将选中的表情插入到输入文本的光标之后
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str)
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length
          textArea.focus()
        } else if (document.selection) {
          // IE浏览器
          obj.focus()
          var sel = document.selection.createRange()
          sel.text = str
        }
      }

      changeSelectedText(textArea, this.faceList[index])
      this.content = textArea.value// 要同步data中的数据
      // console.log(this.faceList[index]);
      return

    }
  }
}
</script>

<style lang="scss">
/* el-popover是和app同级的，所以scoped的局部属性设置无效 */
/* 需要设置全局style */
.a-popover {
  height: 200px;
  width: 450px;
  overflow: scroll;
  overflow-x: auto;
}
</style>

<style lang="scss" scoped>
.emoBox {
  z-index: 9999999 !important;
}

#uesrtext {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30%;
  border: solid 1px #DDD;
  background-color: white;

  > textarea {
    padding: 10px;
    width: 95%;
    height: 58%;
    border: none;
    outline: none;
    resize: none; //禁止拉伸
  }

  #sendBtn {
    float: right;
    margin-right: 10px;
  }

  #uploadImgBtn {
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 12px;
  }

  #uploadImgBtn:hover {
    background-color: white;
  }

  #emojiBtn {
    border: none;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  #emojiBtn:hover {
    background-color: white;
  }

  .upload-btn {
    display: inline-block;
  }
}

.emotionList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  height: 200px;
  width: 450px;
  overflow: scroll;
  overflow-x: auto;
}

.emotionItem {
  width: 10%;
  font-size: 20px;
  text-align: center;
}

/*包含以下四种的链接*/
.emotionItem {
  text-decoration: none;
}

/*正常的未被访问过的链接*/
.emotionItem:link {
  text-decoration: none;
}

/*已经访问过的链接*/
.emotionItem:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
.emotionItem:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
.emotionItem:active {
  text-decoration: none;
}
</style>

<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="520"
             :body-style="{padding: '24px 24px 0 24px'}"
             @close="cancel"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">退货物流信息填写</div>
      </template>
      <a-form
          :model="formData"
          name="basic"
          autocomplete="off"
          @finish="onFinish">
        <a-form-item label="手机号码" name="phone" :rules="[{ required: true, message: '请填写寄件人手机号码' }]">
          <a-input class="input-cell" v-model:value="formData.phone" placeholder="请填写寄件人手机号码"/>
        </a-form-item>
        <a-form-item label="物流公司" name="logistics" :rules="[{ required: true, message: '请填写物流公司' }]">
          <a-input class="input-cell" v-model:value="formData.logistics" placeholder="请填写物流公司"/>
        </a-form-item>
        <a-form-item label="物流单号" name="tracking" :rules="[{ required: true, message: '请填写物流单号' }]">
          <a-input class="input-cell" v-model:value="formData.tracking" placeholder="请填写物流单号"/>
        </a-form-item>
        <a-form-item>
          <div class="text-right">
            <a-button type="primary" html-type="submit">确认退货</a-button>
          </div>
        </a-form-item>
      </a-form>
      <template #footer>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'Evaluate',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formData: {
        logistics: '',
        tracking: '',
        phone: ''
      }
    }
  },
  created() {
    this.formData = {
      logistics: '',
      tracking: '',
      phone: ''
    }
  },
  methods: {
    onFinish() {
      this.$emit('change', this.formData)
      this.formData = {
        logistics: '',
        tracking: '',
        phone: ''
      }
    },
    cancel() {
      this.$emit('change')
      this.formData = {
        logistics: '',
        tracking: '',
        phone: ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <div id="telegram" ref="telegram"  class="test"/>
  </div>
</template>

<script>
export default {
  name: 'vue-telegram-login',
  props: {
    mode: {
      type: String,
      required: true,
      validator(value) {
        return ['callback', 'redirect'].includes(value);
      }
    },
    telegramLogin: {
      type: String,
      required: true,
      validator(value) {
        return value.endsWith('bot') || value.endsWith('Bot');
      }
    },
    redirectUrl: {
      type: String,
      default: ''
    },
    requestAccess: {
      type: String,
      default: 'write',
      validator(value) {
        return ['read', 'write'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      }
    },
    userpic: {
      type: Boolean,
      default: true
    },
    radius: {
      type: String
    }
  },
  mounted() {
    this.addTelegramScript();
  },
  beforeUnmount() {
    this.removeTelegramScript();
  },
  methods: {
    onTelegramAuth(user) {
      this.$emit('callback', user);
    },
    addTelegramScript() {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      script.setAttribute('data-size', this.size);
      script.setAttribute('data-userpic', this.userpic);
      script.setAttribute('data-telegram-login', this.telegramLogin);
      script.setAttribute('data-request-access', this.requestAccess);
      if (this.radius) {
        script.setAttribute('data-radius', this.radius);
      }
      if (this.mode === 'callback') {
        window.onTelegramAuth = this.onTelegramAuth;
        script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
      } else {
        script.setAttribute('data-auth-url', this.redirectUrl);
      }
      this.$el.appendChild(script)
    },
    removeTelegramScript() {
      const script = this.$el.querySelector('script[src*="telegram-widget"]');
      if (script) {
        this.$el.removeChild(script);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.tgme_widget_login.large button.tgme_widget_login_button {
  opcity: 0;
  cursor: pointer;
}
</style>

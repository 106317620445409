<template>
  <div class="content-drawer">
    <a-spin size="large" :spinning="loading">
      <div v-if="title === '购物车'" class="cart-con">
        <ul>
          <li v-for="(goods,goodsIndex) in cartList" :key="goodsIndex">
            <div>
              <img :src="goods.goodsSku.thumbnail" width="90" height="90" alt="">
            </div>
            <div>
              <p class="hover-color"
                 @click="linkTo(`/goodsDetail?skuId=${goods.goodsSku.id}&goodsId=${goods.goodsSku.goodsId}`)">
                {{ goods.goodsSku.goodsName }}</p>
              <p class="price">{{ goods.goodsSku.price | unitPrice('￥') }}<span>&nbsp; x{{ goods.num }}</span></p>
            </div>
            <span class="del hover-color" @click="delGoods(goods.goodsSku.id)">删除</span>
          </li>
        </ul>
        <Button size="large" class="mt_10" type="primary" @click="linkTo('/cart')" long>去购物车结算</Button>
      </div>
      <div v-else-if="title === '我的订单'" class="order-con">
        <ul>
          <li v-for="(order,orderIndex) in orderList" :key="orderIndex">
            <div class="order-status">
              <span>{{ filterOrderStatus(order.orderStatus) }}</span><span>{{ order.createTime }}</span></div>
            <div class="goods-img">
              <img :src="img.image"
                   @click="linkTo(`/goodsDetail?skuId=${img.skuId}&goodsId=${img.goodsId}`)"
                   v-for="(img,imgIndex) in order.orderItems"
                   :key="imgIndex" width="40" height="40" alt="">
            </div>
            <div class="order-handle"><span>{{ order.flowPrice | unitPrice('￥') }}</span><span class="hover-color"
                                                                                               @click="linkTo(`home/OrderDetail?sn=${order.sn}`)">查看订单</span>
            </div>
          </li>
        </ul>
        <Button type="primary" @click="linkTo('/home/MyOrder')" long>查看全部订单</Button>
      </div>
      <div v-else-if="title === '优惠券'" class="coupon-con">
        <ul class="coupon-list">
          <li v-for="(coupon, index) in couponList" class="coupon-item" :key="index">
            <div class="c-left">
              <div>
                <span v-if="coupon.couponType === 'PRICE'" class="fontsize_12 global_color">￥<span
                    class="price">{{ coupon.price | unitPrice }}</span></span>
                <span v-if="coupon.couponType === 'DISCOUNT'" class="fontsize_12 global_color"><span
                    class="price">{{ coupon.couponDiscount }}</span>折</span>
                <span class="describe">满{{ coupon.consumeThreshold }}元可用</span>
              </div>
              <p>使用范围：{{ useScope(coupon.scopeType, coupon.storeName) }}</p>
              <p>有效期：{{ coupon.endTime }}</p>
            </div>
            <b></b>
            <a class="c-right" @click="receive(coupon)">立即领取</a>
            <i class="circle-top"></i>
            <i class="circle-bottom"></i>
          </li>
        </ul>
      </div>
      <div v-else-if="title === '我的足迹'" class="tracks-con">
        <ul>
          <li v-for="(track,trackIndex) in tracksList" :key="trackIndex">
            <img :src="track.thumbnail" :alt="track.thumbnail"
                 @click="linkTo(`/goodsDetail?skuId=${track.id}&goodsId=${track.goodsId}`)" width="100" height="100">
            <div @click="addToCart(track.id)">加入购物车</div>
            <p class="global_color">{{ track.price | unitPrice('￥') }}</p>
          </li>
        </ul>
        <div class="hover-color" style="text-align:center;" @click="linkTo('/home/MyTracks')">查看更多>></div>
      </div>
      <div v-else-if="title === '我的收藏'" class="collect-con">
        <ul>
          <li v-for="(collect,collectIndex) in collectList" :key="collectIndex">
            <img :src="collect.image" :alt="collect.image"
                 @click="linkTo(`/goodsDetail?skuId=${collect.skuId}&goodsId=${collect.goodsId}`)" width="100"
                 height="100">
            <div @click="addToCart(collect.skuId)">加入购物车</div>
            <span class="del-icon" @click.stop="cancelCollect(collect.skuId)">
            <Icon type="md-trash"/>
          </span>
            <p class="global_color">{{ collect.price | unitPrice('￥') }}</p>
          </li>
        </ul>
        <div class="hover-color" style="text-align:center;" @click="linkTo('/home/Favorites')">查看更多>></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    title: {
      default: '',
      type: String
    }
  },
  watch: {},
  data() {
    return {
      loading: false, // 控制spin显隐
      cartList: [], // 购物车列表
      couponList: [], // 优惠券列表
      orderList: [], // 订单列表
      collectList: [], // 收藏列表
      tracksList: [], // 足迹列表
      orderStatusList: [ // 订单状态
        {
          name: '未付款',
          status: 'UNPAID'
        },
        {
          name: '已付款',
          status: 'PAID'
        },
        {
          name: '待发货',
          status: 'UNDELIVERED'
        },
        {
          name: '已发货',
          status: 'DELIVERED'
        },
        {
          name: '已完成',
          status: 'COMPLETED'
        },
        {
          name: '待核验',
          status: 'TAKE'
        },
        {
          name: '已取消',
          status: 'CANCELLED'
        }
      ]
    }
  },
  methods: {
    getCartList() {
    },
    // 删除商品
    delGoods(id) {
    },
    filterOrderStatus(status) { // 获取订单状态中文
    },
    receive(item) { // 领取优惠券
    },
    useScope(type, storeName) { // 判断优惠券使用范围
    },
    addToCart(id) { // 添加商品到购物车
    },
    getCouponList() { // 获取优惠券列表
    },
    getOrderList() { // 获取订单列表
    },
    getCollectList() { // 获取收藏列表
    },
    cancelCollect(id) { // 取消商品收藏
    },
    getTracksList() { // 获取足迹列表
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/coupon.scss';
@import './drawer.scss';

.coupon-item {
  overflow: hidden;
  background-color: #fff;
  height: 120px;

  .c-left {
    padding: 15px;
  }

  .c-right {
    width: 38px;
    padding: 13px;
    font-size: 14px;
  }

  i {
    right: 30px;
    background-color: #eee;
  }
}
</style>

import {
  getPayConfigApi
} from '@/api/order.js'

import {
  getUserInfo,
  getLogout
} from '@/api/user.js'

import {
  getMerTypeListApi,
  getMerCategoryListApi
} from '@/api/merchant.js'

import {
  tokenIsExistApi
} from '@/api/api.js'

import {
  Message
} from 'view-ui-plus'

import {
  LOGIN_STATUS,
  USER_INFO,
  UID,
  PLATFORM,
  GLOBAL_DATA,
} from '@/config/index.js'

import router from '@/router'
import { msgCusTempSave } from '@/api/message'

let cartArr = [
  {
    name: '余额支付',
    icon: 'icon-yuezhifu',
    value: 'yue',
    title: '可用余额:',
    payStatus: 1,
    userBalance: ''
  }
]
const state = {
  token: localStorage.getItem(LOGIN_STATUS) || false,
  backgroundColor: '#fff',
  userInfo: localStorage.getItem(USER_INFO) ? JSON.parse(localStorage.getItem(USER_INFO)) : null,
  uid: localStorage.getItem(UID) || null,
  homeActive: false,
  chatUrl: localStorage.getItem('chatUrl') || '',
  systemPlatform: localStorage.getItem(PLATFORM) ? localStorage.getItem(PLATFORM) : '',
  productType: localStorage.getItem('productType') || '',
  globalData: localStorage.getItem(GLOBAL_DATA) || {},
  merchantClassify: localStorage.getItem('merchantClassify') ? JSON.parse(localStorage.getItem('merchantClassify')) : [],
  /** 商户分类 **/
  merchantType: localStorage.getItem('merchantType') ? JSON.parse(localStorage.getItem('merchantType')) : [],
  /** 商户类型 **/
  merchantAPPInfo: localStorage.getItem('merchantAPPInfo') ? JSON.parse(localStorage.getItem('merchantAPPInfo')) : {},
  merSttledData: localStorage.getItem('merSttledData') ? JSON.parse(localStorage.getItem('merSttledData')) : {},
  bottomNavigationIsCustom: false, //是否使用自定义导航
  merTokenIsExist: localStorage.getItem('merTokenIsExist') || false,
  discoverTopic: [],
  language: localStorage.getItem('language') || 'zh',
  dark: localStorage.getItem('dark_css') || false,
  chatmsgcount: localStorage.getItem('chatmsgcount') || 0,
  readCount: localStorage.getItem('readCount') || 0,
  visitorUserId: localStorage.getItem('visitorUserId') || 0
}

const mutations = {
  VISITOR_USER_ID(state, visitorUserId) {
    state.visitorUserId = visitorUserId
    localStorage.setItem('visitorUserId', visitorUserId)
  },
  CHAT_MSG(state, count) {
    state.chatmsgcount = count
    localStorage.setItem('chatmsgcount', count)
  },
  NO_READ_COUNT(state, count) {
    state.readCount = count
    localStorage.setItem('readCount', count)
  },
  LOGIN(state, opt) {
    state.token = opt.token
    localStorage.setItem(LOGIN_STATUS, opt.token)
  },
  SETUID(state, val) {
    state.uid = val
    localStorage.setItem(UID, val)
  },
  UPDATE_LOGIN(state, token) {
    state.token = token
  },
  LOGOUT(state) {
    state.token = undefined
    state.uid = undefined
    localStorage.removeItem(LOGIN_STATUS)
    localStorage.removeItem(UID)
    localStorage.removeItem(USER_INFO)
  },
  BACKGROUND_COLOR(state, color) {
    state.color = color
    document.body.style.backgroundColor = color
  },
  UPDATE_USERINFO(state, userInfo) {
    state.userInfo = userInfo
    localStorage.setItem(USER_INFO, JSON.stringify(userInfo))
  },
  OPEN_HOME(state) {
    state.homeActive = true
  },
  CLOSE_HOME(state) {
    state.homeActive = false
  },
  SET_CHATURL(state, chatUrl) {
    state.chatUrl = chatUrl
  },
  SYSTEM_PLATFORM(state, systemPlatform) {
    state.systemPlatform = systemPlatform
    localStorage.setItem(PLATFORM, systemPlatform)
  },
  //更新useInfo数据
  changInfo(state, payload) {
    state.userInfo[payload.amount1] = payload.amount2
    localStorage.setItem(USER_INFO, state.userInfo)
  },
  //商品类型，用于区分视频号商品与一般商品
  PRODUCT_TYPE(state, productType) {
    state.productType = productType
    localStorage.setItem('productType', productType)
  },
  GLOBAL_DATA(state, key) {
    localStorage.setItem(GLOBAL_DATA, key)
    state.globalData = key
  },
  /** 商户全部分类  **/
  SET_MerchantClassify: (state, merchantClassify) => {
    state.merchantClassify = changeNodes(merchantClassify)
    localStorage.setItem('merchantClassify', JSON.stringify(changeNodes(merchantClassify)))
  },
  /** 商户全部类型 **/
  SET_MerchantType: (state, merchantType) => {
    state.merchantType = changeNodes(merchantType)
    localStorage.setItem('merchantType', JSON.stringify(changeNodes(merchantType)))
  },
  /** 商户信息 **/
  MERCHANTJINFO: (state, merchantJInfo) => {
    state.merchantAPPInfo = merchantJInfo
    localStorage.setItem('merchantAPPInfo', JSON.stringify(merchantJInfo))
  },
  LANGUAGE: (state, language) => {
    state.language = language
    localStorage.setItem('language', language)
  },
  DARK: (state, dark) => {
    state.dark = dark
    localStorage.setItem('dark_css', dark)
  },
  /** 入驻申请信息 **/
  MERSTTLEDDATA: (state, merSttledData) => {
    state.merSttledData = merSttledData
    localStorage.setItem('merSttledData', merSttledData)
  },
  /** 是否使用自定义导航 **/
  BottomNavigationIsCustom: (state, bottomNavigationIsCustom) => {
    state.bottomNavigationIsCustom = bottomNavigationIsCustom
  },
  /** 校验token是否有效 **/
  TokenIsExist: (state, merTokenIsExist) => {
    state.merTokenIsExist = merTokenIsExist
    localStorage.setItem('merTokenIsExist', merTokenIsExist)
  },
  /** 选中的话题列表 **/
  DiscoverTopic: (state, discoverTopic) => {
    state.discoverTopic = discoverTopic
    //localStorage.setItem('merTokenIsExist', merTokenIsExist);
  },
}

/** tree去除 childList=[] 的结构**/
const changeNodes = function (data) {
  if (data.length > 0) {
    for (var i = 0; i < data.length; i++) {
      if (!data[i].childList || data[i].childList.length < 1) {
        data[i].childList = undefined
      } else {
        changeNodes(data[i].childList)
      }
    }
  }
  return data
}

const actions = {
  /**
   * 校验token是否有效,true为有效，false为无效
   */
  GetTokenIsExist({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      tokenIsExistApi()
        .then(res => {
          commit('TokenIsExist', res.data)
          reslove(res.data)
        })
    }).catch(err => {
      return util.Tips({
        title: err
      })
    })
  },
  /**
   * 用户信息
   */
  USERINFO({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      getUserInfo()
        .then(res => {
          commit('UPDATE_USERINFO', res.data)
          commit('SETUID', res.data.id)
          reslove(res.data)
        })
    }).catch(() => {

    })
  },

  /**
   * 退出登录
   */
  GETLOGOUT({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      getLogout()
        .then(res => {
          commit('LOGOUT')
          router.replace({ path: '/' })
          reslove(res)
        })
    }).catch(() => {

    })
  },
  MerCategoryList({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      getMerCategoryListApi()
        .then(res => {
          commit('SET_MerchantClassify', res.data)
          reslove(res.data)
        })
    }).catch(err => {
      return util.Tips({
        title: err
      })
    })
  },
  MerTypeList({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      getMerTypeListApi()
        .then(res => {
          commit('SET_MerchantType', res.data)
          reslove(res.data)
        })
    }).catch(err => {
      return util.Tips({
        title: err
      })
    })
  },
  getPayConfig({
    state,
    commit
  }, force) {
    return new Promise(reslove => {
      getPayConfigApi()
        .then(res => {
          let data = res.data
          cartArr[0].payStatus = data.yuePayStatus ? 1 : 0
          cartArr[0].userBalance = data.userBalance ? data.userBalance : 0
          let cartArrs = cartArr.filter(e => e.payStatus === 1)
          reslove({
            userBalance: data.userBalance,
            payConfig: cartArrs
          })
        })
    })
  }
}
export default {
  state,
  mutations,
  actions
}

<template>
  <div class="container width_1200_auto">
    <div class="mer-top-card">
      <div class="margin-top-xs">
        <a-card>
          <a-row :gutter="[16,24]">
            <a-col :span="12">
              <a-carousel arrows dots-class="slick-dots slick-thumb">
                <template #customPaging="props">
                  <a><img :src="getImgUrl(props.i)"/></a>
                </template>
                <div v-for="(item, index) in sliderImage" :key="index">
                  <a-image class="rounded-lg" :width="520" :height="520" :src="item"/>
                </div>
              </a-carousel>
            </a-col>
            <a-col :span="12">
              <div class="product-name line2">
                <div>
                  {{ productInfo.name }}
                </div>
                <div class="cursor-pointer ml-3 d-flex flex-column align-items-center">
                  <!-- 分享-->
                  <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
                    <template #title>
                      <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
                    </template>
                    <template #content>
                      <div class="mt-6">
                        <div class="text-gray tg-url-share">
                          {{ tagUrl }}
                          <div class="copy-btn" @click="$Copy({text: tagUrl})">复制</div>
                        </div>
                        <div class="telegram-btn" @click="toTelegram">
                          将链接分享至Telegram
                        </div>
                      </div>
                    </template>
                    <div class="btn-2 cursor-pointer">
                      <Icon custom="iconfont icon-share" :size="24"/>
                    </div>
                  </a-popover>

                  <Icon v-if="!userCollect" color="#1F1F1F" class="mt-2" type="ios-heart-outline" :size="28"
                        @click="collectProduct"/>
                  <Icon v-else color="#269FFA" class="mt-2" type="md-heart" :size="28" @click="collectProduct"/>
                </div>
              </div>
              <div class="product-money">{{ productInfo.price }}<span class="unit">USDT</span></div>
              <div class="product-arrtr flex cursor-pointer">
                分类:
                <div v-for="(item,index) in skuArr" :key="index" @click="selectSku(item)">
                  <div class="product-attrValues cu-center">
                    {{ item.sku }}
                  </div>
                </div>
              </div>
              <div class="product-arrtr flex cursor-pointer text-gray" v-if="stock > 0">
                库存: <span class="ml-3 text-gray"> {{ stock }} </span>
              </div>
              <div class="product-arrtr flex mt-10">
                数量:
                <div class="product-buyNum cu-center">
                  <a-input-number id="inputNumber" v-model:value="buyNum" :min="1" :max="stock"/>
                </div>
              </div>
              <div>
                <div class="cursor-pointer product-buy cu-center mt-16" v-if="(stock - buyNum) >= 0"
                     @click="isSetPwd">
                  立即购买
                </div>
                <div class="product-buy-end cu-center mt-16" v-else>
                  库存不足
                </div>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </div>

      <div class="margin-top-xs">
        <a-card body-style="{padding: '28px'}" :bordered="false">
          <List>
            <ListItem>
              <template #extra>
                <div class="ml-3 d-flex align-end">
                  <div class="btn-2-gz cursor-pointer" @click="followToggle(merchantInfo)">
                    {{ isCollect ? '已关注' : '+关注店铺' }}
                  </div>

                  <div class="ml-3 btn-1 cursor-pointer" @click="customerOpen">
                    <Icon type="ios-headset-outline"/>
                    联系商家
                  </div>

                  <div class="btn-2 cursor-pointer ml-2" @click="customerDb">
                    <a-image :preview="false" :src="require('@/assets/login/dianbao.png')" :width="20" :height="20"/>
                    商家电报
                  </div>
                </div>
              </template>
              <ListItemMeta>
                <template #avatar>
                  <a-avatar :src="merchantInfo.avatar" :size="52" class="cursor-pointer"
                            @click="()=>{$router.push({path:'/MerHome', query:{merId: merchantInfo.id}})}"/>
                </template>
                <template #title>
                  <div class="d-flex align-center justify-start">
                    <div>
                      <div class="cursor-pointer"
                           @click="()=>{$router.push({path:'/MerHome', query:{merId: merchantInfo.id}})}">
                        {{ merchantInfo.name }}
                      </div>
                      <!--                      <div>-->
                      <!--                        <a-rate v-model:value="merchantInfo.starLevel" disabled-->
                      <!--                                style="font-size: 14px;margin-left: -2px"/>-->
                      <!--                      </div>-->
                      <div class="custom-font text-red">
                        保证金：{{ merchantInfo.earnestMoney }} USDT
                      </div>
                    </div>
                  </div>
                </template>
              </ListItemMeta>
            </ListItem>
            <div :bordered="false" :body-style="{ padding: '10px' }"
                 v-if="merchantInfo.proList && merchantInfo.proList.length > 0">
              <div class="margin-top-xs">
                <arco-scrollbar type="embed" style="height:120px;overflow: auto;">
                  <div class="flex mt-3">
                    <div class="mr-3 cursor-pointer" v-for="(items, index) in merchantInfo.proList" :key="index"
                         @click="() => {$router.push({path:'/Product', query:{ id: items.id}})}">
                      <div class="imgs">
                        <a-image :preview="false" :src="items.image" :width="109" :height="106"
                                 style="border-radius: 10px;"
                                 :fallback="require('@/assets/index/logo.png')"/>
                        <div class="usdt-bottom">
                          {{ items.price }}USDT
                        </div>
                      </div>
                    </div>
                  </div>
                </arco-scrollbar>
              </div>
            </div>
          </List>
        </a-card>
      </div>

      <div class="margin-top-xs">
        <a-card body-style="{padding: '15px'}">
          <Tabs value="name1" @onClick="tabsClick">
            <TabPane label="产品详情" name="name1">
              <div v-html="description"></div>
            </TabPane>
            <TabPane label="参数" name="name2">
              <div>
                <a-row :gutter="[16,24]" style="border: 0.5px solid #FFFFFF;background-color: #E8E8E8">
                  <a-col :span="5" style="border-right: 1px solid #FFFFFF;font-size: 13px">产品名称</a-col>
                  <a-col style="font-size: 13px" :span="19">{{
                      productInfo.productName ? productInfo.productName : '-'
                    }}
                  </a-col>
                </a-row>
                <a-row :gutter="[16,24]" style="border: 0.5px solid #FFFFFF;background-color: #E8E8E8"
                       v-for="(item,index) in productInfo.serviceType" :key="index">
                  <a-col :span="5" style="border-right: 1px solid #FFFFFF;font-size: 13px">
                    {{ item.name }}
                  </a-col>
                  <a-col :span="19" style="font-size: 13px">{{ item.info }}</a-col>
                </a-row>
              </div>
            </TabPane>
            <TabPane label="用户评价" name="name3">
              <div class='evaluate-list'>
                <div class="flex-pinglun">
                  <div>
                    评分:
                    <a-rate style="font-size: 16px" v-model:value="replyData.replyStar" disabled/>
                  </div>
                  <div>
                    {{ replyData.replyChance ? (replyData.replyChance) * 100 : 0 }}% 好评率
                  </div>
                </div>
                <div class="flex mt-6">
                  <div :class="type === 0 ? 'p-btn-s' :''" class="cursor-pointer p-btn" @click='changeType(0)'>
                    全部({{ replyData.sumCount || 0 }})
                  </div>
                  <div :class="type === 1 ? 'p-btn-s' :''" class="cursor-pointer ml-3 p-btn" @click='changeType(1)'>
                    好评({{ replyData.goodCount || 0 }})
                  </div>
                  <div :class="type === 2 ? 'p-btn-s' :''" class="cursor-pointer ml-3 p-btn" @click='changeType(2)'>
                    中评({{ replyData.inCount || 0 }})
                  </div>
                  <div :class="type === 3 ? 'p-btn-s' :''" class="cursor-pointer ml-3 p-btn" @click='changeType(3)'>
                    差评({{ replyData.poorCount || 0 }})
                  </div>
                </div>
                <empty v-if="reply.length === 0"/>
                <div class="mt-4" v-if="reply && reply.length > 0">
                  <List :loading="loading">
                    <ListItem v-for="(item, index) in reply" :key="index">
                      <ListItemMeta>
                        <template #avatar>
                          <a-avatar :src="item.avatar" :size="52"/>
                        </template>
                        <template #title>
                          {{ item.nickname }}
                          <a-rate v-model:value="item.star" disabled style="font-size: 14px;margin-left: 15px"/>
                        </template>
                        <template #description>
                          <a-card :bordered="false" :body-style="{ padding: '10px' }">
                            {{ item.comment }}
                            <div class="margin-top-xs" v-if="item.pics && item.pics.length > 0">
                              <arco-scrollbar type="embed" style="height:147px;overflow: auto;">
                                <div class="flex">
                                  <div v-for="(items, index) in item.pics" :key="index">
                                    <div class="imgs">
                                      <a-image :src="items" :width="149" :height="146"
                                               style="border-radius: 10px;"
                                               :fallback="require('@/assets/index/logo.png')"/>
                                    </div>
                                  </div>
                                </div>
                              </arco-scrollbar>
                            </div>

                            <div class="margin-top-xs bg-gray rounded-sm padding-xs" v-if="item.merchantReplyContent">
                              <text class="font_color">回复:</text>
                              ：{{ item.merchantReplyContent }}
                            </div>
                          </a-card>
                        </template>
                      </ListItemMeta>
                      <template #extra>
                        {{ item.createTime }}
                      </template>
                    </ListItem>
                  </List>
                </div>
              </div>

              <!-- 分页 -->
              <div class="page-size mt-10" v-if="reply && reply.length > 0">
                <a-pagination :page-size="limit" :total="total" show-less-items :show-size-changer="false"
                              @change="changePageNum" @page-size-change="changePageSize"/>
              </div>
            </TabPane>
          </Tabs>
        </a-card>
      </div>
    </div>
    <ProductShare :open="openShow" :product-info="productInfo" @close="openShow = false"/>
  </div>
</template>
<script>
import { collectAdd, collectCancel, getProductDetail, getReplyConfig, getReplyList } from '@/api/product.js'

import { getMerCollectAddApi, getMerCollectCancelApi, getMerDetailApi, merCustomerApi, } from '@/api/merchant.js'
import ProductShare from '@/components/share/index.vue'
import { preOrderApi } from '@/api/order'
import { Debounce } from '@/utils/validate'

import { HeartOutlined } from '@ant-design/icons-vue'
import { isSet } from '@/api/user'
import { Modal } from 'ant-design-vue'
import { company } from '@/config'

export default {
  components: {
    ProductShare,
    HeartOutlined
  },
  data() {
    return {
      id: '',
      sliderImage: [],
      productType: '',
      productInfo: {},
      serviceConfig: {
        id: '',
        merId: '',
        uId: ''
      },
      masterProductId: '',
      merchantInfo: {},
      description: '',
      userCollect: false,
      attr: {
        cartAttr: false,
        productAttr: [],
        productSelect: {}
      },
      productValue: '',
      guaranteeList: [],
      skuArr: [],
      buyNum: 1,
      stock: 0,
      skuArrId: 0,
      replyData: {},
      page: 1,
      limit: 10,
      type: 0,
      reply: [],
      total: '',
      loading: false,
      openShow: false,
      isCollect: false,
      previewVisible: false,
      tagUrl: company.href + '/Product?id=',
      store: {},
    }
  },
  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        if (this.$route.name === 'Product') {
          this.id = this.$route.query.id
          this.getGoodsDetails()
          this.getProductReplyCount()
          this.getProductReplyList()
        }
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.tagUrl = this.tagUrl + this.id
    this.getGoodsDetails()
  },
  methods: {
    customerDb() {
      window.open(this.store.phone, '_blank')
    },
    getStore(merId) {
      getMerDetailApi(merId)
          .then(res => {
            this.store = res.data
          })
    },
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.tagUrl}`, '_blank')
    },
    collectProduct() {
      this.setCollect()
    },
    setCollect: Debounce(function () {
      let that = this
      if (!this.$store.getters.token) {
        this.$router.push({
          path: 'Login',
          query: {}
        })
      } else {
        let id = this.productInfo.id
        if (this.userCollect) {
          collectCancel({
            ids: id
          })
              .then(res => {
                this.$nextTick(() => {
                  this.userCollect = !this.userCollect
                })
              })
        } else {
          collectAdd(id)
              .then(res => {
                this.$nextTick(() => {
                  this.userCollect = !this.userCollect
                })
              })
        }
      }
    }),
    // 设置是否关注
    followToggle(merchantInfo) {
      if (merchantInfo.isCollect) {
        getMerCollectCancelApi(merchantInfo.id)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      } else {
        getMerCollectAddApi(merchantInfo.id)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      }
    },
    customerOpen() {
      if (!this.$store.getters.token) {
        this.$Message.error('请先登录')
        this.$router.push({ path: '/Login' })
      } else {
        let routeUrl = this.$router.resolve({
          path: '/ChatIm',
          query: {
            dbMerId: this.merchantInfo.id,
            dbProductId: this.$route.query.id,
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    isSetPwd() {
      isSet()
          .then(res => {
            if (res.code === 200) {
              if (res.data) {
                Modal.confirm({
                  title: '提醒',
                  centered: true,
                  content: '您未设置支付密码,前去设置?',
                  onOk: () => {
                    this.$router.push({ path: '/AccountSafe' })
                  },
                  onCancel: () => {
                  }
                })
              } else {
                this.getPreOrderCreate()
              }
            }
          })
    },
    /**
     * 预下单
     */
    getPreOrderCreate() {
      if (this.buyNum <= 0) {
        return this.$Message.error('单次可购买商品数量必须大于0')
      }
      if (!this.skuArrId) {
        return this.$Message.error('请选择SKU')
      }
      let types = 'buyNow'
      this.getPreOrderLink(types,
          [{
            'attrValueId': parseFloat(this.skuArrId),
            'productId': parseFloat(this.id),
            'productNum': parseFloat(this.buyNum),
          }])
    },
    getPreOrderLink(preOrderType, orderDetails) {
      return new Promise((resolve, reject) => {
        preOrderApi({
          'preOrderType': preOrderType,
          'orderDetails': orderDetails
        })
            .then(res => {
              if (res.code === 200) {
                this.$router.push({
                  path: '/OrderConfirm',
                  query: {
                    orderNo: res.data.orderNo,
                  }
                })
              } else {
                this.$Message.error(res.message)
              }
            })
      })
    },
    selectSku(item) {
      this.skuArrId = item.id
      this.stock = item.stock
    },
    showShareProduct() {
      this.openShow = true
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getProductReplyList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getProductReplyList()
    },
    tabsClick(e) {
      if ('name3' === e) {
        this.getProductReplyCount()
        this.getProductReplyList()
      }
    },
    getProductReplyCount() {
      let that = this
      getReplyConfig(that.id)
          .then(res => {
            that.replyData = res.data
          })
    },
    getProductReplyList() {
      let that = this
      that.loading = true
      getReplyList(that.id, {
        page: that.page,
        limit: that.limit,
        type: that.type,
      })
          .then(res => {
            that.reply = res.data.list
            that.total = res.data.total
            that.loading = false
          })
    },
    /*
     * 点击事件切换
     * */
    changeType: function (e) {
      let type = parseInt(e)
      if (type === this.type) return
      this.type = type
      this.page = 1
      this.getProductReplyList()
    },
    getImgUrl(i) {
      return this.sliderImage[i]
    },
    getGoodsDetails: function () {
      this.skuArr = []
      getProductDetail(this.id, '')
          .then(res => {
            let data = res.data
            let productInfo = data.productInfo
            let arrayImg = productInfo.sliderImage
            this.sliderImage = JSON.parse(arrayImg)
            this.productType = productInfo.type //商品类型
            this.productInfo = productInfo
            this.masterProductId = data.masterProductId
            this.merchantInfo = data.merchantInfo
            this.isCollect = data.merchantInfo.isCollect
            this.serviceConfig.id = this.id
            this.serviceConfig.merId = this.merchantInfo.id
            this.serviceConfig.uId = this.merchantInfo.uid
            this.description = productInfo.content
            this.userCollect = data.userCollect
            this.attr.productAttr = data.productAttr
            this.productValue = data.productValue

            this.getStore(this.merchantInfo.id)
            this.getMerCustomer(productInfo.merId)
            for (let key in data.productValue) {
              let obj = data.productValue[key]
              this.skuArr.push(obj)
            }
            this.stock = this.skuArr[0].stock
            this.skuArrId = this.skuArr[0].id
            this.attr.productAttr = this.attr.productAttr.map(item => {
              return {
                attrName: item.attrName,
                attrValues: item.attrValues.split(','),
                id: item.id,
                isDel: item.isDel,
                productId: item.productId,
                type: item.type
              }
            })

            this.guaranteeList = data.guaranteeList ? data.guaranteeList : []
            if (this.isJson(data.productInfo.serviceType)) {
              this.productInfo.serviceType = JSON.parse(data.productInfo.serviceType)
            } else {
              this.productInfo.serviceType = []
            }
          })
    },
    getMerCustomer(id) {
      merCustomerApi(id)
          .then(res => {
            this.serviceConfig.serviceLink = res.data.serviceLink
            this.serviceConfig.servicePhone = res.data.servicePhone
            this.serviceConfig.serviceType = 'H5'
          })
    },
    isJson(str) {
      if (typeof str !== 'string') {
        return false
      }
      try {
        const obj = JSON.parse(str)
        if (typeof obj === 'object' && obj) {
          return true
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    },
  }
}
</script>
<style scoped lang="scss">
.home-name {
  font-size: 18px;
  font-weight: 900;
}

.btn-2 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #269FFA;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.btn-1 {
  margin-left: 10px;
  width: 100px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #5EAC65;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #5EAC65;
}

.telegram-btn {
  margin-top: 15px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #269FFA;
  color: #FFFFFF;
}

.tg-url-share {
  border: 1px solid #e8e8e8;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 10px;

  .copy-btn {
    cursor: pointer;
    margin-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #e8e8e8;
    height: 25px;
    border-radius: 10px;
    color: #666666;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line-ge {
  border-bottom: 1px solid #000000;
}

.btn-2-gz {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
  background-color: #269FFA;
}

.imgs {
  margin-right: 15px;
  position: relative;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;
    color: #FFFFFF;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.btn-3 {
  margin-left: 10px;
  width: 100px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #269FFA;
}

.custom-font {
  font-size: 14px;
  margin-left: -3px;
}

.p-btn-s {
  border: 1px solid #269FFA !important;
  color: #269FFA !important;
}

.p-btn {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid #787878;

  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0em;

  color: #787878;
}

.ping-rate {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-pinglun {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

:deep(.ivu-tabs-tabpane) {
  padding: 20px !important;
}

:deep(.wscnph) {
  width: 100% !important;
}

.product {
  &-name {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-money {
    margin-top: 10px;
    font-size: 28px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0em;
    color: #F14E4E;

    .unit {
      margin-left: 15px;
      font-weight: 900;
      font-size: 14px;
    }
  }

  &-arrtr {
    margin-top: 10px;
    font-size: 15px;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: 0em;
  }

  &-attrValues {
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 26px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #269FFA;

    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: 0em;

    color: #269FFA;
  }

  &-buyNum {
    margin-left: 15px;

  }

  &-buy {
    width: 346px;
    height: 50px;
    border-radius: 8px;
    opacity: 1;
    background: #269FFA;

    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FFFFFF;
  }

  &-buy-end {
    width: 346px;
    height: 50px;
    border-radius: 8px;
    opacity: 1;
    background: grey;

    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FFFFFF;
  }
}

.create-time {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
}

.create-time-info {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  color: #999999;
}

.intro {
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0em;
  color: #787878;
}

.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;

  .mer-top-card {
    width: 100%;
  }
}

/* For demo */
:deep(.slick-dots) {
  position: relative;
  height: auto;
}

:deep(.slick-slide) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

:deep(.slick-slide a-image) {
  border: 1px solid #fff;
  display: block;
  margin: auto;
  max-width: 100%;
}

:deep(.slick-arrow) {
  display: none !important;
}

:deep(.slick-thumb) {
  bottom: 0px;
}

:deep(.slick-thumb li) {
  width: 80px;
  height: 45px;
}

:deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>

<template>
  <div class="chatTitle">
    <span class="titleName" v-if="selectItem">
      {{ selectItem.merName }}
    </span>
    <a-button type="text" shape="circle" @click="refresh">
      <template #icon>
        <ReloadOutlined/>
      </template>
    </a-button>
  </div>
</template>

<script>
import { ReloadOutlined } from '@ant-design/icons-vue'

export default {
  name: 'chattitle',
  components: {
    ReloadOutlined
  },
  props: {
    selectItem: {
      type: Object,
      default: {}
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh', this.selectItem)
    }
  }
}
</script>

<style scoped>
.chatTitle {
  height: 50px;
  width: 100%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #c7d2db;
  background-color: #FFFFFF;
}

.moreBtn {
  background-color: #FFFFFF;
  border: 0;
  height: 40px;
}

.titleName {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 20px;
}

</style>

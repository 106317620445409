import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Setting from '@/utils/setting'
import { refreshToken } from '@/api/api'
import { getUserInfo } from '@/api/user'

let service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 60000, // 过期时间
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 发送请求之前做的
    const token = !store.getters.token ? sessionStorage.getItem('token') : store.getters.token
    if (token && config.url.indexOf('login/refresh') <= -1) {
      config.headers['Authori-zation'] = token
    }
    if (/get/i.test(config.method)) {
      config.params = config.params || config.data
    }
    if (/post/i.test(config.method)) {
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

let subscribers = []
let isRefreshing = true

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 401) {
      store.commit('LOGOUT')
      router.push({ path: '/login' })
    }
    if (res.code === 402 || res.code === 1016) {
      if (isRefreshing) {
        isRefreshing = false
        // 刷新TOKEN
        const token = !store.getters.token ? sessionStorage.getItem('token') : store.getters.token
        refreshToken(token)
          .then(res => {
            if (res.code !== 200) {
              store.commit('LOGOUT')
              router.push({ path: '/login' })
            } else {
              store.commit('LOGIN', { 'token': res.data.token })
              store.commit('SETUID', res.data.id)
              getUserInfo()
                .then(res => {
                  let accountInfo = res.data
                  store.commit('VISITOR_USER_ID', accountInfo.id)
                  store.commit('UPDATE_USERINFO', res.data)
                  if (subscribers.length > 0) {
                    subscribers.map(cap => cap())
                    subscribers = []
                  }
                })
            }
          })
          .finally(() => {
            isRefreshing = true
          })
      }
      return new Promise(resolve => {
        // 用函数形式将 resolve 存入，等待刷新后再执行
        subscribers.push(() => {
          resolve(service(response.config))
        })
      })
    } else {
      return res
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service

<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}">
      <div class="text-df text-bold">我的优惠券</div>
      <div class="mt-4 d-flex">
        <div class="cu-center">
          <a-button :class="selectedIndex === 'usable' ? 'btn-add cu-center' : ''" class="text-gray cu-center"
                    type="link" @click="showList('usable')">
            未使用
          </a-button>
        </div>
        <div class="ml-3 cu-center">
          <a-button :class="selectedIndex === 'unusable' ? 'btn-add' : ''" class="text-gray cu-center" type="link"
                    @click="showList('unusable')">
            已使用
          </a-button>
        </div>
        <div class="ml-3 cu-center">
          <a-button :class="selectedIndex === 'overdue' ? 'btn-add' : ''" class="text-gray cu-center" type="link"
                    @click="showList('overdue')">
            已过期
          </a-button>
        </div>
      </div>

      <div class="mt-4" style="width: 100%">
        <empty v-if="!couponsList || couponsList.length === 0"/>
        <a-row v-else :gutter="[16,24]">
          <a-col :span="8" v-for="(item,index) in couponsList" :key="index">
            <div class="bg-img-cus">
              <div class="money-hour" v-if="item.showFlag === 1">
                {{ item.month }}
                <text class="money-dw">
                  {{
                    item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : ''
                  }}
                </text>
              </div>
              <div class="money" v-if="item.showFlag === 0">
                {{ item.money }}
                <text class="money-dw">USDT</text>
              </div>
              <div class="item">
                <div class="content cursor-pointer">
                  <div class="text-grey text-font-s">{{ item.name }}</div>
                  <div class="text-grey text-sm">{{ '限商家入驻使用' }}</div>
                  <div class="text-grey text-sm">
                    {{ getCouponTime(item.startTime, item.endTime) }}
                  </div>
                </div>

                <div class="cursor-pointer" :class="item.validStr==='usable' ? 'action' : 'moneyGray-cus'">
                  <div class="btn-cus" v-if="item.validStr==='usable'" @click="goCouponsProList(item)">
                    使用
                  </div>
                  <div class="text-gray" v-if="item.validStr==='unusable'">
                    已使用
                  </div>
                  <div class="text-gray" v-if="item.validStr==='overdue'">
                    已过期
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  getUserCoupons
} from '@/api/api.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      selectedIndex: 'usable',
      page: 1,
      limit: 30,
      couponsList: []
    }
  },
  mounted() {
    this.getUseCoupons()
  },
  methods: {
    goCouponsProList(item) {
      if (item.category === 7) {
        this.$router.push({
          path: '/Enter',
          query: {
          }
        })
      }
    },
    /**优惠券展示的使用有效期均根据今天进行判断，
     * 没到使用期的展示「开始日期-结束日期 有效」，
     * 已经可以使用的展示「有效期至 结束日期」
     * @param {Object} time
     */
    getCouponTime(startTime, endTime) {
      let start = new Date(startTime).getTime()
      let newTime = Date.now()
      let reg = new RegExp('-', 'g') //g代表全部
      if (newTime < start) {
        return startTime.slice(0, 16)
            .replace(reg, '.') + ' - ' + endTime.slice(0, 16)
            .replace(reg, '.') + ' 可用'
      } else {
        return '有效期至 ' + endTime.slice(0, 16)
            .replace(reg, '.')
      }
    },
    getUseCoupons() {
      let that = this
      this.loading = true
      getUserCoupons({
        page: that.page,
        limit: that.limit,
        type: that.selectedIndex
      })
          .then(res => {
            that.couponsList = res.data ? res.data.list : []
            that.loading = false
          })
    },
    showList(e) {
      this.selectedIndex = e
      this.page = 1
      this.getUseCoupons()
    }
  },
}
</script>

<style scoped lang="scss">
.btn-add {
  width: 65px;
  height: 24px;
  border-radius: 50px;
  background: #269FFA;

  font-size: 14px;
  font-weight: 350;
  letter-spacing: 0px;
  color: #F9F9F9;
}


.bg-img-cus {
  position: relative;
  margin-top: 10px;
  width: 310px;
  height: 134px;
  border-radius: 12px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #E4E4E4;


  .item {
    position: relative;
    display: flex;
    margin-top: 10px;
    padding-right: 10px;
    height: 100%;
    justify-content: flex-end;

    align-items: center;

    .content {
      position: absolute;
      top: 2px;
      left: 125px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-around;

      color: #000;

      .text-font-s {
        font-size: 14px;
        font-weight: 500;
        color: #3D3D3D;
      }

      .text-font-xs {
        font-size: 10px;
        color: #999999;
        margin-top: 5px;
      }

      .text-font-xs-time {
        font-size: 11px;
        color: #FF8529;
        margin-top: 3px;
      }

      .text-font-s-1 {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
      }
    }


    .moneyGray-cus {
      position: absolute;
      bottom: 20px;
      left: 60px;
      right: 0;
      margin: 0 auto;
      text-align: center;
      border-radius: 50px;

      color: gray;

      width: 125px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid gray;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action {
      position: absolute;
      bottom: 20px;
      left: 60px;
      right: 0;
      margin: 0 auto;
      text-align: center;
      border-radius: 50px;

      color: #FF6F4A;

      width: 125px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid #FF6F4A;

      display: flex;
      align-items: center;
      justify-content: center;

      .text-cus-ss {
        font-size: 14px;
        line-height: normal;
        color: #FFFFFF;
      }

      .text-cus-ss-1 {
        font-size: 14px;
        line-height: normal;
        color: gray;
      }
    }
  }

  .money-hour {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100%;

    border-right: 1px dashed #D8D8D8;

    font-size: 43px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #FF6F4A;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FF6F4A;
    }
  }

  .money {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100%;

    border-right: 1px dashed #D8D8D8;

    font-size: 28px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #FF6F4A;

    display: flex;
    justify-content: center;
    align-items: center;

    .money-dw {
      margin-left: 5px;
      margin-top: 35px;
      font-size: 12px;
      color: #FF6F4A;
    }
  }
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>

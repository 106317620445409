<template>
  <div class="page-container-all width_1200_auto">
    <div class="filter-container">
      <div class="main-top">
        <div class="mer-top-card">
          <MerHome :show-join="false" :merchant-info="merchantInfo" :store="store"  :isCollect="isCollect"
                   @followToggle="followToggle"></MerHome>

          <div class="margin-top-xs margin-bottom">
            <a-row :gutter="[16, 24]">
              <a-col :span="5">
                <a-card :bodyStyle="{padding: '10px'}">
                  <a-menu mode="inline" @click="handleClick"
                          v-model:selectedKeys="selectedKeys">
                    <a-menu-item-group>
                      <template #title>
                        <div class="text-body-1 text-gray">商品分类</div>
                      </template>
                      <a-menu-item v-for="(item,index) in category" :key="item.id">{{ item.name }}</a-menu-item>
                    </a-menu-item-group>
                  </a-menu>
                </a-card>
              </a-col>
              <a-col :span="19">
                <a-card :bordered="false">
                  <div class="flex mb-3">
                    <a-space>
                      <a-button :class="morenSed ? 'button-moren-seleced' :''" type="link" class="button-moren"
                                @click="moRenClick">
                        默认
                      </a-button>
                      <a-button :class="isPriceSed ? 'button-moren-seleced' :''" class="button-moren-1"
                                @click="priceClick(isPrice)">价格
                        <Icon type="md-arrow-dropdown" v-if="isPrice"/>
                        <Icon type="md-arrow-dropup" v-if="!isPrice"/>
                      </a-button>
                      <a-button :class="isSalesSed ? 'button-moren-seleced' :''" class="button-moren-1"
                                @click="salesClick(isSales)">
                        销量
                        <Icon type="md-arrow-dropdown" v-if="isSales"/>
                        <Icon type="md-arrow-dropup" v-if="!isSales"/>
                      </a-button>
                    </a-space>
                  </div>
                  <a-row :gutter="[16,24]">
                    <a-col :span="6" v-for="(item, index) in productList" :key="index" class="mb-6">
                      <a-card hoverable :bordered="false" :bodyStyle="{padding: '0',height: '320px'}"
                              :loading="goodsLoading" class="cursor-pointer"
                              @click="() => {$router.push({path:'/Product', query:{ id: item.id}})}">
                        <div>
                          <a-image :width="212" :height="212" class="rounded-lg" :preview="false" :src="item.image"/>
                        </div>
                        <div class="line2 mt-2 pa-1" style="height: 45px;">
                          {{ item.name }}
                        </div>
                        <div class="text-red mt-1 ml-2">
                          {{ item.price }} USDT
                        </div>
                        <div class="text-gray mt-1 ml-2 text-caption">
                          {{ item.sales }} 人付款
                        </div>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-card>
                <!-- 分页 -->
                <div class="page-size mt-3" v-if="productList && productList.length > 0">
                  <a-pagination :page-size="where.limit" :total="total" show-less-items
                                :show-size-changer="false"
                                @change="changePageNum" @page-size-change="changePageSize"/>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getMerCategoryApi,
  getMerCollectAddApi,
  getMerCollectCancelApi, getMerDetailApi,
  getMerIndexInfoApi,
  getMerProListApi
} from '@/api/merchant.js'

import MerHome from '@/components/merTop/index.vue'

export default {
  components: { MerHome },
  data() {
    return {
      merid: 0,
      merchantInfo: {},
      store: {},
      isCollect: false,
      skeletonShow: false,
      goodsLoading: false,
      price: 0,
      stock: 0,
      where: {
        merId: '',
        priceOrder: '',
        salesOrder: '',
        cid: '',
        page: 1,
        limit: 12,
      },
      selectedKeys: [''],
      productList: [],
      total: 0,
      moren: true,
      isPrice: false,
      isSales: false,
      morenSed: true,
      isPriceSed: false,
      isSalesSed: false,
      category: [],
    }
  },
  created() {
    this.merid = this.$route.query.merId
    this.getMerIndexInfo()
    this.getStore()
    this.getMerCategory()
    this.getGoods()
  },
  methods: {
    MerHome() {
    },
    getStore() {
      getMerDetailApi(this.merid)
          .then(res => {
            this.store = res.data
          })
    },
    handleClick(e) {
      this.where.cid = e.key
      this.getGoods()
    },
    getMerCategory() {
      getMerCategoryApi(this.merid)
          .then(res => {
            let all = {
              id: '',
              name: '全部分类'
            }
            this.category = res.data
            this.category.unshift(all)
          })
    },
    moRenClick() {
      this.morenSed = true
      this.isPriceSed = false
      this.isSalesSed = false
      this.moren = true
      this.isPrice = false
      this.isSales = false
      this.price = 0
      this.stock = 0
      this.getGoods()
    },
    priceClick(val) {
      this.morenSed = false
      this.isPriceSed = true
      this.isSalesSed = false

      this.moren = false
      this.isSales = false

      this.price = 2
      if (val) {
        this.price = 1
      }
      this.stock = 0
      this.isPrice = !this.isPrice
      this.getGoods()
    },
    salesClick(val) {
      this.morenSed = false
      this.isPriceSed = false
      this.isSalesSed = true

      this.moren = false
      this.isPrice = false

      this.stock = 2
      if (val) {
        this.stock = 1
      }
      this.price = 0
      this.isSales = !this.isSales
      this.getGoods()
    },
    getGoods() {
      this.goodsLoading = true
      this.where.merId = this.merid
      if (this.price === 0) {
        this.where.priceOrder = ''
      } else if (this.price === 1) {
        this.where.priceOrder = 'asc'
      } else if (this.price === 2) {
        this.where.priceOrder = 'desc'
      }
      if (this.stock === 0) {
        this.where.salesOrder = ''
      } else if (this.stock === 1) {
        this.where.salesOrder = 'asc'
      } else if (this.stock === 2) {
        this.where.salesOrder = 'desc'
      }
      getMerProListApi(this.where)
          .then(res => {
            this.productList = res.data.list
            this.goodsLoading = false
            this.total = res.data.total
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.where.page = val
      this.getGoods()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.where.page = 1
      this.where.limit = val
      this.getGoods()
    },
    getMerIndexInfo() {
      getMerIndexInfoApi(this.merid)
          .then(res => {
            this.merchantInfo = res.data
            this.isCollect = this.merchantInfo.isCollect
            this.$store.commit('MERCHANTJINFO', res.data)
            this.skeletonShow = false
          })
    },
    // 设置是否关注
    followToggle() {
      if (this.merchantInfo.isCollect) {
        getMerCollectCancelApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      } else {
        getMerCollectAddApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}

.button-moren-seleced {
  color: #269FFA !important;
  border: 1px solid #269FFA !important;;
}

.button-moren {
  width: 46px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #5D5D5D;
  background: #F3F3F3;
}

.button-moren-1 {
  width: 62px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  //border: 1px solid #5D5D5D;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #5D5D5D;
  background: #F3F3F3;
}


.btn-1 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #FFFFFF;
}

.btn-2 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #999999;
}

.page-container-all {
  //width: 100%;
  margin-top: 3px;
  min-height: 1200px;

  .filter-container {
    width: 100%;

    .main-top {
      width: 1200px;
      margin: 0 auto;

      .mer-top-card {
        width: 100%;
        margin-top: 10px;


        .merName {
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0em;
          color: #3D3D3D;
        }

        .danbao-name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;

          .gold-font {
            margin-left: 5px;
            font-weight: 700;
            font-size: 16px;
            color: #FF6F4A;
          }

          .usdt {
            margin-left: 5px;
            font-weight: 400;
            font-size: 12px;
            color: #FF6F4A;
          }
        }
      }
    }
  }
}
</style>

import {createApp} from 'vue'
import Vuex from "vuex";
import modules from "./modules";
import getters from "./getters";
import App from "@/App.vue";


const app = createApp(App)
app.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,
  getters,
  strict: debug
});

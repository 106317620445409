<template>
  <div style="width: 90%;margin-left: 15px;">
    <arco-scrollbar type="embed" style="height:120px;overflow: auto;">
      <div class="flex">
        <div v-for="(items, index) in proList" :key="index" class="cursor-pointer" @click="() => {$router.push({path:'/Product', query:{ id: items.productId}})}">
          <div class="imgs">

            <a-image :preview="false" :src="items.image ? items.image : items.productImage" :width="109" :height="106"  style="border-radius: 10px;" :fallback="require('@/assets/index/logo.png')"/>
            <div class="usdt-bottom" v-if="items.price">
              {{ items.price }}USDT
            </div>
          </div>
        </div>
      </div>
    </arco-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'infiniteScroll',
  props: {
    proList: {
      type: Array,
      default: []
    }
  },
  watch: {
    proList(val) {
      console.log(val)
    }
  },
  data() {
    return {}
  },
  methods: {
    load({ done }) {
      done('empty')
    },
  }
}
</script>

<style scoped lang="scss">
.imgs {
  margin-right: 15px;
  position: relative;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;
    color: #FFFFFF;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>

<template>
  <div id="card">
    <a-input class="search" type="text" size="mini" v-model:value="keyword" placeholder="搜索"
              @input="search"></a-input>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      keyword: ""
    };
  },
  methods: {
    search(e) {
      this.$emit("search", this.keyword);
    }
  }
};
</script>

<style lang="scss" scoped>
#card {
  height: 50px;
  padding: 12px;
  background-color: #FFFFFF;

  .avatar {
    width: 40px;
    height: 40px;
    vertical-align: middle; /*这个是图片和文字居中对齐*/
  }

  .name {
    display: inline-block;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .search {
    background-color: #DBD9D8;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border: none !important;
    border-radius: 4px;
    outline: none; /*鼠标点击后不会出现蓝色边框*/
  }

  ::v-deep .el-input {
    --el-input-text-color: #DBD9D8;
    --el-input-border: #DBD9D8;
    --el-input-hover-border: #DBD9D8;
    --el-input-focus-border: #DBD9D8;
    --el-input-transparent-border: 0 0 0 1px transparent inset;
    --el-input-border-color: #DBD9D8;
    --el-input-border-radius: var(--el-border-radius-base);
    --el-input-bg-color: #DBD9D8;
    --el-input-icon-color: var(--el-text-color-placeholder);
    --el-input-placeholder-color: var(--el-text-color-placeholder);
    --el-input-hover-border-color: #DBD9D8;
    --el-input-clear-hover-color: #DBD9D8;
    --el-input-focus-border-color: #DBD9D8;
    --el-input-width: 100%;
  }


  .search:hover {
    border: none !important;
  }

  .searchBtn {
    background-color: #DBD9D8;
    border: 0;
    margin-left: 3px;
  }
}
</style>
<style>
/*当前组件的el-input样式设置*/
#card .el-input__inner {
  background-color: #DBD9D8;
  outline: none; /*鼠标点击后不会出现蓝色边框*/
}
</style>
